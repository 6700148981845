import { createContext } from 'react';
import { AuthContextValue, AuthState } from './jwtAuthContext.types';

export const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  isAppReady: false,
  user: null,
  denialReasons: null,
  persona: null,
  confirmationEmail: null,
};

export const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
  confirm: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  newPassword: () => Promise.resolve(),
  changePassword: () => Promise.resolve(),
  validateOtpForMerchantAccountId: () => Promise.resolve(),
  resendSupplierAccountConfirmationInstructions: () => Promise.resolve(),
});
