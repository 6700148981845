import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { DELETE_SUPPLIER_ACCOUNT_BY_ID } from '../contexts/suppliers/mutations/deleteSupplierAccountById';
import { DeleteSupplierAccountById } from '../contexts/suppliers/mutations/__generated__/DeleteSupplierAccountById';
import { GetSupplierAccountsGrid_rows_edges_node } from '../contexts/suppliers/queries/__generated__/GetSupplierAccountsGrid';

export function useDeleteSupplierAccountById(onSuccess?: () => void, onError?: () => void) {
  const [supplierToDelete, setSupplierToDelete] =
    useState<GetSupplierAccountsGrid_rows_edges_node | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();

  const [deleteSupplierAccountById] = useMutation<DeleteSupplierAccountById>(
    DELETE_SUPPLIER_ACCOUNT_BY_ID,
    {
      onCompleted: () => {
        setSupplierToDelete(null);
        enqueueSnackbar(
          formatMessage({
            id: 'admin.supplier-account.delete-dialog.success',
            defaultMessage: 'Supplier account deleted successfully',
          }),
          { variant: 'success' },
        );

        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (result) => {
        enqueueSnackbar(result.message, { variant: 'error' });
        if (onError) {
          onError();
        }
      },
      update: (cache, { data }) => {
        if (data && !data.deleteSupplierAccountById?.denialReasons) {
          cache.evict({ id: `${supplierToDelete?.__typename}:${supplierToDelete?.id}` });
          cache.gc();
        }
      },
    },
  );

  const selectForDelete = (supplier: GetSupplierAccountsGrid_rows_edges_node | null): void => {
    setSupplierToDelete(supplier);
  };

  const cancelDeletion = (): void => {
    setSupplierToDelete(null);
  };

  const confirmDeletion = async () => {
    if (supplierToDelete && supplierToDelete.id) {
      return deleteSupplierAccountById({
        variables: { id: supplierToDelete.id },
      });
    }
  };

  return { selectForDelete, cancelDeletion, confirmDeletion, supplierToDelete };
}
