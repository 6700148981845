import { gql } from '@apollo/client';

export const UPDATE_SUPPLIER_ACCOUNT_BY_ID_BASIC_ATTRIBUTES = gql`
  mutation UpdateSupplierAccountByIdBasicAttributes(
    $id: ID!
    $supplierAccountAttributes: SupplierAccountAttributes!
  ) {
    updateSupplierAccountByIdBasicAttributes(
      input: { id: $id, supplierAccountAttributes: $supplierAccountAttributes }
    ) {
      account {
        ... on SupplierAccount {
          supplierCompanies {
            nodes {
              id
            }
          }
        }
      }
      denialReasons
    }
  }
`;
