import { gql } from '@apollo/client';

export const GET_COMMODITY_GROUPS = gql`
  query GetCommodityGroups {
    commodityGroups {
      name
      level
    }
  }
`;

export const GET_ACTIVE_COMMODITY_GROUPS = gql`
  query GetActiveCommodityGroups {
    commodityGroups(usedGroupsOnly: true) {
      name
      level
    }
  }
`;
