export interface User {
  id: string;
  avatar: string;
  email: string;
  [key: string]: any;
}

export enum UserPersona {
  ADMIN = 'AdminAccount',
  SUPPLIER = 'SupplierAccount',
  MERCHANT = 'MerchantAccount',
}

export const PERSONA_LOGIN_MAP: Record<UserPersona, string> = {
  [UserPersona.ADMIN]: '/admin/sign_in',
  [UserPersona.SUPPLIER]: '/login',
  [UserPersona.MERCHANT]: `${process.env.REACT_APP_LION}/merchant_accounts/sign_in`,
};

export const PERSONA_DEFAULT_ROUTE_MAP: Record<UserPersona, string> = {
  [UserPersona.ADMIN]: '/admin',
  [UserPersona.SUPPLIER]: '/app',
  [UserPersona.MERCHANT]: '/merchants',
};
