import { createContext } from 'react';
import { SupplierState, SupplierContextValue } from './supplierContext.types';

export const initialSupplierState: SupplierState = {
  name: '',
  postalCode: '',
  city: '',
  district: '',
  deliveryArea: '',
  website: '',
  description: '',
  products: '',
  minimumOrderValue: '',
  commodityGroups: [],
  firstName: '',
  surname: '',
  phone: '',
  fax: '',
  logo: null,
  productPortfolio: null,
  images: null,
  publishedDate: null,
  publishedState: 'draft',
  companyId: null,
};

const SupplierContext = createContext<SupplierContextValue>({
  ...initialSupplierState,
  updateSupplierContextState: () => {},
  updateAndSubmitSupplierAccountState: () => Promise.resolve() as any,
  updateAndSubmitSupplierCompanyState: () => Promise.resolve() as any,
  updateAndSubmitSupplierStatus: () => Promise.resolve() as any,
  basicsDone: false,
  detailsDone: false,
  productsDone: false,
  accountDone: false,
  logoDone: false,
  productPortfolioDone: false,
  imagesDone: false,
  readyToSubmit: false,
  approvalRequested: false,
  approved: false,
  blocked: false,
});

export default SupplierContext;
