import { FC, useEffect, useRef } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik, FormikContextType } from 'formik';
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  makeStyles,
  Divider,
  Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import FormattedMessageMarkdown from '../../utils/FormattedMessageMarkdown';
import { UserPersona } from '../../types/user';

interface JWTLoginProps {
  className?: string;
  persona: UserPersona;
}

interface LoginFormState {
  email: string;
  password: string;
  submit: boolean | null;
}

const useStyles = makeStyles(() => ({
  root: {},
  divider: {
    marginTop: '2rem',
  },
  new: {
    textAlign: 'center',
    marginTop: '2rem',
  },
  link: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    'margin-top': '1rem',
  },
  forgotPassword: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const JWTLogin: FC<JWTLoginProps> = ({ className, persona }) => {
  const classes = useStyles();
  const { login, denialReasons } = useAuth();
  const { formatMessage } = useIntl();
  const isMountedRef = useIsMountedRef();
  const formRef = useRef<FormikContextType<LoginFormState>>(null);

  // Update the internal form state
  // when there are errors in the auth context
  useEffect(() => {
    formRef.current?.setFieldError(
      'submit',
      denialReasons
        ?.map((reasonName) =>
          formatMessage({
            id: `login-page.error.${reasonName}`,
            defaultMessage: reasonName,
          }),
        )
        .join(', '),
    );
  }, [denialReasons, formatMessage]);

  return (
    <Formik<LoginFormState>
      initialValues={{
        email: '',
        password: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(
            formatMessage({
              id: 'login-page.error.valid-email',
              defaultMessage: 'Must be a valid email',
            }),
          )
          .max(255)
          .required(
            formatMessage({
              id: 'login-page.error.email',
              defaultMessage: 'Email is required',
            }),
          ),
        password: Yup.string()
          .max(128)
          .required(
            formatMessage({
              id: 'login-page.error.password',
              defaultMessage: 'Password is required',
            }),
          ),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        await login(values.email, values.password, persona);
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      }}
      innerRef={formRef}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit} className={clsx(classes.root, className)}>
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            autoFocus
            helperText={touched.email && errors.email}
            label={formatMessage({
              id: 'login-page.form.email',
              defaultMessage: '"Email Address"',
            })}
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label={formatMessage({
              id: 'login-page.form.password',
              defaultMessage: '"Password',
            })}
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          {persona !== UserPersona.ADMIN && (
            <Typography variant="body2" color="textSecondary" className={classes.forgotPassword}>
              <FormattedMessageMarkdown
                id="login-page.form.forgot-password-link"
                defaultMessage="Forgot password?"
              />
            </Typography>
          )}
          <Button
            color="secondary"
            disabled={isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            className={classes.button}
          >
            {formatMessage({
              id: 'login-page.form.login-link',
              defaultMessage: 'Login',
            })}
          </Button>
          {persona !== UserPersona.ADMIN && (
            <>
              <Divider className={classes.divider} />
              <Typography variant="body2" color="textSecondary" className={classes.new}>
                <FormattedMessageMarkdown
                  id="login-page.form.register-link"
                  defaultMessage="Register"
                />
              </Typography>
            </>
          )}
        </form>
      )}
    </Formik>
  );
};

JWTLogin.propTypes = {
  className: PropTypes.string,
};

export default JWTLogin;
