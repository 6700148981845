import { FC } from 'react';
import SystemSettingsContext from './systemSettingsContext';
import sudwestSettings from './sudwest.json';
import nstSettings from './nst.json';
import nordSettings from './nord.json';
import { EdekaRegions } from '../../types/company';

const SystemSettingsProvider: FC = ({ children }) => {
  // This solution should be replaced
  // with pulling settings from the DB.
  let settings = sudwestSettings;

  const region: keyof typeof EdekaRegions =
    (process.env?.REACT_APP_CURRENT_REGION as keyof typeof EdekaRegions) || 'sw';

  if (EdekaRegions[region] === EdekaRegions.nst) {
    settings = nstSettings;
  } else if (EdekaRegions[region] === EdekaRegions.nord) {
    settings = nordSettings;
  }

  return (
    <SystemSettingsContext.Provider value={settings}>{children}</SystemSettingsContext.Provider>
  );
};

export default SystemSettingsProvider;
