import { gql } from '@apollo/client';

export const CONFIRM_SUPPLIER_ACCOUNT = gql`
  mutation ConfirmSupplierAccount($email: String!, $confirmationToken: String!) {
    confirmSupplierAccount(input: { email: $email, confirmationToken: $confirmationToken }) {
      denialReasons
      session {
        account {
          ... on SupplierAccount {
            id
            email
          }
        }
        jwtToken
      }
    }
  }
`;
