import { gql } from '@apollo/client';

export const SUPPLIER_COMPANY_FRAGMENT = gql`
  fragment SupplierCompanyCoreData on SupplierCompany {
    advertisingArea
    city
    commodityGroups
    createdAt
    deliveryArea
    description
    district
    edekaRegion
    id
    images {
      id
      imageUrl
    }
    listing
    logo {
      id
      imageUrl
    }
    minimumOrderValue
    name
    postalCode
    productPortfolio {
      id
      name
      portfolioUrl
    }
    products
    publishedDate
    publishedState
    updatedAt
    submittedAt
    website
  }
`;

export const SUPPLIER_ACCOUNT_FRAGMENT = gql`
  fragment SupplierAccountCoreData on SupplierAccount {
    id
    acceptedTerms
    backofficeNotes
    createdAt
    email
    faxNumber
    firstName
    surname
    phoneNumber
    zksNumber
  }
`;
