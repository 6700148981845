import { gql } from '@apollo/client';
import { SUPPLIER_ACCOUNT_FRAGMENT, SUPPLIER_COMPANY_FRAGMENT } from '../fragments';

export const GET_SUPPLIER_ACCOUNT_BY_ID = gql`
  ${SUPPLIER_COMPANY_FRAGMENT}
  ${SUPPLIER_ACCOUNT_FRAGMENT}
  query GetSupplierAccountById($id: ID!) {
    supplierAccount: supplierAccountById(id: $id) {
      ...SupplierAccountCoreData
      supplierCompanies {
        nodes {
          ...SupplierCompanyCoreData
        }
        edges {
          node {
            ...SupplierCompanyCoreData
          }
        }
      }
    }
  }
`;
