import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Box, Divider } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import typography from '../../theme/typography';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    width: '100%',
    padding: theme.spacing(5, 0, 3),
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.background.paper : theme.palette.grey[800],
  },
  link: {
    padding: '0 2rem',
  },
  terms: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  divider: {
    margin: '2rem 0',
    width: '100%',
  },
  social: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '1rem',
  },
  contact: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  connect: {
    fontSize: typography.h4.fontSize,
  },
  socialIcon: {
    margin: '0 1rem',
  },
  copyright: {
    textAlign: 'center',
    marginTop: '2rem',
  },
}));

export default function Footer() {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <footer className={classes.footer}>
      <Box className={classes.terms}>
        <Link
          className={classes.link}
          color="textSecondary"
          component={RouterLink}
          to="/privacy"
          underline="hover"
          variant="body2"
          paragraph
        >
          {formatMessage({
            id: 'footer.data-protection',
            defaultMessage: 'Privacy',
          })}
        </Link>
        <Link
          className={classes.link}
          color="textSecondary"
          component={RouterLink}
          to="/terms"
          underline="hover"
          variant="body2"
          paragraph
        >
          {formatMessage({
            id: 'footer.terms-of-service',
            defaultMessage: 'Terms',
          })}
        </Link>
        <Link
          className={classes.link}
          color="textSecondary"
          component={RouterLink}
          to="/imprint"
          underline="hover"
          variant="body2"
          paragraph
        >
          {formatMessage({
            id: 'footer.imprint',
            defaultMessage: 'Imprint',
          })}
        </Link>
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.contact}>
        <Box mt={2} px={4}>
          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.connect}
            paragraph
            align="center"
          >
            {formatMessage({
              id: 'footer.contact',
              defaultMessage: 'Contact us',
            })}
          </Typography>
          <Typography variant="body1" color="textPrimary" align="center">
            foodstarter Gmbh
          </Typography>
          <Typography variant="body1" color="textPrimary" align="center">
            <Link href="mailto:regional@foodstarter.edeka">regional@foodstarter.edeka</Link>
          </Typography>
          <Typography variant="body1" color="textPrimary" align="center">
            New-York-Ring 6
          </Typography>
          <Typography variant="body1" color="textPrimary" align="center">
            22297 Hamburg
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.connect}
            align="center"
          >
            {formatMessage({
              id: 'footer.connect',
              defaultMessage: 'Connect',
            })}
          </Typography>
          <Box className={classes.social}>
            <Link
              href="https://facebook.com/foodstarterwebsite"
              target="_blank"
              variant="body1"
              color="textPrimary"
              align="center"
              className={classes.socialIcon}
            >
              <FacebookIcon />
            </Link>

            <Link
              href="https://www.instagram.com/foodstarter.edeka"
              target="_blank"
              variant="body1"
              color="textPrimary"
              align="center"
              className={classes.socialIcon}
            >
              <InstagramIcon />
            </Link>
          </Box>
        </Box>
      </Box>
      <Typography variant="body2" color="textPrimary" className={classes.copyright}>
        © 2021 foodstarter
      </Typography>
    </footer>
  );
}
