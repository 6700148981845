import { Link } from '@material-ui/core';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import Markdown from 'react-markdown';
import { Link as RouterLink } from 'react-router-dom';

interface FormattedMessageMarkdownProps {
  id: string;
  defaultMessage: string;
  values?: any;
  description?: string;
  linkTarget?: string;
}

const NavLink: FC<{ href: string; linkTarget?: string }> = ({ href, linkTarget, children }) =>
  href.match(/^(https?:)?\/\//) ? (
    <a {...{ href, target: linkTarget }}>{children}</a>
  ) : (
    <Link {...{ component: RouterLink, to: href, target: linkTarget, underline: 'always' }}>
      {children}
    </Link>
  );

const FormattedMessageMarkdown: FC<FormattedMessageMarkdownProps> = (props) => {
  const { formatMessage } = useIntl();
  const { id, defaultMessage, values, description, linkTarget } = props;
  const messageDescriptor = { id, defaultMessage, values, description };

  return (
    <Markdown
      source={formatMessage(messageDescriptor)}
      renderers={{
        link: ({ node, ...rendererProps }) => (
          <NavLink linkTarget={linkTarget} {...rendererProps} />
        ),
      }}
    />
  );
};

export default FormattedMessageMarkdown;
