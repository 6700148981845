import { Box, Container, makeStyles } from '@material-ui/core';
import { FC } from 'react';
import { AdminBreadcrumb, AdminHeader } from '.';
import Page from '../../../components/Page';

interface Props {
  heading: string;
  pageTitle: string;
  breadcrumbs: AdminBreadcrumb[];
  exportSection: boolean;
}

const useStyles = makeStyles(() => ({
  wrapper: {
    paddingTop: '30px',
    paddingBottom: '24px',
  },
}));

const AdminPageWrapper: FC<Props> = ({
  children,
  heading,
  breadcrumbs,
  pageTitle,
  exportSection,
}) => {
  const classes = useStyles();

  return (
    <Page title={pageTitle} className={classes.wrapper}>
      <Container maxWidth={false}>
        <AdminHeader exportSection={exportSection} heading={heading} breadcrumbs={breadcrumbs} />
        <Box mt={3}>{children}</Box>
      </Container>
    </Page>
  );
};

export default AdminPageWrapper;
