import moment from 'moment-timezone';

export class DateUtils {
  public static readonly DISPLAYED_TZ = 'Europe/Berlin';
  public static readonly DISPLAYED_LOCALE = 'de';
  public static readonly UTC_TZ = 'UTC';
  public static readonly DATE_FORMAT = 'DD.MM.YYYY HH:mm';

  public static toBerlinTimezoneString(date: string) {
    return moment(date).tz(DateUtils.DISPLAYED_TZ).format(DateUtils.DATE_FORMAT);
  }
}
