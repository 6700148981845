import type { FC } from 'react';
import { Redirect } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { PERSONA_DEFAULT_ROUTE_MAP, UserPersona } from '../types/user';

/*
  The GuestGuard is responsible for handling authenticated | registered | guest users.
  If the user is a guest, the children components will be displayed.
  If the user is authenticated they will be redirected to the Dashboard.
  If the user is not yet authenticated (after logging in) but has registered a new account they will
  be redirected to the ConfirmationView.
*/
const GuestGuard: FC = ({ children }) => {
  const { isAuthenticated, persona } = useAuth();

  if (isAuthenticated) {
    return <Redirect to={PERSONA_DEFAULT_ROUTE_MAP[persona ?? UserPersona.SUPPLIER]} />;
  }

  return <>{children}</>;
};

export default GuestGuard;
