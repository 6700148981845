import { Grid } from '@material-ui/core';
import MarketingCookiesDetails from '../detail-texts/MarketingCookiesDetails';
import commerceIconPath from './commerce.svg';
import ToggableCheckbox from './ToggableCheckbox';

const MarketingCookiesSection = ({ styles, setMySelection, mySelection }) => (
  <Grid container>
    <Grid item sm={1}>
      <div className={styles.iconContainer}>
        <label htmlFor="marketingCookies">
          <img className={styles.icon} src={commerceIconPath} alt="marketing cookies icon" />
        </label>
      </div>
    </Grid>
    <Grid item sm={10}>
      <div className={styles.textContainer}>
        <MarketingCookiesDetails styles={styles} />
      </div>
    </Grid>
    <Grid item sm={1}>
      <ToggableCheckbox
        cookieType="marketingCookies"
        styles={styles}
        mySelection={mySelection}
        setMySelection={setMySelection}
      />
    </Grid>
  </Grid>
);

export default MarketingCookiesSection;
