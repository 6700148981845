import { RESET_PASSWORD } from '../../auth/mutations/resetPassword';

export const resetPasswordMock = {
  request: {
    query: RESET_PASSWORD,
    variables: {
      email: 'foo@bar.baz',
    },
  },
  result: {
    data: {
      resetPassword: {
        __typename: 'ResetPasswordPayload',
      },
    },
  },
};
