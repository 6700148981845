import { gql } from '@apollo/client';

export const GET_SUPPLIER_COMPANIES = gql`
  query GetSupplierCompanies(
    $first: Int
    $after: String
    $supplierCompaniesFilter: SupplierCompanyFilter!
  ) {
    supplierCompaniesFiltered(supplierCompaniesFilter: $supplierCompaniesFilter) {
      supplierCompanies(first: $first, after: $after) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            name
            images {
              id
              imageUrl
            }
            listing
          }
        }
      }
      denialReasons
    }
  }
`;
