import {
  BarChart as BarChartIcon,
  PieChart as PieChartIcon,
  Users as UsersIcon,
  LogOut as LogoutIcon,
} from 'react-feather';
import { Section } from '.';

const sections: Section[] = [
  {
    subheader: '',
    items: [
      {
        title: 'Suppliers',
        name: 'suppliers',
        icon: UsersIcon,
        href: '/admin',
        items: [
          {
            title: 'Supplier Accounts',
            name: 'supplier-accounts',
            href: '/admin/supplier_accounts',
            icon: BarChartIcon,
          },
          {
            title: 'Supplier Companies',
            name: 'supplier-companies',
            href: '/admin/supplier_companies',
            icon: PieChartIcon,
          },
        ],
      },
      {
        title: 'Logout',
        name: 'admin-logout',
        icon: LogoutIcon,
        href: '/logout',
      },
    ],
  },
];

export default sections;
