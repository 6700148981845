import { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import AdminDashboardLayout from './layouts/AdminDashboardLayout/AdminDashboardLayout';
import MainLayout from './layouts/MainLayout';
import MerchantLayout from './layouts/MerchantLayout';
import { UserPersona } from './types/user';
import AccountSetUpView from './views/account/AccountSetUpView';
import AccountView from './views/account/AccountView';
import ChangePasswordView from './views/account/ChangePasswordView';
import SupplierListView from './views/admin/supplier/SupplierListView/SupplierListView';
import NewPasswordView from './views/auth/NewPasswordView';
import ResetPasswordView from './views/auth/ResetPasswordView';
import HomeView from './views/home/HomeView';
import ImprintView from './views/imprint/ImprintView';
import LoginView from './views/login/LoginView';
import LogoutView from './views/login/LogoutView';
import PrivacyPolicyView from './views/privacy/PrivacyPolicyView';
import TermsView from './views/terms/TermsView';
import CompanyListView from './views/admin/company/CompanyListView/CompanyListView';
import SupplierDetailView from './views/admin/supplier/SupplierDetailView/SupplierDetailView';
import CompanyDetailView from './views/admin/company/CompanyDetailView/CompanyDetailView';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? renderRoutes(route.routes) : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('./views/errors/NotFoundView')),
  },
  {
    path: '/admin',
    routes: [
      {
        exact: true,
        layout: MainLayout,
        guard: GuestGuard,
        path: '/admin/sign_in',
        component: () => <LoginView persona={UserPersona.ADMIN} />,
      },
      {
        exact: true,
        path: '/admin',
        guard: (props: any) => (
          <AuthGuard
            allowedPersonas={[UserPersona.ADMIN]}
            sendGuestsToLoginFor={UserPersona.ADMIN}
            {...props}
          />
        ),
        component: () => <Redirect to="/admin/supplier_accounts" />,
      },
      {
        exact: true,
        layout: AdminDashboardLayout,
        guard: (props: any) => (
          <AuthGuard
            allowedPersonas={[UserPersona.ADMIN]}
            sendGuestsToLoginFor={UserPersona.ADMIN}
            {...props}
          />
        ),
        path: '/admin/supplier_accounts',
        component: SupplierListView,
      },
      {
        exact: true,
        layout: AdminDashboardLayout,
        guard: (props: any) => (
          <AuthGuard
            allowedPersonas={[UserPersona.ADMIN]}
            sendGuestsToLoginFor={UserPersona.ADMIN}
            {...props}
          />
        ),
        path: '/admin/supplier_companies',
        component: CompanyListView,
      },
      {
        exact: true,
        layout: AdminDashboardLayout,
        guard: (props: any) => (
          <AuthGuard
            allowedPersonas={[UserPersona.ADMIN]}
            sendGuestsToLoginFor={UserPersona.ADMIN}
            {...props}
          />
        ),
        path: '/admin/supplier_accounts/:id',
        component: SupplierDetailView,
      },
      {
        exact: true,
        layout: AdminDashboardLayout,
        guard: (props: any) => (
          <AuthGuard
            allowedPersonas={[UserPersona.ADMIN]}
            sendGuestsToLoginFor={UserPersona.ADMIN}
            {...props}
          />
        ),
        path: '/admin/supplier_companies/:id',
        component: CompanyDetailView,
      },
    ],
  },
  {
    path: '/merchants',
    layout: MerchantLayout,
    routes: [
      {
        exact: true,
        path: '/merchants',
        guard: (props: any) => (
          <AuthGuard allowedPersonas={[UserPersona.MERCHANT, UserPersona.ADMIN]} {...props} />
        ),
        component: () => <Redirect to="/merchants/suppliers/overview" />,
      },
      {
        exact: true,
        path: '/merchants/suppliers',
        guard: (props: any) => (
          <AuthGuard allowedPersonas={[UserPersona.MERCHANT, UserPersona.ADMIN]} {...props} />
        ),
        component: () => <Redirect to="/merchants/suppliers/overview" />,
      },
      {
        exact: true,
        path: '/merchants/suppliers/overview',
        guard: (props: any) => (
          <AuthGuard allowedPersonas={[UserPersona.MERCHANT, UserPersona.ADMIN]} {...props} />
        ),
        component: lazy(() => import('./views/merchants/suppliers/Overview')),
      },
      {
        exact: true,
        path: '/merchants/suppliers/:id',
        guard: (props: any) => (
          <AuthGuard allowedPersonas={[UserPersona.MERCHANT, UserPersona.ADMIN]} {...props} />
        ),
        component: lazy(() => import('./views/merchants/suppliers/Detail')),
      },
    ],
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/',
    layout: (props: any) => <MainLayout withStageImage {...props} />,
    component: HomeView,
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    layout: (props: any) => <MainLayout withStageImage {...props} />,
    component: () => <LoginView persona={UserPersona.SUPPLIER} />,
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/logout',
        component: LogoutView,
      },
      {
        exact: true,
        path: '/confirmation',
        component: lazy(() => import('./views/auth/ConfirmationView')),
      },
      {
        exact: true,
        guard: GuestGuard,
        path: '/reset-password',
        component: ResetPasswordView,
      },
      {
        exact: true,
        guard: GuestGuard,
        path: '/new-password',
        component: NewPasswordView,
      },
      {
        exact: true,
        path: '/imprint',
        component: ImprintView,
      },
      {
        exact: true,
        path: '/privacy',
        component: PrivacyPolicyView,
      },
      {
        exact: true,
        path: '/terms',
        component: TermsView,
      },
      {
        path: '/app',
        guard: (props: any) => <AuthGuard allowedPersonas={[UserPersona.SUPPLIER]} {...props} />,
        routes: [
          {
            exact: true,
            path: '/app',
            component: () => <Redirect to="/app/dashboard" />,
          },
          {
            exact: true,
            path: '/app/dashboard',
            component: lazy(() => import('./views/dashboard/DashboardView')),
          },
          {
            exact: true,
            path: '/app/setupaccount',
            component: AccountSetUpView,
          },
          {
            exact: true,
            path: '/app/account',
            component: AccountView,
          },
          {
            exact: true,
            path: '/app/account/password',
            component: ChangePasswordView,
          },
        ],
      },
      {
        path: '/onboarding',
        guard: (props: any) => <AuthGuard allowedPersonas={[UserPersona.SUPPLIER]} {...props} />,
        routes: [
          {
            exact: true,
            path: '/onboarding',
            component: lazy(() => import('./views/onboarding/OverviewView')),
          },
          {
            exact: true,
            path: '/onboarding/basics',
            component: lazy(() => import('./views/onboarding/BasicsView')),
          },
          {
            exact: true,
            path: '/onboarding/details',
            component: lazy(() => import('./views/onboarding/DetailsView')),
          },
          {
            exact: true,
            path: '/onboarding/logo',
            component: lazy(() => import('./views/onboarding/LogoView')),
          },
          {
            exact: true,
            path: '/onboarding/products',
            component: lazy(() => import('./views/onboarding/ProductsView')),
          },
          {
            exact: true,
            path: '/onboarding/productportfolio',
            component: lazy(() => import('./views/onboarding/ProductsPortfolioView')),
          },
          {
            exact: true,
            path: '/onboarding/images',
            component: lazy(() => import('./views/onboarding/ImagesView')),
          },
          {
            exact: true,
            path: '/onboarding/preview',
            component: lazy(() => import('./views/onboarding/PreviewView')),
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

export default routes;
