// Classes, Functions and Hooks
import { FC, useEffect, useReducer } from 'react';
import { useMutation } from '@apollo/client';
import { AuthContext, initialAuthState } from './jwtAuthContext';
import LoadingScreen from '../../components/LoadingScreen';
import { authReducer } from './jwtAuthContext.reducer';
import { useHistory } from 'react-router';

// Types
import { AuthResponse } from './jwtAuthContext.types';
import { UserPersona } from '../../types/user';

// Mutations and Queries
import { SUPPLIER_LOGIN_WITH_CREDENTIALS } from '../../auth/mutations/supplierLoginWithCredentials';
import { RESET_PASSWORD } from '../../auth/mutations/resetPassword';
import { ADMIN_LOGIN_WITH_CREDENTIALS } from '../../auth/mutations/adminLoginWithCredentials';
import { CHANGE_PASSWORD } from '../../auth/mutations/changePassword';
import { CONFIRM_SUPPLIER_ACCOUNT } from '../../auth/mutations/confirmSupplierAccount';
import { CURRENT_ACCOUNT } from '../../auth/mutations/currentAccount';
import MERCHANT_ACCOUNT_LOGIN_WITH_OTP_AND_ACCOUNT_ID from '../../auth/mutations/merchantAccountLoginWithOtpAndAccountId';
import { NEW_PASSWORD } from '../../auth/mutations/newPassword';
import { REGISTER_SUPPLIER_ACCOUNT_WITH_EMAIL_AND_PASSWORD } from '../../auth/mutations/registerSupplierAccountWithEmailAndPassword';
import { RESEND_SUPPLIER_ACCOUNT_CONFIRMATION_INSTRUCTIONS } from '../../auth/mutations/resendSupplierAccountConfirmationInstructions';
import { JWTAuthContextUtils } from './jwtAuthContext.utils';

export const AuthProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialAuthState);
  const history = useHistory();

  /**
   * ACCOUNTS
   */
  const [loadAccount] = useMutation(CURRENT_ACCOUNT);

  /**
   * Merchants
   */
  const [merchantAccountLoginWithOtpAndAccountId] = useMutation(
    MERCHANT_ACCOUNT_LOGIN_WITH_OTP_AND_ACCOUNT_ID,
  );

  /**
   * Suppliers
   */
  const [loginSupplierWithBackend] = useMutation(SUPPLIER_LOGIN_WITH_CREDENTIALS);
  const [registerSupplierWithBackend] = useMutation(
    REGISTER_SUPPLIER_ACCOUNT_WITH_EMAIL_AND_PASSWORD,
  );
  const [confirmSupplierWithBackend] = useMutation(CONFIRM_SUPPLIER_ACCOUNT);
  const [resetPasswordSupplierWithBackend] = useMutation(RESET_PASSWORD);
  const [newPasswordSupplierWithBackend] = useMutation(NEW_PASSWORD);
  const [ChangePasswordSupplierWithBackend] = useMutation(CHANGE_PASSWORD);
  const [resendConfirmationInstructionsSupplierWithBackend] = useMutation(
    RESEND_SUPPLIER_ACCOUNT_CONFIRMATION_INSTRUCTIONS,
  );

  /**
   * Admins
   */
  const [loginAdminWithBackend] = useMutation(ADMIN_LOGIN_WITH_CREDENTIALS);

  /**
   * BEGIN IMPLEMENTATION OF AUTH CONTEXT METHODS
   */
  const login = async (email: string, password: string, persona: UserPersona) => {
    let result = null;
    if (persona === UserPersona.SUPPLIER) {
      result = await loginSupplierWithBackend({
        variables: {
          email,
          password,
        },
      });

      JWTAuthContextUtils.processLoginResponse(result, 'supplierLoginWithCredentials', dispatch);
    } else if (persona === UserPersona.ADMIN) {
      result = await loginAdminWithBackend({
        variables: {
          email,
          password,
        },
      });
      JWTAuthContextUtils.processLoginResponse(result, 'adminLoginWithCredentials', dispatch);
    }
  };

  const logout = () => {
    JWTAuthContextUtils.setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (
    email: string,
    password: string,
    passwordConfirmation: string,
    acceptedTerms: boolean,
  ) => {
    const result = await registerSupplierWithBackend({
      variables: {
        email,
        password,
        passwordConfirmation,
        acceptedTerms,
      },
    });

    const { account, denialReasons } = result.data.registerSupplierAccountWithEmailAndPassword;

    if (denialReasons && denialReasons.length) {
      throw new Error(denialReasons);
    }

    JWTAuthContextUtils.setConfirmationEmail(account.email);

    dispatch({
      type: 'REGISTER',
      payload: {
        user: account,
        persona: UserPersona.SUPPLIER,
        confirmationEmail: account.email,
      },
    });
  };

  const confirm = async (email: string, confirmationToken: string) => {
    const result = await confirmSupplierWithBackend({
      variables: {
        email,
        confirmationToken,
      },
    });

    const { session, denialReasons } = result.data.confirmSupplierAccount as AuthResponse;

    if (denialReasons && denialReasons.length) {
      dispatch({
        type: 'DENIAL',
        payload: {
          denialReasons,
        },
      });

      return;
    }

    JWTAuthContextUtils.removeConfirmationEmail();

    const accessToken = session.jwtToken;
    const user = session.account;

    JWTAuthContextUtils.setSession(accessToken);
    dispatch({
      type: 'CONFIRM',
      payload: {
        user,
        persona: UserPersona.SUPPLIER,
      },
    });
  };

  const resetPassword = async (email: string) => {
    resetPasswordSupplierWithBackend({
      variables: { email },
    });

    dispatch({ type: 'RESET_PASSWORD' });
  };

  const newPassword = async (
    password: string,
    passwordConfirmation: string,
    resetPasswordToken: string,
  ) => {
    const result = await newPasswordSupplierWithBackend({
      variables: { password, passwordConfirmation, resetPasswordToken },
    });

    const { denialReasons } = result.data.newPassword;

    if (denialReasons && denialReasons.length) {
      throw new Error(denialReasons);
    }

    dispatch({ type: 'NEW_PASSWORD' });
  };

  const changePassword = async (
    email: string,
    currentPassword: string,
    newPassword: string,
    newPasswordConfirmation: string,
  ) => {
    const result = await ChangePasswordSupplierWithBackend({
      variables: { email, currentPassword, newPassword, newPasswordConfirmation },
    });

    const { denialReasons } = result.data.changePassword;

    if (denialReasons && denialReasons.length) {
      throw new Error(denialReasons);
    }

    dispatch({ type: 'CHANGE_PASSWORD' });
  };

  const validateOtpForMerchantAccountId = async (
    token: string | undefined,
    otp: string | null,
    accountId: string | null,
  ) => {
    if (token && JWTAuthContextUtils.isValidToken(token)) {
      JWTAuthContextUtils.setSession(token);
      dispatch({
        type: 'INITIALISE',
        payload: {
          isAuthenticated: true,
          persona: state.persona || UserPersona.MERCHANT,
        },
      });
    } else {
      const result = await merchantAccountLoginWithOtpAndAccountId({
        variables: { otp, accountId },
      });
      JWTAuthContextUtils.processLoginResponse(
        result,
        'merchantAccountLoginWithOtpAndAccountId',
        dispatch,
      );
    }
  };

  const resendSupplierAccountConfirmationInstructions = async (email: string) => {
    resendConfirmationInstructionsSupplierWithBackend({
      variables: { email },
    });

    dispatch({ type: 'RESEND_SUPPLIER_ACCOUNT_CONFIRMATION_INSTRUCTIONS' });
  };

  /**
   * END IMPLEMENTATION OF AUTH CONTEXT METHODS
   */

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        let user;

        if (accessToken && JWTAuthContextUtils.isValidToken(accessToken)) {
          JWTAuthContextUtils.setSession(accessToken);
          const result = await loadAccount();
          user = result?.data?.currentAccount.account;

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user: user,
              persona: user.__typename,
              confirmationEmail: JWTAuthContextUtils.getConfirmationEmail(),
            },
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null,
              confirmationEmail: JWTAuthContextUtils.getConfirmationEmail(),
            },
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null,
            confirmationEmail: JWTAuthContextUtils.getConfirmationEmail(),
          },
        });
      }
    };

    initialise();
  }, [loadAccount]);

  return state.isAppReady ? (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        confirm,
        resetPassword,
        newPassword,
        changePassword,
        validateOtpForMerchantAccountId,
        resendSupplierAccountConfirmationInstructions,
      }}
    >
      {children}
    </AuthContext.Provider>
  ) : (
    <LoadingScreen />
  );
};
