import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { DELETE_SUPPLIER_COMPANY_BY_ID } from '../contexts/suppliers/mutations/deleteSupplierCompanyById';
import { DeleteSupplierCompanyById } from '../contexts/suppliers/mutations/__generated__/DeleteSupplierCompanyById';
import { GetSupplierCompaniesGrid_rows_edges_node } from '../contexts/suppliers/queries/__generated__/GetSupplierCompaniesGrid';
import { GetSupplierCompanyById_supplierCompany } from '../contexts/suppliers/queries/__generated__/GetSupplierCompanyById';

type Company =
  | GetSupplierCompaniesGrid_rows_edges_node
  | GetSupplierCompanyById_supplierCompany
  | null;

export function useDeleteSupplierCompanyById(onSuccess?: () => void, onError?: () => void) {
  const [companyToDelete, setCompanyToDelete] = useState<Company>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();

  const [deleteSupplierCompanyById] = useMutation<DeleteSupplierCompanyById>(
    DELETE_SUPPLIER_COMPANY_BY_ID,
    {
      onCompleted: () => {
        setCompanyToDelete(null);
        enqueueSnackbar(
          formatMessage({
            id: 'admin.company.delete-dialog.success',
            defaultMessage: 'Supplier company deleted successfully',
          }),
          {
            variant: 'success',
          },
        );

        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (result) => {
        enqueueSnackbar(result.message, { variant: 'error' });
        if (onError) {
          onError();
        }
      },
      update: (cache, { data }) => {
        if (data && !data.deleteSupplierCompanyById?.denialReasons) {
          cache.evict({ id: `${companyToDelete?.__typename}:${companyToDelete?.id}` });
          cache.gc();
        }
      },
    },
  );

  const selectForDelete = (company: Company): void => {
    setCompanyToDelete(company);
  };

  const cancelDeletion = (): void => {
    setCompanyToDelete(null);
  };

  const confirmDeletion = async () => {
    if (companyToDelete) {
      return deleteSupplierCompanyById({
        variables: {
          id: companyToDelete.id,
        },
      });
    }
  };

  return {
    selectForDelete,
    cancelDeletion,
    confirmDeletion,
    companyToDelete,
  };
}
