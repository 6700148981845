import { Button } from '@material-ui/core';
import clsx from 'clsx';
import { useState } from 'react';
import styles from './CookiesBanner.module.scss';
import AnalyticalCookiesSection from './sections/AnalyticalCookiesSection';
import MarketingCookiesSection from './sections/MarketingCookiesSection';
import RequiredCookiesSection from './sections/RequiredCookiesSection';

const CookiesSettings = ({ selectedCookies, isVisible, saveCookieSelection }) => {
  const [mySelection, setMySelection] = useState(selectedCookies);

  const closeSettings = () => {
    saveCookieSelection(mySelection);
  };

  return (
    <div className={clsx(styles.cookiesSettingsContainer, !isVisible && styles.hidden)}>
      <div id={styles.cookiesSettings}>
        <h2>Cookie Einstellungen</h2>
        <br />
        <p>
          Um euch den bestmöglichen Service anbieten zu können, setzen wir auf foodstarter.edeka
          Cookies ein. Das sind kleine Textdateien, die auf Deinem Computer gespeichert werden. Dazu
          zählen Cookies für den Betrieb und die Optimierung der Seite, als auch für Services, wie
          die Nutzung des Text- oder Video Chats als auch für an Deinem Nutzungsverhalten
          orientierte Werbung. Wähle selbst, welche Cookies zugelassen werden:
        </p>
        <br />
        <section>
          <RequiredCookiesSection styles={styles} />
          <AnalyticalCookiesSection
            mySelection={mySelection}
            setMySelection={setMySelection}
            styles={styles}
          />
          <MarketingCookiesSection
            mySelection={mySelection}
            setMySelection={setMySelection}
            styles={styles}
          />
        </section>
        <div className={styles.actionContainer}>
          <Button
            id="accept-btn"
            color="secondary"
            onClick={closeSettings}
            variant="contained"
            className={styles.acceptCookies}
          >
            Speichern
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CookiesSettings;
