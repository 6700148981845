import { gql } from '@apollo/client';

export const MERCHANT_ACCOUNT_LOGIN_WITH_OTP_AND_ACCOUNT_ID = gql`
  mutation MerchantAccountLoginWithOtpAndAccountId($otp: String!, $accountId: String!) {
    merchantAccountLoginWithOtpAndAccountId(input: { otp: $otp, accountId: $accountId }) {
      denialReasons
      session {
        jwtToken
        account {
          ... on MerchantAccount {
            id
            email
          }
        }
      }
    }
  }
`;

export default MERCHANT_ACCOUNT_LOGIN_WITH_OTP_AND_ACCOUNT_ID;
