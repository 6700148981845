import { FC } from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache, HttpLink } from '@apollo/client';
import { MockedProvider } from '@apollo/client/testing';
import { setContext } from '@apollo/client/link/context';
import AuthStorageAccessor from '../../utils/authStorage';
import { mocks } from '../../mocks';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_HOST,
});

const authLink = setContext((_, { headers }) => {
  const token = AuthStorageAccessor.readToken();

  if (!token) {
    return { headers };
  }

  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  };
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const GraphQlProvider: FC = ({ children }) => (
  <ApolloProvider client={apolloClient}>
    {process.env.REACT_APP_MOCKED_PROVIDER === 'true' ? (
      <MockedProvider mocks={mocks} addTypename={false}>
        {children}
      </MockedProvider>
    ) : (
      children
    )}
  </ApolloProvider>
);

export default GraphQlProvider;
