import { useQuery } from '@apollo/client';
import { Box, Container, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import Page from '../../../../components/Page';
import { GET_SUPPLIER_COMPANY_BY_ID } from '../../../../contexts/suppliers/queries/getSupplierCompanyById';
import { GetSupplierCompanyById } from '../../../../contexts/suppliers/queries/__generated__/GetSupplierCompanyById';
import { Theme } from '../../../../theme';
import { AdminBreadcrumb, AdminHeader } from '../../common';
import CompanyDetail from './CompanyDetail';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

interface ParamTypes {
  id: string;
}

const CompanyDetailView: FC = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const { id } = useParams<ParamTypes>();
  const { loading, error, data, refetch } = useQuery<GetSupplierCompanyById>(
    GET_SUPPLIER_COMPANY_BY_ID,
    {
      variables: {
        id: id,
      },
      fetchPolicy: 'network-only',
    },
  );

  let content: JSX.Element;

  if (loading || !data) {
    content = (
      <Alert variant="filled" severity="info">
        {formatMessage({
          id: 'admin.company-detail.loading',
          defaultMessage: 'Loading company data...',
        })}
      </Alert>
    );
  } else if (error) {
    content = (
      <Alert variant="filled" severity="error">
        {formatMessage({
          id: 'admin.company-detail.loading-error',
          defaultMessage: 'Error loading company data...',
        })}
      </Alert>
    );
  } else if (data && data.supplierCompany) {
    const heading: string = data?.supplierCompany?.name || '';
    const breadcrumbs: AdminBreadcrumb[] = [
      {
        label: formatMessage({
          id: 'admin.company.breadcrumbs.overview',
          defaultMessage: 'Overview',
        }),
        linkTo: '/admin',
      },
      {
        label: formatMessage({
          id: 'admin.company.breadcrumbs.companies',
          defaultMessage: 'Supplier Companies',
        }),
        linkTo: '/admin/supplier_companies',
      },
      {
        label: heading,
        linkTo: null,
      },
    ];

    content = (
      <>
        <AdminHeader heading={heading} breadcrumbs={breadcrumbs} exportSection={false} />
        <Box mt={3}>
          <CompanyDetail company={data.supplierCompany} refreshData={() => refetch()} />
        </Box>
      </>
    );
  } else {
    content = <Box></Box>;
  }

  return (
    <Page className={classes.root} title={data?.supplierCompany?.name || ''}>
      <Container maxWidth={false}>{content}</Container>
    </Page>
  );
};

export default CompanyDetailView;
