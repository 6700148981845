import { GET_SUPPLIER_COMPANIES } from '../../contexts/suppliers/queries/companies';

export const getSupplierCompaniesSearchRegionalMock = {
  request: {
    query: GET_SUPPLIER_COMPANIES,
    variables: { first: 8, regional: true },
  },
  result: {
    data: {
      supplierCompanies: {
        totalCount: 5,
        pageInfo: {
          startCursor: 'MQ',
          endCursor: 'Mm',
          hasNextPage: false,
          hasPreviousPage: false,
        },
        edges: [
          {
            cursor: 'MQ',
            node: {
              id: '1',
              name: 'Obst- und Spargelhof',
              images: ['https://via.placeholder.com/1600x700.png/666/fff'],
            },
          },
          {
            cursor: 'Mg',
            node: {
              id: '2',
              name: 'Hinterhauensteinhof',
              images: ['https://via.placeholder.com/1500x900.png/8a85ff/fff'],
            },
          },
          {
            cursor: 'Ml',
            node: {
              id: '3',
              name: 'RAUER BAUER',
              images: ['https://via.placeholder.com/1400x300.png/fff/000'],
            },
          },
          {
            cursor: 'Mo',
            node: {
              id: '4',
              name: 'Wilde Weingut u. Brauerei',
              images: ['https://via.placeholder.com/1300x600.png/8a85ff/fff'],
            },
          },
          {
            cursor: 'Mm',
            node: {
              id: '5',
              name: 'gaVerde',
              images: ['https://via.placeholder.com/1350x700.png/8a853f/fff'],
            },
          },
        ],
      },
    },
  },
};

export const getSupplierCompaniesSearchNameMock = {
  request: {
    query: GET_SUPPLIER_COMPANIES,
    variables: { first: 8, name: 'Wein' },
  },
  result: {
    data: {
      supplierCompanies: {
        totalCount: 2,
        pageInfo: {
          startCursor: 'Mo',
          endCursor: 'Mn',
          hasNextPage: false,
          hasPreviousPage: false,
        },
        edges: [
          {
            cursor: 'Mo',
            node: {
              id: '4',
              name: 'Wilde Weingut u. Brauerei',
              images: ['https://via.placeholder.com/1300x600.png/8a85ff/fff'],
            },
          },
          {
            cursor: 'Mn',
            node: {
              id: '6',
              name: 'Weinmanufaktur Gengendorf',
              images: ['https://via.placeholder.com/1550x900.png/fff/8a85ff'],
            },
          },
        ],
      },
    },
  },
};

export const getSupplierCompaniesNameContainsMock = {
  request: {
    query: GET_SUPPLIER_COMPANIES,
    variables: { first: 8, name: 'rau' },
  },
  result: {
    data: {
      supplierCompanies: {
        totalCount: 13,
        pageInfo: {
          startCursor: 'MQ',
          endCursor: 'Ma',
          hasNextPage: true,
          hasPreviousPage: false,
        },
        edges: [
          {
            cursor: 'MQ',
            node: {
              id: '1',
              name: 'Obst- und Spargelrauhof',
              images: ['https://via.placeholder.com/1600x700.png/666/fff'],
            },
          },
          {
            cursor: 'Mg',
            node: {
              id: '2',
              name: 'Hinterauensteinhof',
              images: ['https://via.placeholder.com/1500x900.png/8a85ff/fff'],
            },
          },
          {
            cursor: 'Ml',
            node: {
              id: '3',
              name: 'RAUER BAUER',
              images: ['https://via.placeholder.com/1400x300.png/fff/000'],
            },
          },
          {
            cursor: 'Mo',
            node: {
              id: '4',
              name: 'Wilde Weingut u. Brauerei',
              images: ['https://via.placeholder.com/1300x600.png/8a85ff/fff'],
            },
          },
          {
            cursor: 'Mm',
            node: {
              id: '5',
              name: 'gaVerraude',
              images: ['https://via.placeholder.com/1350x700.png/8a853f/fff'],
            },
          },
          {
            cursor: 'Mn',
            node: {
              id: '6',
              name: 'Weinraufaktur Gengendorf',
              images: ['https://via.placeholder.com/1550x900.png/fff/8a85ff'],
            },
          },
          {
            cursor: 'Md',
            node: {
              id: '7',
              name: 'SaRauWa Salatsoße GbR',
              images: ['https://via.placeholder.com/900x300.png/8a85ff/fff'],
            },
          },
          {
            cursor: 'Ma',
            node: {
              id: '8',
              name: 'SaRauWa Salatsoße GbR',
              images: ['https://via.placeholder.com/1000x500.png/fa85ff/000'],
            },
          },
        ],
      },
    },
  },
};

export const getSupplierCompaniesNameContainsPage2Mock = {
  request: {
    query: GET_SUPPLIER_COMPANIES,
    variables: { first: 8, name: 'rau', after: 'Ma' },
  },
  result: {
    data: {
      supplierCompanies: {
        totalCount: 13,
        pageInfo: {
          startCursor: 'Mia',
          endCursor: 'Mim',
          hasNextPage: false,
          hasPreviousPage: true,
        },
        edges: [
          {
            cursor: 'Mia',
            node: {
              id: '9',
              name: 'Gemüse- und Sparaugelhof',
              images: ['https://via.placeholder.com/1600x700.png/666/fff'],
            },
          },
          {
            cursor: 'Mig',
            node: {
              id: '10',
              name: 'Hinterauensteinhof',
              images: ['https://via.placeholder.com/1500x900.png/8a85ff/fff'],
            },
          },
          {
            cursor: 'Mil',
            node: {
              id: '11',
              name: 'RAUER BAUER',
              images: ['https://via.placeholder.com/1400x300.png/fff/000'],
            },
          },
          {
            cursor: 'Mio',
            node: {
              id: '12',
              name: 'Wilde Weingut u. Brauerei',
              images: ['https://via.placeholder.com/1300x600.png/8a85ff/fff'],
            },
          },
          {
            cursor: 'Mim',
            node: {
              id: '13',
              name: 'gaVerraude',
              images: ['https://via.placeholder.com/1350x700.png/8a853f/fff'],
            },
          },
        ],
      },
    },
  },
};

export const getSupplierCompaniesSearchZipMock = {
  request: {
    query: GET_SUPPLIER_COMPANIES,
    variables: { first: 8, zip: '10999' },
  },
  result: {
    data: {
      supplierCompanies: {
        totalCount: 3,
        pageInfo: {
          startCursor: 'Mo',
          endCursor: 'Mn',
          hasNextPage: false,
          hasPreviousPage: false,
        },
        edges: [
          {
            cursor: 'Mo',
            node: {
              id: '4',
              name: 'Wilde Weingut u. Brauerei',
              images: ['https://via.placeholder.com/1300x600.png/8a85ff/fff'],
            },
          },
          {
            cursor: 'Mm',
            node: {
              id: '5',
              name: 'gaVerde',
              images: ['https://via.placeholder.com/1350x700.png/8a853f/fff'],
            },
          },
          {
            cursor: 'Mn',
            node: {
              id: '6',
              name: 'Weinmanufaktur Gengendorf',
              images: ['https://via.placeholder.com/1550x900.png/fff/8a85ff'],
            },
          },
        ],
      },
    },
  },
};

export const getSupplierCompaniesSearchZipRadiusMock = {
  request: {
    query: GET_SUPPLIER_COMPANIES,
    variables: { first: 8, zip: '10999', radius: '20' },
  },
  result: {
    data: {
      supplierCompanies: {
        totalCount: 1,
        pageInfo: {
          startCursor: 'Mo',
          endCursor: 'Mo',
          hasNextPage: false,
          hasPreviousPage: false,
        },
        edges: [
          {
            cursor: 'Mo',
            node: {
              id: '4',
              name: 'Wilde Weingut u. Brauerei',
              images: ['https://via.placeholder.com/1300x600.png/8a85ff/fff'],
            },
          },
        ],
      },
    },
  },
};
