import { gql } from '@apollo/client';

export const UPDATE_SUPPLIER_COMPANY_STATUS_BY_ID = gql`
  mutation UpdateSupplierCompanyStatusById(
    $id: ID!
    $supplierCompanyAttributes: SupplierCompanyAttributes!
  ) {
    updateSupplierCompanyById(
      input: { id: $id, supplierCompanyAttributes: $supplierCompanyAttributes }
    ) {
      denialReasons
    }
  }
`;
