import { GET_SUPPLIER_COMPANIES } from '../../contexts/suppliers/queries/companies';

export const getSupplierCompaniesMock = {
  request: {
    query: GET_SUPPLIER_COMPANIES,
    variables: { first: 8 },
  },
  result: {
    data: {
      supplierCompanies: {
        totalCount: 18,
        pageInfo: {
          startCursor: 'MQ',
          endCursor: 'Ma',
          hasNextPage: true,
          hasPreviousPage: false,
        },
        edges: [
          {
            cursor: 'MQ',
            node: {
              id: '1',
              name: 'Obst- und Spargelhof',
              images: ['https://via.placeholder.com/1600x700.png/666/fff'],
            },
          },
          {
            cursor: 'Mg',
            node: {
              id: '2',
              name: 'Hinterhauensteinhof',
              images: ['https://via.placeholder.com/1500x900.png/8a85ff/fff'],
            },
          },
          {
            cursor: 'Ml',
            node: {
              id: '3',
              name: 'RAUER BAUER',
              images: ['https://via.placeholder.com/1400x300.png/fff/000'],
            },
          },
          {
            cursor: 'Mo',
            node: {
              id: '4',
              name: 'Wilde Weingut u. Brauerei',
              images: ['https://via.placeholder.com/1300x600.png/8a85ff/fff'],
            },
          },
          {
            cursor: 'Mm',
            node: {
              id: '5',
              name: 'gaVerde',
              images: ['https://via.placeholder.com/1350x700.png/8a853f/fff'],
            },
          },
          {
            cursor: 'Mn',
            node: {
              id: '6',
              name: 'Weinmanufaktur Gengendorf',
              images: ['https://via.placeholder.com/1550x900.png/fff/8a85ff'],
            },
          },
          {
            cursor: 'Md',
            node: {
              id: '7',
              name: 'SaRaWa Salatsoße GbR',
              images: ['https://via.placeholder.com/900x300.png/8a85ff/fff'],
            },
          },
          {
            cursor: 'Ma',
            node: {
              id: '8',
              name: 'SaRaWa Salatsoße GbR',
              images: ['https://via.placeholder.com/1000x500.png/fa85ff/000'],
            },
          },
        ],
      },
    },
  },
};

export const getSupplierCompaniesPage2Mock = {
  request: {
    query: GET_SUPPLIER_COMPANIES,
    variables: { first: 8, after: 'Ma' },
  },
  result: {
    data: {
      supplierCompanies: {
        totalCount: 18,
        pageInfo: {
          startCursor: 'Mia',
          endCursor: 'Maa',
          hasNextPage: true,
          hasPreviousPage: true,
        },
        edges: [
          {
            cursor: 'Mia',
            node: {
              id: '9',
              name: 'Gemüse- und Spargelhof',
              images: ['https://via.placeholder.com/1600x700.png/666/fff'],
            },
          },
          {
            cursor: 'Mig',
            node: {
              id: '10',
              name: 'Hinterhauensteinhof',
              images: ['https://via.placeholder.com/1500x900.png/8a85ff/fff'],
            },
          },
          {
            cursor: 'Mil',
            node: {
              id: '11',
              name: 'RAUER BAUER',
              images: ['https://via.placeholder.com/1400x300.png/fff/000'],
            },
          },
          {
            cursor: 'Mio',
            node: {
              id: '12',
              name: 'Wilde Weingut u. Brauerei',
              images: ['https://via.placeholder.com/1300x600.png/8a85ff/fff'],
            },
          },
          {
            cursor: 'Mim',
            node: {
              id: '13',
              name: 'gaVerde',
              images: ['https://via.placeholder.com/1350x700.png/8a853f/fff'],
            },
          },
          {
            cursor: 'Min',
            node: {
              id: '14',
              name: 'Weinmanufaktur Gengendorf',
              images: ['https://via.placeholder.com/1550x900.png/fff/8a85ff'],
            },
          },
          {
            cursor: 'Mid',
            node: {
              id: '15',
              name: 'SaRaWa Salatsoße GbR',
              images: ['https://via.placeholder.com/900x300.png/8a85ff/fff'],
            },
          },
          {
            cursor: 'Maa',
            node: {
              id: '16',
              name: 'SaRaWa Salatsoße GbR',
              images: ['https://via.placeholder.com/1000x500.png/fa85ff/000'],
            },
          },
        ],
      },
    },
  },
};

export const getSupplierCompaniesPage3Mock = {
  request: {
    query: GET_SUPPLIER_COMPANIES,
    variables: { first: 8, after: 'Maa' },
  },
  result: {
    data: {
      supplierCompanies: {
        totalCount: 18,
        pageInfo: {
          startCursor: 'Moa',
          endCursor: 'Mog',
          hasNextPage: false,
          hasPreviousPage: true,
        },
        edges: [
          {
            cursor: 'Moa',
            node: {
              id: '17',
              name: 'Brühe- und Spargelhof',
              images: ['https://via.placeholder.com/1600x700.png/666/fff'],
            },
          },
          {
            cursor: 'Mog',
            node: {
              id: '18',
              name: 'Vorderhauensteinhof',
              images: ['https://via.placeholder.com/1500x900.png/8a85ff/fff'],
            },
          },
        ],
      },
    },
  },
};
