import Cookies from 'js-cookie';

interface CookieOptions {
  samesite: '' | 'Strict' | 'Lax' | undefined;
  expires: number;
  secure: boolean;
}

const COOKIE_KEY: string = 'auth_token';
const defaultCookieOptions: CookieOptions = {
  samesite: 'Strict',
  expires: 365,
  secure: process.env.NODE_ENV !== 'development',
};

export default class AuthStorageAccessor {
  static readToken(): string | undefined {
    return Cookies.get(COOKIE_KEY);
  }

  static writeToken(token: string): void {
    Cookies.set(COOKIE_KEY, token, defaultCookieOptions);
  }

  static removeToken(): void {
    Cookies.remove(COOKIE_KEY);
  }
}
