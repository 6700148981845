import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import German from '@uppy/locales/lib/de_DE';
import { Dashboard, useUppy } from '@uppy/react';
import { useCallback } from 'react';
import { FC, useEffect } from 'react';
import { UppyBaseProps } from '../../../types/files';
import { getUppyFileParameters } from '../../../utils/file-upload';

interface Props extends UppyBaseProps {
  onS3UploadComplete: (uploadResult: Uppy.UploadResult) => void;
  onCancelAll: () => void;
  height?: string;
  width?: string;
}

const FileUploadArea: FC<Props> = ({
  acceptedFiles,
  maxFileSize,
  filesLimit,
  onS3UploadComplete,
  onCancelAll,
  height,
  width,
}) => {
  const uppyOptions = {
    restrictions: {
      maxFileSize,
      allowedFileTypes: acceptedFiles,
      maxNumberOfFiles: filesLimit,
    },
    autoProceed: true,
    allowMultipleUploads: true,
    locale: German,
  };

  useEffect(() => {
    uppy.setOptions(uppyOptions);
  }, [filesLimit]);

  const uppy = useUppy(() => {
    return Uppy<Uppy.StrictTypes>(uppyOptions).use(AwsS3, {
      getUploadParameters: getUppyFileParameters,
    });
  });

  const completeCallback = useCallback(
    (uploadResult: Uppy.UploadResult) => onS3UploadComplete(uploadResult),
    [onS3UploadComplete],
  );

  const cancelAllCallback = useCallback(() => onCancelAll(), [onCancelAll]);

  useEffect(() => {
    uppy.on('complete', completeCallback);
    uppy.on('cancel-all', cancelAllCallback);

    return () => {
      uppy.off('complete', completeCallback);
      uppy.off('cancel-all', cancelAllCallback);
    };
  }, [uppy, completeCallback, cancelAllCallback]);

  return (
    <Dashboard
      uppy={uppy}
      locale={German}
      height={height}
      showRemoveButtonAfterComplete={false}
      width={width}
    />
  );
};

export default FileUploadArea;
