import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { MerchantProvider } from '../../contexts/merchants/MerchantProvider';
import { UserPersona } from '../../types/user';
import Footer from '../common/Footer';
import TopBar from '../common/TopBar';
interface MerchantLayoutProps {
  children?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
  },
  wrapper: {
    paddingTop: 64,
    paddingBottom: theme.spacing(3),
  },
  content: {
    flex: '1 1 auto',
  },
}));

const MerchantLayout: FC<MerchantLayoutProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <MerchantProvider>
      <div className={classes.root}>
        <TopBar persona={UserPersona.MERCHANT} />
        <div className={classes.wrapper}>
          <div className={classes.content}>{children}</div>
        </div>
        <Footer />
      </div>
    </MerchantProvider>
  );
};

MerchantLayout.propTypes = {
  children: PropTypes.node,
};

export default MerchantLayout;
