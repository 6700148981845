import DetailsText from './DetailsText';

const RequiredCookiesDetails = ({ styles }) => (
  <>
    <h4>Erforderliche Cookies</h4>
    <br />
    <p className={styles.shortText}>
      Diese Cookies sind notwendig, damit Du durch die Seiten navigieren und wesentliche Funktionen
      nutzen kannst. Diese kannst Du nicht ausmachen.
    </p>
    <DetailsText>
      <p>
        Technisch erforderliche Cookies ermöglichen die Navigation auf unseren Websites. Sie sind
        erforderlich für die Kernfunktionalität der Website. Grundfunktionen wie LogIns, Zugriff auf
        sichere Bereiche, die Darstellung und Auswahl von Produkten oder Warenkörbe sind ohne sie
        nicht möglich. Die Webseite kann ohne diese Cookies nicht richtig funktionieren.
        <br />
        <a href="/privacy" target="_blank" rel="noreferrer noopener">
          Datenschutzhinweise
        </a>
      </p>
    </DetailsText>
  </>
);

export default RequiredCookiesDetails;
