import type { FC } from 'react';
import { useIntl } from 'react-intl';
import AdminPageWrapper from '../../common/AdminPageWrapper';
import SuppliersGrid from './SuppliersGrid';

const SupplierListView: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <AdminPageWrapper
      pageTitle={formatMessage({
        id: 'page-titles.admin-supplier-accounts',
        defaultMessage: 'Supplier accounts',
      })}
      heading={formatMessage({
        id: 'admin.supplier-account.header',
        defaultMessage: 'Supplier Accounts',
      })}
      breadcrumbs={[
        {
          label: formatMessage({
            id: 'admin.supplier-account.breadcrumbs.overview',
            defaultMessage: 'Overview',
          }),
          linkTo: '/admin',
        },
        {
          label: formatMessage({
            id: 'admin.supplier-account.breadcrumbs.suppliers',
            defaultMessage: 'Supplier Accounts',
          }),
          linkTo: null,
        },
      ]}
      exportSection={true}
    >
      <SuppliersGrid />
    </AdminPageWrapper>
  );
};

export default SupplierListView;
