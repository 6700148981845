import { gql } from '@apollo/client';

export const ADMIN_LOGIN_WITH_CREDENTIALS = gql`
  mutation AdminLoginWithCredentials($email: String!, $password: String!) {
    adminLoginWithCredentials(input: { email: $email, password: $password }) {
      denialReasons
      session {
        jwtToken
        account {
          ... on AdminAccount {
            id
            email
          }
        }
      }
    }
  }
`;
