import { Box, Button, FormHelperText, makeStyles, TextField } from '@material-ui/core';
import clsx from 'clsx';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { Redirect } from 'react-router';
import * as Yup from 'yup';
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { splitReduceAndJoin } from '../../utils/errorMessageHelper';

interface JWTResetPasswordProps {
  className?: string;
}

const useStyles = makeStyles(() => ({
  root: {},
  button: {
    'margin-top': '1rem',
  },
  errors: {
    'white-space': 'pre-wrap',
  },
}));

const JWTResetPassword: FC<JWTResetPasswordProps> = ({ className, ...rest }) => {
  const [emailSent, setEmailSent] = useState(false);
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { resetPassword } = useAuth();
  const isMountedRef = useIsMountedRef();

  if (emailSent) {
    return <Redirect to="/reset-password?email_sent=1" />;
  }

  return (
    <Formik
      initialValues={{
        email: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(
            formatMessage({
              id: 'reset-password-page.error.valid-email',
              defaultMessage: '"Must be a valid email',
            }),
          )
          .max(255)
          .required(
            formatMessage({
              id: 'reset-password-page.error.email',
              defaultMessage: 'Email is required',
            }),
          ),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await resetPassword(values.email);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
            setEmailSent(true);
          }
        } catch (err) {
          console.error(err);

          const translatedErrorMessage = splitReduceAndJoin(err.message, (message: string) =>
            formatMessage({
              id: `reset-password-page.error.${message}`,
              defaultMessage: message,
            }),
          );

          setStatus({ success: false });
          setErrors({ submit: translatedErrorMessage });
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form
          noValidate
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label={formatMessage({
              id: 'reset-password-page.form.email',
              defaultMessage: 'Email',
            })}
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error className={classes.errors}>
                {errors.submit}
              </FormHelperText>
            </Box>
          )}
          <Button
            color="secondary"
            disabled={isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            className={classes.button}
          >
            {formatMessage({
              id: 'reset-password-page.form.call-to-action',
              defaultMessage: 'Reset password',
            })}
          </Button>
        </form>
      )}
    </Formik>
  );
};

JWTResetPassword.propTypes = {
  className: PropTypes.string,
};

export default JWTResetPassword;
