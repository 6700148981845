import { FC, useContext, Dispatch, SetStateAction } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, FormHelperText, Grid, TextField, makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import useAuth from '../../hooks/useAuth';
import SupplierContext from '../../contexts/suppliers/SupplierContext';

const useStyles = makeStyles((theme) => ({
  name: {
    display: 'flex',
  },
  button: {
    'margin-top': '1rem',
  },
  errors: {
    'white-space': 'pre-wrap',
  },
}));

interface AccountSetupFormProps {
  setFormSubmitted: Dispatch<SetStateAction<boolean>>;
}

const AccountSetupForm: FC<AccountSetupFormProps> = ({ setFormSubmitted }) => {
  const classes = useStyles();
  const { user } = useAuth();
  const { formatMessage } = useIntl();
  const {
    firstName,
    surname,
    phone,
    fax,
    updateAndSubmitSupplierAccountState,
    updateSupplierContextState,
  } = useContext(SupplierContext);

  return (
    <Formik
      initialValues={{
        email: user ? user.email : '',
        firstName: firstName ?? '',
        surname: surname ?? '',
        phone: phone ?? '',
        fax: fax ?? '',
        submit: null,
      }}
      enableReinitialize
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required(
          formatMessage({
            id: 'account-setup.error.first-name',
            defaultMessage: 'First Name is required',
          }),
        ),
        surname: Yup.string().required(
          formatMessage({
            id: 'account-setup.error.last-name',
            defaultMessage: 'Last name is required',
          }),
        ),
        email: Yup.string()
          .email(
            formatMessage({
              id: 'account-setup.error.valid-email',
              defaultMessage: 'Must be a valid email',
            }),
          )
          .required(
            formatMessage({
              id: 'account-setup.error.email',
              defaultMessage: 'Email Address is required',
            }),
          ),
        phone: Yup.string().required(
          formatMessage({
            id: 'account-setup.error.phone',
            defaultMessage: 'Phone is required',
          }),
        ),
      })}
      onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
        try {
          const response = await updateAndSubmitSupplierAccountState(values);
          if (response.data.updateSupplierAccountByIdBasicAttributes.denialReasons === null) {
            const companyId =
              response.data.updateSupplierAccountByIdBasicAttributes.account.supplierCompanies
                .nodes[0].id;
            updateSupplierContextState({ companyId });
            setFormSubmitted(true);
            setStatus({ success: true });
          }
          setSubmitting(false);
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.firstName && errors.firstName)}
                    fullWidth
                    helperText={touched.firstName && errors.firstName}
                    label={formatMessage({
                      id: 'account-setup.first-name',
                      defaultMessage: 'Email',
                    })}
                    margin="normal"
                    name="firstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.firstName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.surname && errors.surname)}
                    fullWidth
                    helperText={touched.surname && errors.surname}
                    label={formatMessage({
                      id: 'account-setup.last-name',
                      defaultMessage: 'Last Name',
                    })}
                    margin="normal"
                    name="surname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.surname}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label={formatMessage({
                  id: 'account-setup.email',
                  defaultMessage: 'Email',
                })}
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.phone && errors.phone)}
                fullWidth
                helperText={touched.phone && errors.phone}
                label={formatMessage({
                  id: 'account-setup.phone',
                  defaultMessage: 'Phone',
                })}
                margin="normal"
                name="phone"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.fax && errors.fax)}
                fullWidth
                helperText={touched.fax && errors.fax}
                label={formatMessage({
                  id: 'account-setup.fax',
                  defaultMessage: 'Fax',
                })}
                margin="normal"
                name="fax"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fax}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error className={classes.errors}>
                    {errors.submit}
                  </FormHelperText>
                </Box>
              )}
              <Button
                color="secondary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                className={classes.button}
              >
                {formatMessage({
                  id: 'account-setup.save-button',
                  defaultMessage: 'Save',
                })}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default AccountSetupForm;
