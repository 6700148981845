import { FC, useState } from 'react';
import { makeStyles, Grid, Button, TextField, Box, FormHelperText } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Redirect } from 'react-router';
import { useSnackbar } from 'notistack';
import Wrapper from './shared/Wrapper';
import useAuth from '../../hooks/useAuth';
import { splitReduceAndJoin } from '../../utils/errorMessageHelper';
import useIsMountedRef from '../../hooks/useIsMountedRef';

const useStyles = makeStyles((theme) => ({
  button: {
    'margin-top': '1rem',
  },
  errors: {
    'white-space': 'pre-wrap',
  },
}));

const ChangePasswordView: FC = () => {
  const [passwordChanged, setPasswordChanged] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { user, changePassword } = useAuth();
  const isMountedRef = useIsMountedRef();

  if (passwordChanged) {
    return <Redirect to="/app/account" />;
  }

  return (
    <Wrapper
      formattedMessageMarkdownTitleId="page-titles.change-password"
      formattedMessageMarkdownHeaderId="change-password.header"
    >
      <Formik
        initialValues={{
          currentPassword: '',
          newPassword: '',
          newPasswordConfirmation: '',
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          currentPassword: Yup.string().required(
            formatMessage({
              id: 'change-password.error.current-password',
              defaultMessage: 'Current password is required',
            }),
          ),
          newPassword: Yup.string()
            .min(
              7,
              formatMessage({
                id: 'change-password.error.min-password',
                defaultMessage: 'Must be at least 7 characters',
              }),
            )
            .max(128)
            .required(
              formatMessage({
                id: 'change-password.error.new-password',
                defaultMessage: 'New password is required',
              }),
            ),
          newPasswordConfirmation: Yup.string().oneOf(
            [Yup.ref('newPassword')],
            formatMessage({
              id: 'change-password.error.passwords-match',
              defaultMessage: 'Passwords should match',
            }),
          ),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await changePassword(
              user?.email as string,
              values.currentPassword,
              values.newPassword,
              values.newPasswordConfirmation,
            );

            if (isMountedRef.current) {
              enqueueSnackbar(
                formatMessage({
                  id: `change-password.notification-success`,
                  defaultMessage: 'Your password has been changed',
                }),
              );
              setStatus({ success: true });
              setSubmitting(false);
              setPasswordChanged(true);
            }
          } catch (err) {
            console.error(err);

            const translatedErrorMessage = splitReduceAndJoin(err.message, (message: string) =>
              formatMessage({
                id: `change-password.error.${message}`,
                defaultMessage: message,
              }),
            );

            setStatus({ success: false });
            setErrors({ submit: translatedErrorMessage });
            setSubmitting(false);
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  error={Boolean(touched.currentPassword && errors.currentPassword)}
                  fullWidth
                  helperText={touched.currentPassword && errors.currentPassword}
                  label={formatMessage({
                    id: 'change-password.current-password',
                    defaultMessage: 'Current password',
                  })}
                  type="password"
                  margin="dense"
                  name="currentPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.currentPassword}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={Boolean(touched.newPassword && errors.newPassword)}
                  fullWidth
                  helperText={touched.newPassword && errors.newPassword}
                  label={formatMessage({
                    id: 'change-password.new-password',
                    defaultMessage: 'New password',
                  })}
                  type="password"
                  margin="dense"
                  name="newPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.newPassword}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={Boolean(touched.newPasswordConfirmation && errors.newPasswordConfirmation)}
                  fullWidth
                  helperText={touched.newPasswordConfirmation && errors.newPasswordConfirmation}
                  label={formatMessage({
                    id: 'change-password.confirm-new-password',
                    defaultMessage: 'Confirm new password',
                  })}
                  type="password"
                  margin="dense"
                  name="newPasswordConfirmation"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.newPasswordConfirmation}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                {errors.submit && (
                  <Box mt={3}>
                    <FormHelperText error className={classes.errors}>
                      {errors.submit}
                    </FormHelperText>
                  </Box>
                )}
                <Button
                  color="secondary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  variant="contained"
                  className={classes.button}
                  type="submit"
                >
                  {formatMessage({
                    id: 'change-password.button',
                    defaultMessage: 'Save',
                  })}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default ChangePasswordView;
