import sign from 'jwt-encode';
import { MERCHANT_ACCOUNT_LOGIN_WITH_OTP_AND_ACCOUNT_ID } from '../../auth/mutations/merchantAccountLoginWithOtpAndAccountId';

const secret = '';
const data = {
  account_id: '42',
  account_type: 'MerchantAccount',
  exp: (Date.now() + 12096e5) / 1000,
};
const jwtToken = sign(data, secret);

export const merchantAccountLoginWithOtpAndAccountIdMock = {
  request: {
    query: MERCHANT_ACCOUNT_LOGIN_WITH_OTP_AND_ACCOUNT_ID,
    variables: {
      otp: '123456',
      accountId: '42', // is represented by a `maid` from url params
    },
  },
  result: {
    data: {
      merchantAccountLoginWithOtpAndAccountId: {
        denialReasons: null,
        session: {
          jwtToken,
          account: {
            id: 42,
            email: 'merchant-account@foodstarter.edeka',
          },
        },
      },
    },
  },
};

// More cases, incl. negative path cases will be added once Demeter begins to take shape. -holger
