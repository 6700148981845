import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Theme } from '../../../../theme';

interface DeleteSupplierProps {
  open: boolean;
  supplierName: string;
  handleDelete: () => void;
  handleClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  supplierName: {
    color: 'red',
  },
}));

const DeleteSupplierAccountDialog: FC<DeleteSupplierProps> = ({
  handleDelete,
  handleClose,
  open,
  supplierName,
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-cy="supplierdeletedialog"
    >
      <DialogTitle id="alert-dialog-title" disableTypography={true}>
        <Typography variant="h4">
          {formatMessage({
            id: 'admin.supplier-account.delete-dialog.header-1',
            defaultMessage: `Are you sure you want to delete supplier `,
          })}
          <span className={classes.supplierName}>{supplierName}</span>
          {formatMessage({
            id: 'admin.supplier-account.delete-dialog.header-2',
            defaultMessage: '?',
          })}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {formatMessage({
            id: 'admin.supplier-account.delete-dialog.text',
            defaultMessage:
              "If you delete the supplier account, the supplier's corporate presence will also be deleted automatically. You can no longer undo the deletion process.",
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleDelete}
          color="primary"
          variant="outlined"
          data-cy="supplierdeletedialog-confirm"
        >
          {formatMessage({
            id: 'admin.supplier-account.delete-dialog.delete',
            defaultMessage: 'Delete',
          })}
        </Button>
        <Button
          onClick={handleClose}
          color="primary"
          variant="contained"
          data-cy="supplierdeletedialog-cancel"
        >
          {formatMessage({
            id: 'admin.supplier-account.delete-dialog.cancel',
            defaultMessage: 'Cancel',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteSupplierAccountDialog;
