import { FC } from 'react';
import { useIntl } from 'react-intl';
import AdminPageWrapper from '../../common/AdminPageWrapper';
import CompaniesGrid from './CompaniesGrid';

const CompanyListView: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <AdminPageWrapper
      pageTitle={formatMessage({
        id: 'page-titles.admin-supplier-companies',
        defaultMessage: 'Supplier companies',
      })}
      heading={formatMessage({
        id: 'admin.company.header',
        defaultMessage: 'Company Presentations',
      })}
      breadcrumbs={[
        {
          label: formatMessage({
            id: 'admin.company.breadcrumbs.overview',
            defaultMessage: 'Overview',
          }),
          linkTo: '/admin',
        },
        {
          label: formatMessage({
            id: 'admin.company.breadcrumbs.companies',
            defaultMessage: 'Company Presentations',
          }),
          linkTo: null,
        },
      ]}
      exportSection={false}
    >
      <CompaniesGrid />
    </AdminPageWrapper>
  );
};

export default CompanyListView;
