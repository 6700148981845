import { gql } from '@apollo/client';

export const SUPPLIER_LOGIN_WITH_CREDENTIALS = gql`
  mutation SupplierLoginWithCredentials($email: String!, $password: String!) {
    supplierLoginWithCredentials(input: { email: $email, password: $password }) {
      denialReasons
      session {
        jwtToken
        account {
          ... on SupplierAccount {
            id
            email
          }
        }
      }
    }
  }
`;
