import { Grid } from '@material-ui/core';
import RequiredCookiesDetails from '../detail-texts/RequiredCookiesDetails';
import browserIconPath from './browser.svg';

const RequiredCookiesSection = ({ styles }) => (
  <Grid container>
    <Grid item sm={1}>
      <div className={styles.iconContainer}>
        <img className={styles.icon} src={browserIconPath} alt="required cookies icon" />
      </div>
    </Grid>
    <Grid item sm={10}>
      <div className={styles.textContainer}>
        <RequiredCookiesDetails styles={styles} />
      </div>
    </Grid>
    <Grid item sm={1}>
      <div className={styles.checkboxContainer}>
        <input type="checkbox" name="essentialCookies" checked="checked" disabled />
      </div>
    </Grid>
  </Grid>
);

export default RequiredCookiesSection;
