import { useQuery } from '@apollo/client';
import { Box, Container, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import Page from '../../../../components/Page';
import { GET_SUPPLIER_ACCOUNT_BY_ID } from '../../../../contexts/suppliers/queries/getSupplierAccountById';
import { GetSupplierAccountById } from '../../../../contexts/suppliers/queries/__generated__/GetSupplierAccountById';
import { Theme } from '../../../../theme';
import { AdminBreadcrumb, AdminHeader } from '../../common';
import SupplierDetail from './SupplierDetail';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

interface ParamTypes {
  id: string;
}

const SupplierDetailView: FC = () => {
  const classes = useStyles();
  const { id } = useParams<ParamTypes>();
  const { formatMessage } = useIntl();

  const { loading, error, data } = useQuery<GetSupplierAccountById>(GET_SUPPLIER_ACCOUNT_BY_ID, {
    variables: {
      id: id,
    },
  });

  let content: JSX.Element;
  if (loading || !data) {
    content = (
      <Alert variant="filled" severity="info">
        {formatMessage({
          id: 'admin.supplier-account.loading',
          defaultMessage: 'Loading supplier data...',
        })}
      </Alert>
    );
  } else if (error) {
    content = (
      <Alert variant="filled" severity="error">
        {formatMessage({
          id: 'admin.supplier-account.loading-error',
          defaultMessage: 'Error loading supplier data.',
        })}
      </Alert>
    );
  } else if (data?.supplierAccount) {
    const heading: string =
      `${data.supplierAccount.firstName} ${data.supplierAccount.surname}`.trim();
    const breadcrumbs: AdminBreadcrumb[] = [
      {
        label: formatMessage({
          id: 'admin.supplier-account.breadcrumbs.overview',
          defaultMessage: 'Overview',
        }),
        linkTo: '/admin',
      },
      {
        label: formatMessage({
          id: 'admin.supplier-account.breadcrumbs.suppliers',
          defaultMessage: 'Supplier Accounts',
        }),
        linkTo: '/admin/supplier_accounts',
      },
      {
        label: heading,
        linkTo: null,
      },
    ];

    content = (
      <>
        <AdminHeader heading={heading} breadcrumbs={breadcrumbs} exportSection={false} />
        <Box mt={3}>
          <SupplierDetail supplier={data.supplierAccount} />
        </Box>
      </>
    );
  } else {
    content = <></>;
  }

  return (
    <Page
      className={classes.root}
      title={`${data?.supplierAccount?.firstName} ${data?.supplierAccount?.surname}`.trim()}
    >
      <Container maxWidth={false}>{content}</Container>
    </Page>
  );
};

export default SupplierDetailView;
