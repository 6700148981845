/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import { ReactNode, useEffect } from 'react';
import type { FC } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useIntl } from 'react-intl';

import NavItem from './NavItem';
import sections from './sections';
import { Item } from '.';
import useAuth from '../../../hooks/useAuth';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

function renderNavItems({
  items,
  pathname,
  depth = 0,
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items.reduce<Array<ReactNode>>(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        [],
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
}: {
  acc: ReactNode[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  const open = matchPath(pathname, {
    path: item.href,
    exact: false,
  });

  if (item.items) {
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
        name={`admin.sidebar.sections.${item.name}`}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        open={Boolean(open)}
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
        name={`admin.sidebar.sections.${item.name}`}
      />,
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  infoContainer: {
    paddingTop: 100,
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  const { user } = useAuth();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">{/* <Logo /> */}</RouterLink>
          </Box>
        </Hidden>
        <Box p={2} className={classes.infoContainer}>
          <Box display="flex" justifyContent="start">
            <RouterLink to="/app/account">
              <Avatar alt="User" className={classes.avatar} src={user?.avatar} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="left">
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h3"
              color="textPrimary"
              underline="none"
            >
              {formatMessage({ id: 'admin.sidebar.header', defaultMessage: 'Welcome!' })}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {user?.email}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func.isRequired,
  openMobile: PropTypes.bool.isRequired,
};

export default NavBar;
