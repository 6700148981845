import { gql } from '@apollo/client';
import { SUPPLIER_COMPANY_FRAGMENT } from '../fragments';

export const GET_SUPPLIER_COMPANY_BY_ID = gql`
  ${SUPPLIER_COMPANY_FRAGMENT}
  query GetSupplierCompanyById($id: ID!) {
    supplierCompany: supplierCompanyById(id: $id) {
      ...SupplierCompanyCoreData
      supplierAccount {
        id
        email
        firstName
        surname
      }
    }
  }
`;
