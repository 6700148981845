import { FC } from 'react';
import Page from '../../components/Page';
import { makeStyles, Container, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import typography from '../../theme/typography';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
  container: {
    margin: '3rem auto',
  },
  typography: {
    fontSize: typography.body1.fontSize,
    fontWeight: 'bold',
    flexDirection: 'column',
  },
}));

const ImprintView: FC = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Page
      title={formatMessage({
        id: 'page-titles.imprint',
        defaultMessage: 'Imprint',
      })}
    >
      <Container maxWidth="sm" className={classes.container}>
        <Typography variant="h1" color="textPrimary" align="center" paragraph gutterBottom>
          Impressum
        </Typography>
        <Typography variant="body2" color="textPrimary" align="left" paragraph>
          Auf dieser Seite finden Sie Informationen über den Herausgeber der Seite. Weiter finden
          Sie die Ansprechpartner für die inhaltliche und technische Realisierung der Seite.
        </Typography>
        <Typography
          variant="h2"
          color="textPrimary"
          align="left"
          paragraph
          className={classes.typography}
        >
          Herausgeber der Website
        </Typography>
        <Typography variant="body2" color="textPrimary" align="left">
          EDEKA DIGITAL GmbH
        </Typography>
        <Typography variant="body2" color="textPrimary" align="left">
          Geschäftsführung: Christoph Diekmeyer
        </Typography>
        <Typography variant="body2" color="textPrimary" align="left">
          New-York-Ring 6
        </Typography>
        <Typography variant="body2" color="textPrimary" align="left" paragraph gutterBottom>
          22297 Hamburg
        </Typography>
        <Typography variant="body2" color="textPrimary" align="left">
          Ein Unternehmen der EDEKA ZENTRALE Stiftung & Co. KG
        </Typography>
        <Typography variant="body2" color="textPrimary" align="left">
          Telefon: +49 40 6377 - 0
        </Typography>
        <Typography variant="body2" color="textPrimary" align="left">
          Telefax: +49 40 6377 - 4010
        </Typography>
        <Typography variant="body2" color="textPrimary" align="left">
          E-Mail: info.eddi@edeka.de
        </Typography>
        <Typography variant="body2" color="textPrimary" align="left">
          Handelsregister: Amtsgericht Hamburg HRB 113314
        </Typography>
        <Typography variant="body2" color="textPrimary" align="left" paragraph gutterBottom>
          USt-IdNr.: DE255759321
        </Typography>

        <Typography variant="body2" color="textPrimary" align="left">
          Technische Realisierung:
        </Typography>
        <Typography variant="body2" color="textPrimary" align="left">
          EDEKA DIGITAL GmbH
        </Typography>
        <Typography variant="body2" color="textPrimary" align="left">
          New-York-Ring 6
        </Typography>
        <Typography variant="body2" color="textPrimary" align="left">
          22297 Hamburg
        </Typography>
      </Container>
    </Page>
  );
};

export default ImprintView;
