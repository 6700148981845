import AuthStorageAccessor from '../utils/authStorage';

// custom hook to handle auth token
export const useAuthToken = () => {
  // we use the AuthStorageAccessor to access our cookie
  const authToken = AuthStorageAccessor.readToken();

  // allows to save any string as auth token
  const setAuthToken = (token: string) => AuthStorageAccessor.writeToken(token);

  // removes the auth token from our cookies, for example to logout
  const removeAuthToken = () => AuthStorageAccessor.removeToken();

  return [authToken, setAuthToken, removeAuthToken];
};
