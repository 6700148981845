import { gql } from '@apollo/client';
import { SUPPLIER_ACCOUNT_FRAGMENT } from '../fragments';

export const UPDATE_SUPPLIER_ACCOUNT_AS_ADMIN = gql`
  ${SUPPLIER_ACCOUNT_FRAGMENT}
  mutation UpdateSupplierAccountAsAdmin(
    $id: ID!
    $backofficeNotes: String
    $email: String
    $faxNumber: String
    $firstName: String
    $phoneNumber: String
    $surname: String
    $zksNumber: String
  ) {
    updateSupplierAccountByIdAsAdmin(
      input: {
        id: $id
        supplierAccountAttributes: {
          backofficeNotes: $backofficeNotes
          email: $email
          faxNumber: $faxNumber
          firstName: $firstName
          phoneNumber: $phoneNumber
          surname: $surname
          zksNumber: $zksNumber
        }
      }
    ) {
      denialReasons
      account {
        ...SupplierAccountCoreData
      }
    }
  }
`;
