import { CHANGE_PASSWORD } from '../../auth/mutations/changePassword';

export const changePasswordMock = {
  request: {
    query: CHANGE_PASSWORD,
    variables: {
      email: 'foo@bar.baz',
      currentPassword: 'Enim1234567velit-!.',
      newPassword: '3L4MzskrB-vU3Zw2kPAE',
      newPasswordConfirmation: '3L4MzskrB-vU3Zw2kPAE',
    },
  },
  result: {
    data: {
      changePassword: {
        denialReasons: null,
      },
    },
  },
};
