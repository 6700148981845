import { Grid } from '@material-ui/core';
import AnalyticalCookiesDetails from '../detail-texts/AnalyticalCookiesDetails';
import analyticsIconPath from './analytics.svg';
import ToggableCheckbox from './ToggableCheckbox';

const AnalyticalCookiesSection = ({ styles, setMySelection, mySelection }) => (
  <Grid container>
    <Grid item sm={1}>
      <div className={styles.iconContainer}>
        <label htmlFor="analyticsCookies">
          <img className={styles.icon} src={analyticsIconPath} alt="analytics cookies icon" />
        </label>
      </div>
    </Grid>
    <Grid item sm={10}>
      <div className={styles.textContainer}>
        <AnalyticalCookiesDetails styles={styles} />
      </div>
    </Grid>
    <Grid item sm={1}>
      <ToggableCheckbox
        cookieType="analyticsCookies"
        styles={styles}
        mySelection={mySelection}
        setMySelection={setMySelection}
      />
    </Grid>
  </Grid>
);

export default AnalyticalCookiesSection;
