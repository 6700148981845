import {
  Box,
  Card,
  Divider,
  IconButton,
  InputAdornment,
  makeStyles,
  SvgIcon,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import type { ChangeEvent, FC } from 'react';
import { useEffect, useState } from 'react';
import { Delete as DeleteIcon, Edit as EditIcon, Search as SearchIcon } from 'react-feather';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { LiveBadge } from '../../../../components/common/badges/LiveBadge';
import { NotLiveBadge } from '../../../../components/common/badges/NotLiveBadge';
import { GraphQLGridColumn } from '../../../../components/common/tables';
import GraphQLGrid from '../../../../components/common/tables/GaraphQlGrid';
import { GET_SUPPLIER_COMPANIES_GRID } from '../../../../contexts/suppliers/queries/getSupplierCompanies';
import { GetSupplierCompaniesGrid_rows_edges_node } from '../../../../contexts/suppliers/queries/__generated__/GetSupplierCompaniesGrid';
import { useDeleteSupplierCompanyById } from '../../../../hooks/useDeleteSupplierCompanyById';
import type { Theme } from '../../../../theme';
import { CompanyPublishedState } from '../../../../types/company';
import { DateUtils } from '../../../../utils/date-utils';
import { SupplierUtils } from '../../../../utils/supplier-utils';
import {
  QueryOrderEnum,
  SupplierCompanyForAdminsFilter,
  SupplierCompanyOrderEnum,
} from '../../../../__generated__/globalTypes';
import DeleteCompanyAccountDialog from './DeleteCompanyAccountDialog';

interface ResultsProps {
  className?: string;
}

const tabs = [
  {
    value: 'all',
    label: 'All Companies',
  },
  {
    value: 'live',
    label: 'Live',
  },
  {
    value: 'offline',
    label: 'Offline',
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  queryField: {
    width: 500,
  },
}));

const CompaniesGrid: FC<ResultsProps> = ({ className, ...rest }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [query, setQuery] = useState<string | undefined>();
  const [currentTab, setCurrentTab] = useState<string>('all');

  // Grid Logic
  const [filter, setFilter] = useState<SupplierCompanyForAdminsFilter>();

  const {
    selectForDelete,
    cancelDeletion,
    confirmDeletion,
    companyToDelete,
    // On success refetch - auto uses the latest variables/filters used
  } = useDeleteSupplierCompanyById();

  const supplierCompaniesGridColumns: GraphQLGridColumn<
    SupplierCompanyOrderEnum,
    GetSupplierCompaniesGrid_rows_edges_node
  >[] = [
    {
      name: formatMessage({
        id: 'admin.company.grid.column-headers.email',
        defaultMessage: 'E-mail',
      }),
      sortable: false,
      cell: (row) => (
        <Box display="flex" alignItems="center">
          <div>
            <Typography variant="body2" color="textPrimary">
              {row.supplierAccount.email}
            </Typography>
          </div>
        </Box>
      ),
    },
    {
      name: formatMessage({
        id: 'admin.company.grid.column-headers.company-name',
        defaultMessage: 'Company Name',
      }),
      sortable: true,
      sortAttr: SupplierCompanyOrderEnum.name,
      cell: (row) => row.name,
    },

    {
      name: formatMessage({
        id: 'admin.company.grid.column-headers.release-status',
        defaultMessage: 'Release Status',
      }),
      sortable: true,
      sortAttr: SupplierCompanyOrderEnum.published_state,
      cell: (row) =>
        formatMessage({
          id: `admin.company-detail.published-state.${row.publishedState}`,
          defaultMessage: row.publishedState || '--',
        }),
    },

    {
      name: formatMessage({
        id: 'admin.company.grid.column-headers.submission-date',
        defaultMessage: 'Submission Date',
      }),
      sortable: true,
      sortAttr: SupplierCompanyOrderEnum.submitted_at,
      cell: (row) => (
        <Tooltip title={`Timezone: ${DateUtils.DISPLAYED_TZ}`}>
          <Box>
            {row.publishedState !== CompanyPublishedState.draft &&
            row.publishedState !== CompanyPublishedState.blocked_draft &&
            row.submittedAt
              ? DateUtils.toBerlinTimezoneString(row.submittedAt)
              : '--'}
          </Box>
        </Tooltip>
      ),
    },

    {
      name: formatMessage({
        id: 'admin.company.grid.column-headers.live-status',
        defaultMessage: 'Live Status',
      }),
      sortable: false,
      cell: (row) => (SupplierUtils.isCompanyActive(row) ? <LiveBadge /> : <NotLiveBadge />),
    },

    {
      name: formatMessage({
        id: 'admin.company.grid.column-headers.release-date',
        defaultMessage: 'Release Date',
      }),
      sortable: true,
      sortAttr: SupplierCompanyOrderEnum.published_date,
      cell: (row) => (
        <Tooltip title={`Timezone: ${DateUtils.DISPLAYED_TZ}`}>
          <Box>
            {row.publishedDate ? DateUtils.toBerlinTimezoneString(row.publishedDate) : '--'}
          </Box>
        </Tooltip>
      ),
    },

    {
      name: formatMessage({
        id: 'admin.company.grid.column-headers.edit',
        defaultMessage: 'Edit',
      }),
      sortable: false,
      cell: (row) => (
        <Box alignContent="right" flexWrap="nowrap" flexDirection="row" display="flex">
          <IconButton component={RouterLink} to={`/admin/supplier_companies/${row.id}`}>
            <SvgIcon fontSize="small">
              <EditIcon />
            </SvgIcon>
          </IconButton>
          <IconButton onClick={() => selectForDelete(row)}>
            <SvgIcon fontSize="small">
              <DeleteIcon />
            </SvgIcon>
          </IconButton>
        </Box>
      ),
    },
  ];

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    if (currentTab !== value) {
      if (value === 'all') {
        setFilter({ ...filter, live: null, offline: null });
      } else if (value === 'live') {
        setFilter({
          ...filter,
          live: true,
          offline: null,
        });
      } else if (value === 'offline') {
        setFilter({
          ...filter,
          live: null,
          offline: true,
        });
      }

      setCurrentTab(value);
    }
  };

  useEffect(() => {
    if (query !== filter?.nameCont) {
      setFilter({
        ...filter,
        nameCont: query,
      });
    }
  }, [filter, query, setFilter]);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <DeleteCompanyAccountDialog
        open={companyToDelete !== null}
        handleDelete={confirmDeletion}
        handleClose={cancelDeletion}
        companyName={companyToDelete ? companyToDelete.name : ''}
      />
      <Tabs
        onChange={handleTabsChange}
        scrollButtons="auto"
        textColor="secondary"
        value={currentTab}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            value={tab.value}
            label={formatMessage({
              id: `admin.company.grid.tabs.${tab.value}`,
              defaultMessage: tab.label,
            })}
          />
        ))}
      </Tabs>
      <Divider />
      <Box p={2} minHeight={56} display="flex" alignItems="center">
        <TextField
          className={classes.queryField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          onChange={(e) => setQuery(e.target.value || undefined)}
          placeholder={formatMessage({
            id: 'admin.company.grid.search',
            defaultMessage: 'Search',
          })}
          value={query}
          variant="outlined"
        />
        <Box flexGrow={1} />
      </Box>
      <GraphQLGrid<
        SupplierCompanyOrderEnum,
        GetSupplierCompaniesGrid_rows_edges_node,
        SupplierCompanyForAdminsFilter
      >
        query={GET_SUPPLIER_COMPANIES_GRID}
        initialVariables={{
          first: 10,
          order: [{ attribute: SupplierCompanyOrderEnum.submitted_at, order: QueryOrderEnum.desc }],
        }}
        filter={filter}
        loadingMessage={formatMessage({
          id: 'admin.company.grid.loading',
          defaultMessage: 'Loading companies...',
        })}
        errorMessage={formatMessage({
          id: 'admin.company.grid.error',
          defaultMessage: 'Error loading companies...',
        })}
        columns={supplierCompaniesGridColumns}
        cypressRowName="suppliercompanyrow"
      />
    </Card>
  );
};

CompaniesGrid.propTypes = {
  className: PropTypes.string,
};

export default CompaniesGrid;
