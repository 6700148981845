import { UPDATE_SUPPLIER_ACCOUNT_BY_ID_BASIC_ATTRIBUTES } from '../../contexts/suppliers/mutations/updateSupplierAccountByIdBasicAttributes';

export const saveSupplierCompanyAndAccountMock = {
  request: {
    query: UPDATE_SUPPLIER_ACCOUNT_BY_ID_BASIC_ATTRIBUTES,
    variables: {
      firstName: 'Mark',
      surname: 'Müller',
      phone: '12345678',
      fax: '',
      zksNumber: '',
      backofficeNotes: '',
    },
  },
  result: {
    data: {
      saveSupplierAndAccount: {
        denialReasons: null,
        supplierCompanyId: 1,
        supplierAccountId: 1,
      },
    },
  },
};
