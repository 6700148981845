/**
 * From the backend enum
 */
export enum CompanyPublishedState {
  blocked_approved = 'blocked_approved',
  blocked_approval_requested = 'blocked_approval_requested',
  blocked_draft = 'blocked_draft',
  draft = 'draft',
  approval_requested = 'approval_requested',
  approved = 'approved',
}

export const EdekaRegions = {
  miha: 'Minden-Hannover',
  rr: 'Rhein-Ruhr',
  nord: 'Nord',
  sw: 'Südwest',
  nst: 'Nordbayern-Sachsen-Thüringen',
  sb: 'Südbayern',
  hr: 'Hessenring',
  ez: 'EDEKA Zentrale',
};

export enum AdvertisingAreas {
  Aschaffenburg = 'Aschaffenburg',
  Bodensee = 'Bodensee',
  Eifel = 'Eifel / Mosel',
  Heilbronn = 'Heilbronn',
  Karlsruhe = 'Karlsruhe / Rastatt',
  Ludwigshafen = 'Ludwigshafen',
  NordWurttemberg = 'Nord Württemberg',
  Odenwald = 'Odenwald',
  Ortenau = 'Ortenau',
  Pfalz = 'Pfalz',
  Rhein = 'Rhein / Neckar',
  Rheingau = 'Rheingau / FFM',
  Saarland = 'Saarland',
  Schwarzwald = 'Schwarzwald',
  SudBaden = 'Süd Baden',
  SudWurttemberg = 'Süd Württemberg',
  Ulm = 'Ulm',
}
