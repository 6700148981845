import { Button, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import SupplierContext from '../../contexts/suppliers/SupplierContext';
import useAuth from '../../hooks/useAuth';

const useStyles = makeStyles(() => ({
  divider: {
    margin: '1rem 0',
  },
  flex: {
    display: 'flex',
  },
}));

const AccountDetails: FC = ({ ...rest }) => {
  const classes = useStyles();
  const { user } = useAuth();
  const { firstName, surname, phone, fax } = useContext(SupplierContext);
  const { formatMessage } = useIntl();

  return (
    <>
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Typography variant="body1" color="textPrimary" gutterBottom>
          {formatMessage({
            id: 'account.first-name',
            defaultMessage: 'First name',
          })}
        </Typography>
        <Typography variant="body1" color="textPrimary" gutterBottom>
          {firstName}
        </Typography>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Typography variant="body1" color="textPrimary" gutterBottom>
          {formatMessage({
            id: 'account.last-name',
            defaultMessage: 'Last name',
          })}
        </Typography>
        <Typography variant="body1" color="textPrimary" gutterBottom>
          {surname}
        </Typography>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Typography variant="body1" color="textPrimary" gutterBottom>
          {formatMessage({
            id: 'account.email',
            defaultMessage: 'Email',
          })}
        </Typography>
        <Typography variant="body1" color="textPrimary" gutterBottom>
          {user?.email}
        </Typography>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Typography variant="body1" color="textPrimary" gutterBottom>
          {formatMessage({
            id: 'account.phone',
            defaultMessage: 'Phone',
          })}
        </Typography>
        <Typography variant="body1" color="textPrimary" gutterBottom>
          {phone}
        </Typography>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Typography variant="body1" color="textPrimary" gutterBottom>
          {formatMessage({
            id: 'account.fax',
            defaultMessage: 'Fax',
          })}
        </Typography>
        <Typography variant="body1" color="textPrimary" gutterBottom>
          {fax}
        </Typography>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Typography variant="body1" color="textPrimary" gutterBottom>
          {formatMessage({
            id: 'account.password',
            defaultMessage: 'Password',
          })}
        </Typography>
        <Button
          color="secondary"
          component={RouterLink}
          to="/app/account/password"
          variant="contained"
          size="small"
        >
          {formatMessage({
            id: 'account.change-password-button',
            defaultMessage: 'Change password',
          })}
        </Button>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container direction="column" justify="space-between" alignItems="center">
        <Button
          color="secondary"
          component={RouterLink}
          to="/app/setupaccount"
          variant="contained"
          size="small"
        >
          {formatMessage({
            id: 'account.edit-profile-button',
            defaultMessage: 'Edit Profile',
          })}
        </Button>
      </Grid>
    </>
  );
};

export default AccountDetails;
