import { FC } from 'react';
import { makeStyles, Container, Typography, Box, Grid } from '@material-ui/core';
import Page from '../../../components/Page';
import AccountDetails from '../AccountDetails';
import typography from '../../../theme/typography';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
  container: {
    marginTop: '5rem',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  accountSetupForm: {
    width: '100%',
    maxWidth: '500px',
    backgroundColor: '#fff',
    padding: '1.5rem',
    borderRadius: '0.6rem',
    marginTop: '1.5rem',
    margin: 'auto',
    justifyContent: 'center',
  },
  formHeader: {
    fontSize: typography.h3.fontSize,
  },
  header: {
    marginBottom: '1rem',
  },
  description: {
    marginBottom: '2rem',
  },
}));

interface WrapperProps {
  formattedMessageMarkdownTitleId: string;
  formattedMessageMarkdownHeaderId: string;
}

const Wrapper: FC<WrapperProps> = ({
  children,
  formattedMessageMarkdownTitleId,
  formattedMessageMarkdownHeaderId,
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Page
      className={classes.root}
      title={formatMessage({
        id: formattedMessageMarkdownTitleId,
        defaultMessage: 'Profile Settings',
      })}
    >
      <Container maxWidth="sm" className={classes.container}>
        <Typography variant="h1" color="textPrimary" align="center" className={classes.header}>
          {formatMessage({
            id: formattedMessageMarkdownHeaderId,
            defaultMessage: 'Profile Settings',
          })}
        </Typography>
        <Box justifyContent="center" className={classes.accountSetupForm}>
          {children}
        </Box>
      </Container>
    </Page>
  );
};

export default Wrapper;
