import { AwsS3UploadParameters } from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import { SaveCompanyFileDto, SaveCompanyFileResponseDto } from '../types/files';
import { UploadableRecordEnum } from '../__generated__/globalTypes';
import AuthStorageAccessor from './authStorage';

/**
 * Get a signed AWS S3 URL from Demeter for a specific file.
 *
 * @param file - the file data Uppy passes to this helper
 * @returns Promise<AwsS3UploadParameters>
 */
export const getUppyFileParameters = (file: Uppy.UppyFile): Promise<AwsS3UploadParameters> => {
  const fileNameEncoded = encodeURIComponent(file.name);
  const fileTypeEncoded = encodeURIComponent(file.type ?? '');
  const accessToken = AuthStorageAccessor.readToken();

  const url = `${process.env.REACT_APP_DEMETER}/s3/presign?filename=${fileNameEncoded}&type=${fileTypeEncoded}`;

  return fetch(url, {
    method: 'get',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((r) => r.json());
};

/**
 * Save data about a successfully uploaded file in the backend
 *
 * @param body - data for the file to upload
 * @returns - The response from the backend API
 */
export const saveCompanyFileData = (
  file: Uppy.UppyFile,
  companyId: string,
  uploadType: UploadableRecordEnum,
): Promise<SaveCompanyFileResponseDto> => {
  const url = `${process.env.REACT_APP_DEMETER}/api/uploads`;
  const accessToken = AuthStorageAccessor.readToken();

  const id = (file.meta as any)['key'].match(/^cache\/(.+)/)[1];
  const body: SaveCompanyFileDto = {
    upload_attributes: {
      id: id,
      storage: 'cache',
      companyId,
      uploadType,
      metadata: {
        size: file.data.size,
        filename: (file.data as File).name,
        mime_type: file.data.type,
      },
    },
  };

  return fetch(url, {
    method: 'post',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  }).then((r) => r.json());
};
