import sign from 'jwt-encode';
import { SUPPLIER_LOGIN_WITH_CREDENTIALS } from '../../auth/mutations/supplierLoginWithCredentials';

const secret = '';
const data = {
  account_id: '1234567890',
  account_type: 'SupplierAccount',
  exp: (Date.now() + 12096e5) / 1000,
};
const jwtToken = sign(data, secret);

export const supplierLoginWithCredentialsMock = {
  request: {
    query: SUPPLIER_LOGIN_WITH_CREDENTIALS,
    variables: {
      email: 'foo@bar.baz',
      password: '123',
    },
  },
  result: {
    data: {
      supplierLoginWithCredentials: {
        denialReasons: null,
        session: {
          jwtToken,
          account: {
            id: 1,
            email: 'foo@bar.baz',
          },
        },
      },
    },
  },
};

export const supplierLoginWithCredentialsUnconfirmedMock = {
  request: {
    query: SUPPLIER_LOGIN_WITH_CREDENTIALS,
    variables: {
      email: 'foo@bar.baz',
      password: 'Enim1234567velit-!.',
    },
  },
  result: {
    data: {
      supplierLoginWithCredentials: {
        denialReasons: ['ACCOUNT_UNCONFIRMED'],
        session: null,
      },
    },
  },
};

export const supplierLoginWithoutCredentialsMock = {
  request: {
    query: SUPPLIER_LOGIN_WITH_CREDENTIALS,
    variables: {
      email: '',
      password: '',
    },
  },
  result: {
    data: {
      supplierLoginWithCredentials: {
        denialReasons: ['INVALID_CREDENTIALS'],
        session: null,
      },
    },
  },
};
