import {
  adminLoginWithCredentialsMock,
  adminLoginWithCredentialsUnconfirmedMock,
  adminLoginWithoutCredentialsMock,
} from './mutations/adminLoginWithCredentialsMock';
import {
  confirmSupplierAccountMock,
  confirmSupplierAccountWithNotMatchingTokenMock,
  confirmSupplierAccountWithOutdatedConfirmationMock,
} from './mutations/confirmSupplierAccountMock';
import { merchantAccountLoginWithOtpAndAccountIdMock } from './mutations/merchantAccountLoginWithOtpMock';
import {
  newPasswordConfirmationDoesNotMatchMock,
  newPasswordMock,
} from './mutations/newPasswordMock';
import { changePasswordMock } from './mutations/changePasswordMock';
import {
  registerSupplierAccountWithEmailAndBadPasswordMock,
  registerSupplierAccountWithEmailAndPasswordMock,
  registerSupplierAccountWithEmailAndWithTooCommonPasswordMock,
  registerSupplierAccountWithEmailAndWrongPasswordConfirmationMock,
} from './mutations/registerSupplierAccountWithEmailAndPasswordMock';
import { resetPasswordMock } from './mutations/resetPasswordMock';
import { saveSupplierCompanyAndAccountMock } from './mutations/saveSupplierCompanyAndAccountMock';
import {
  supplierLoginWithCredentialsMock,
  supplierLoginWithCredentialsUnconfirmedMock,
  supplierLoginWithoutCredentialsMock,
} from './mutations/supplierLoginWithCredentialsMock';
import { getSupplierAccountMock } from './queries/getSupplierAccountMock';

import {
  getSupplierCompaniesMock,
  getSupplierCompaniesPage2Mock,
  getSupplierCompaniesPage3Mock,
} from './queries/supplierCompaniesMock';
import {
  getSupplierCompaniesNameContainsMock,
  getSupplierCompaniesNameContainsPage2Mock,
  getSupplierCompaniesSearchNameMock,
  getSupplierCompaniesSearchRegionalMock,
  getSupplierCompaniesSearchZipMock,
  getSupplierCompaniesSearchZipRadiusMock,
} from './queries/supplierCompaniesSearchMock';
import { deleteSupplierAccountByIdMock } from './mutations/deleteSupplierAccountMock';
import {
  getSupplierAccountsMock,
  getSupplierAccountsMockOffline,
  getSupplierAccountsMockOnline,
  getSupplierAccountsMockWithOneDeleted,
} from './queries/getSupplierAccountsMock';

const mockedMutations = [
  adminLoginWithCredentialsMock,
  adminLoginWithCredentialsUnconfirmedMock,
  adminLoginWithoutCredentialsMock,
  changePasswordMock,
  confirmSupplierAccountMock,
  confirmSupplierAccountWithNotMatchingTokenMock,
  confirmSupplierAccountWithOutdatedConfirmationMock,
  deleteSupplierAccountByIdMock,
  merchantAccountLoginWithOtpAndAccountIdMock,
  newPasswordConfirmationDoesNotMatchMock,
  newPasswordMock,
  registerSupplierAccountWithEmailAndBadPasswordMock,
  registerSupplierAccountWithEmailAndPasswordMock,
  registerSupplierAccountWithEmailAndWithTooCommonPasswordMock,
  registerSupplierAccountWithEmailAndWrongPasswordConfirmationMock,
  resetPasswordMock,
  saveSupplierCompanyAndAccountMock,
  supplierLoginWithCredentialsMock,
  supplierLoginWithCredentialsUnconfirmedMock,
  supplierLoginWithoutCredentialsMock,
];

const mockedQueries = [
  getSupplierAccountMock,
  getSupplierAccountsMock,
  getSupplierAccountsMockOffline,
  getSupplierAccountsMockOnline,
  getSupplierAccountsMockWithOneDeleted,
  getSupplierCompaniesMock,
  getSupplierCompaniesPage2Mock,
  getSupplierCompaniesPage3Mock,
  deleteSupplierAccountByIdMock,
  saveSupplierCompanyAndAccountMock,
];

// repeating some mocks in here to be able to call their queries multiple times
const mockedSearchQueries = [
  getSupplierCompaniesMock,
  getSupplierCompaniesPage2Mock,
  getSupplierCompaniesPage3Mock,
  getSupplierCompaniesMock,
  getSupplierCompaniesPage2Mock,
  getSupplierCompaniesPage3Mock,
  getSupplierCompaniesSearchNameMock,
  getSupplierCompaniesSearchNameMock,
  getSupplierCompaniesNameContainsMock,
  getSupplierCompaniesNameContainsPage2Mock,
  getSupplierCompaniesNameContainsMock,
  getSupplierCompaniesNameContainsPage2Mock,
  getSupplierCompaniesSearchRegionalMock,
  getSupplierCompaniesSearchRegionalMock,
  getSupplierCompaniesSearchZipMock,
  getSupplierCompaniesSearchZipRadiusMock,
];

export const mocks = [...mockedMutations, ...mockedQueries, ...mockedSearchQueries];
