export const MB = 1048576;

export interface SaveCompanyFileResponseDto {
  upload_url: string;
  id: number;
}

export type AcceptedFileTypes = 'application/pdf' | 'image/png' | 'image/jpeg';

export interface UppyBaseProps {
  acceptedFiles: AcceptedFileTypes[];
  maxFileSize: number;
  filesLimit: number;
}

export interface SaveCompanyFileDto {
  upload_attributes: {
    id: any;
    storage: 'cache';
    companyId: string;
    uploadType: string;
    metadata: {
      size: number;
      filename: string;
      mime_type: string;
    };
  };
}
