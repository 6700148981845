import { gql } from '@apollo/client';

export const CURRENT_ACCOUNT = gql`
  mutation CurrentAccount {
    currentAccount(input: {}) {
      account {
        ... on SupplierAccount {
          id
          email
          firstName
          surname
        }
        ... on MerchantAccount {
          id
          email
        }
        ... on AdminAccount {
          id
          email
        }
      }
    }
  }
`;
