import { FC } from 'react';
import Page from '../../../components/Page';
import { makeStyles, Container, Box, Grid, Typography } from '@material-ui/core';
import JWTRegister from '../../../auth/RegisterView/JWTRegister';
import typography from '../../../theme/typography';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    marginTop: '5rem',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  titleText: {
    [theme.breakpoints.up('sm')]: {
      backgroundColor: '#fff',
      padding: '0.4rem 0 0.4rem 0.8rem',
      borderRadius: '0.6rem',
    },
  },
  registryForm: {
    backgroundColor: '#fff',
    padding: '2rem 2rem',
    borderRadius: '0.6rem',
  },
  formHeader: {
    fontSize: typography.h3.fontSize,
  },
}));

const HomeView: FC = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Page className={classes.root} title="Home">
      <Container maxWidth="md" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} className={classes.title}>
            <Typography variant="h1" color="textPrimary" className={classes.titleText}>
              {formatMessage({
                id: 'registration-page.header',
                defaultMessage: 'For more regional products',
              })}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box justifyContent="center" className={classes.registryForm}>
              <Typography variant="h2" className={classes.formHeader}>
                {formatMessage({
                  id: 'registration-page.form.header',
                  defaultMessage: 'Register',
                })}
              </Typography>
              <JWTRegister />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default HomeView;
