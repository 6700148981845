import { gql } from '@apollo/client';

export const REGISTER_SUPPLIER_ACCOUNT_WITH_EMAIL_AND_PASSWORD = gql`
  mutation RegisterSupplierAccountWithEmailAndPassword(
    $email: String!
    $password: String!
    $passwordConfirmation: String!
    $acceptedTerms: Boolean!
  ) {
    registerSupplierAccountWithEmailAndPassword(
      input: {
        email: $email
        password: $password
        passwordConfirmation: $passwordConfirmation
        acceptedTerms: $acceptedTerms
      }
    ) {
      denialReasons
      account {
        ... on SupplierAccount {
          id
          email
        }
      }
    }
  }
`;
