import { FC, useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import LoadingScreen from '../../components/LoadingScreen';
import useAuth from '../../hooks/useAuth';
import useUrlParams from '../../hooks/useUrlParams';
import AuthStorageAccessor from '../../utils/authStorage';

// Types
import { UserPersona } from '../../types/user';
import MerchantContext from './merchantContext';

export const MerchantProvider: FC = ({ children }) => {
  const query = useUrlParams();
  const otp = query.get('otp');
  const accountId = query.get('maid');
  const { validateOtpForMerchantAccountId, isAuthenticated, denialReasons, persona } = useAuth();
  const [validatingCredentials, setValidatingCredentials] = useState(false);
  const token = AuthStorageAccessor.readToken();
  const isAuthFromLion = otp && accountId;
  const isMerchantOrAdmin = persona === UserPersona.MERCHANT || persona === UserPersona.ADMIN;
  const [queryAmount, setQueryAmount] = useState(8);
  const [lastSelectedCompany, setLastSelectedCompany] = useState('');

  useEffect(() => {
    if (!validatingCredentials) {
      if ((!!token && isMerchantOrAdmin) || isAuthFromLion) {
        setValidatingCredentials(true);
        validateOtpForMerchantAccountId(token, otp, accountId);
      }
    }
  }, [
    otp,
    accountId,
    validateOtpForMerchantAccountId,
    validatingCredentials,
    token,
    isMerchantOrAdmin,
    isAuthFromLion,
  ]);

  if (isAuthenticated && isMerchantOrAdmin && !validatingCredentials) {
    // We can login, verify credentials/token
    return <LoadingScreen />;
  }

  if (!isAuthenticated && isAuthFromLion) {
    // We can login, verify credentials/token
    return <LoadingScreen />;
  }

  if (!validatingCredentials && !isAuthenticated && !token) {
    // We tried logging-in but we are not authenticated
    return <Redirect to="/login" />;
  }
  if ((isAuthenticated && isMerchantOrAdmin) || (denialReasons && denialReasons.length)) {
    // We are logged-in or experienced denial reasons
    return (
      <MerchantContext.Provider
        value={{
          validatingCredentials,
          isAuthenticated,
          denialReasons,
          queryAmount,
          setQueryAmount,
          lastSelectedCompany,
          setLastSelectedCompany,
        }}
      >
        {children}
      </MerchantContext.Provider>
    );
  }

  return <>{children}</>;
};
