import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  CardProps,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import React from 'react';
import { FC, ReactNode } from 'react';
import ExternalDownloadLink from '../links/ExternalDownloadLink';

const useStyles = makeStyles(() => ({
  mediaRoot: {
    width: 200,
  },
  media: {
    height: 120,
    backgroundSize: 'cover',
  },
}));

interface Props extends CardProps {
  image: string;
  buttons?: ReactNode;
  content?: ReactNode;
  downloadLink?: string;
  tooltipContent?: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal;
}

const ImageCard: FC<Props> = React.forwardRef<unknown, Props>(
  ({ image, buttons, content, downloadLink, tooltipContent, className, ...rest }, ref) => {
    const classes = useStyles();

    return (
      <Card className={className ? className : classes.mediaRoot} {...rest} ref={ref}>
        <CardActionArea>
          <ExternalDownloadLink href={downloadLink}>
            <Tooltip placement="right" title={tooltipContent ?? ''}>
              <CardMedia className={classes.media} image={image} />
            </Tooltip>
          </ExternalDownloadLink>
          {content && <CardContent>{content}</CardContent>}
        </CardActionArea>
        {buttons && <CardActions>{buttons}</CardActions>}
      </Card>
    );
  },
);

export default ImageCard;
