import { gql } from '@apollo/client';

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword(
    $email: String!
    $currentPassword: String!
    $newPassword: String!
    $newPasswordConfirmation: String!
  ) {
    changePassword(
      input: {
        email: $email
        currentPassword: $currentPassword
        newPassword: $newPassword
        newPasswordConfirmation: $newPasswordConfirmation
      }
    ) {
      denialReasons
    }
  }
`;
