import { gql } from '@apollo/client';
import { SUPPLIER_COMPANY_FRAGMENT } from '../fragments';

export const GET_SUPPLIER_COMPANIES_GRID = gql`
  ${SUPPLIER_COMPANY_FRAGMENT}
  query GetSupplierCompaniesGrid(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [SupplierCompanyQueryOrder!]
    $filter: SupplierCompanyForAdminsFilter
  ) {
    rows: supplierCompanies(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      filter: $filter
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...SupplierCompanyCoreData
          supplierAccount {
            email
          }
        }
      }
    }
  }
`;
