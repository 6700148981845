import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import type { FC, ReactNode } from 'react';
import Footer from '../common/Footer';
import TopBar from '../common/TopBar';
import stageRegionalImage from '../../assets/stage_regional.png';
import { SupplierProvider } from '../../contexts/suppliers/SupplierProvider';

interface MainLayoutProps {
  children?: ReactNode;
  withStageImage?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    overflow: 'hidden',
    paddingBottom: theme.spacing(3),
    paddingTop: 64,
  },
  stageImage: {
    backgroundImage: `url(${stageRegionalImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
  },
}));

const MainLayout: FC<MainLayoutProps> = ({ children, withStageImage }) => {
  const classes = useStyles();

  return (
    <SupplierProvider>
      <div className={classes.root}>
        <TopBar />
        <div className={clsx(classes.wrapper, withStageImage && classes.stageImage)}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>{children}</div>
          </div>
        </div>
        <Footer />
      </div>
    </SupplierProvider>
  );
};

export default MainLayout;
