/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CommodityGroupLevelEnum {
  header = "header",
  sub = "sub",
}

export enum ConfirmationDenialReasonEnum {
  CONFIRMATION_TOKEN_DOES_NOT_MATCH = "CONFIRMATION_TOKEN_DOES_NOT_MATCH",
  SUPPLIER_ACCOUNT_EMAIL_NEEDS_TO_BE_CONFIRMED_WITHIN_3_DAYS_PLEASE_REQUEST_A_NEW_ONE = "SUPPLIER_ACCOUNT_EMAIL_NEEDS_TO_BE_CONFIRMED_WITHIN_3_DAYS_PLEASE_REQUEST_A_NEW_ONE",
  SUPPLIER_ACCOUNT_IS_ALREADY_CONFIRMED = "SUPPLIER_ACCOUNT_IS_ALREADY_CONFIRMED",
}

export enum DeleteUploadDenialReasonEnum {
  RECORD_NOT_FOUND = "RECORD_NOT_FOUND",
  RECORD_THIS_OPERATION_IS_NOT_ALLOWED = "RECORD_THIS_OPERATION_IS_NOT_ALLOWED",
}

export enum MerchantAccountValidationDenialReasonEnum {
  API_ERRORS = "API_ERRORS",
  OTP_INVALID = "OTP_INVALID",
}

export enum QueryOrderEnum {
  asc = "asc",
  desc = "desc",
}

export enum SessionDenialReasonEnum {
  ACCOUNT_LOCKED = "ACCOUNT_LOCKED",
  ACCOUNT_UNCONFIRMED = "ACCOUNT_UNCONFIRMED",
  INVALID_CREDENTIALS = "INVALID_CREDENTIALS",
  NO_PREVIOUS_SESSION = "NO_PREVIOUS_SESSION",
}

export enum SupplierAccountChangePasswordDenialReasonEnum {
  CURRENT_PASSWORD_IS_INVALID = "CURRENT_PASSWORD_IS_INVALID",
  PASSWORD_CAN_T_BE_BLANK = "PASSWORD_CAN_T_BE_BLANK",
  PASSWORD_CONFIRMATION_DOESN_T_MATCH_PASSWORD = "PASSWORD_CONFIRMATION_DOESN_T_MATCH_PASSWORD",
  PASSWORD_DOES_NOT_HAVE_ENOUGH_UNIQUE_CHARACTERS = "PASSWORD_DOES_NOT_HAVE_ENOUGH_UNIQUE_CHARACTERS",
  PASSWORD_IS_NOT_VALID = "PASSWORD_IS_NOT_VALID",
  PASSWORD_IS_TOO_COMMON = "PASSWORD_IS_TOO_COMMON",
  PASSWORD_IS_TOO_SHORT_MINIMUM_IS_7_CHARACTERS = "PASSWORD_IS_TOO_SHORT_MINIMUM_IS_7_CHARACTERS",
  SOMETHING_WENT_WRONG = "SOMETHING_WENT_WRONG",
}

export enum SupplierAccountNewPasswordDenialReasonEnum {
  PASSWORD_CAN_T_BE_BLANK = "PASSWORD_CAN_T_BE_BLANK",
  PASSWORD_CONFIRMATION_DOESN_T_MATCH_PASSWORD = "PASSWORD_CONFIRMATION_DOESN_T_MATCH_PASSWORD",
  PASSWORD_DOES_NOT_HAVE_ENOUGH_UNIQUE_CHARACTERS = "PASSWORD_DOES_NOT_HAVE_ENOUGH_UNIQUE_CHARACTERS",
  PASSWORD_IS_NOT_VALID = "PASSWORD_IS_NOT_VALID",
  PASSWORD_IS_TOO_COMMON = "PASSWORD_IS_TOO_COMMON",
  PASSWORD_IS_TOO_SHORT_MINIMUM_IS_7_CHARACTERS = "PASSWORD_IS_TOO_SHORT_MINIMUM_IS_7_CHARACTERS",
  RESET_PASSWORD_TOKEN_EXPIRED = "RESET_PASSWORD_TOKEN_EXPIRED",
  RESET_PASSWORD_TOKEN_IS_NOT_VALID = "RESET_PASSWORD_TOKEN_IS_NOT_VALID",
}

export enum SupplierAccountOrderEnum {
  confirmed_at = "confirmed_at",
  created_at = "created_at",
  email = "email",
  first_name = "first_name",
  id = "id",
  surname = "surname",
  updated_at = "updated_at",
}

export enum SupplierAccountRegistrationDenialReasonEnum {
  EMAIL_AND_PASSWORD_ARE_TOO_SIMILAR = "EMAIL_AND_PASSWORD_ARE_TOO_SIMILAR",
  EMAIL_CAN_T_BE_BLANK = "EMAIL_CAN_T_BE_BLANK",
  ERROR_REGISTERING_EMAIL = "ERROR_REGISTERING_EMAIL",
  NAME_AND_THE_PASSWORD_ARE_TOO_SIMILAR = "NAME_AND_THE_PASSWORD_ARE_TOO_SIMILAR",
  PASSWORD_CAN_T_BE_BLANK = "PASSWORD_CAN_T_BE_BLANK",
  PASSWORD_CONFIRMATION_DOESN_T_MATCH_PASSWORD = "PASSWORD_CONFIRMATION_DOESN_T_MATCH_PASSWORD",
  PASSWORD_DOES_NOT_HAVE_ENOUGH_UNIQUE_CHARACTERS = "PASSWORD_DOES_NOT_HAVE_ENOUGH_UNIQUE_CHARACTERS",
  PASSWORD_IS_NOT_ALLOWED = "PASSWORD_IS_NOT_ALLOWED",
  PASSWORD_IS_NOT_VALID = "PASSWORD_IS_NOT_VALID",
  PASSWORD_IS_TOO_COMMON = "PASSWORD_IS_TOO_COMMON",
  PASSWORD_IS_TOO_SHORT_MINIMUM_IS_7_CHARACTERS = "PASSWORD_IS_TOO_SHORT_MINIMUM_IS_7_CHARACTERS",
  PASSWORD_SHOULD_NOT_INCLUDE_EDEKA_OR_FOODSTARTER = "PASSWORD_SHOULD_NOT_INCLUDE_EDEKA_OR_FOODSTARTER",
  TERMS_MUST_BE_ACCEPTED = "TERMS_MUST_BE_ACCEPTED",
}

export enum SupplierAccountUpdateDenialReasonEnum {
  SUPPLIER_ACCOUNT_ID_OR_CREDENTIALS_WRONG = "SUPPLIER_ACCOUNT_ID_OR_CREDENTIALS_WRONG",
  SUPPLIER_ACCOUNT_NOT_FOUND = "SUPPLIER_ACCOUNT_NOT_FOUND",
  SUPPLIER_ACCOUNT_THIS_OPERATION_IS_NOT_ALLOWED = "SUPPLIER_ACCOUNT_THIS_OPERATION_IS_NOT_ALLOWED",
}

export enum SupplierCompaniesFilteredDenialReasonEnum {
  POSTAL_CODE_INVALID = "POSTAL_CODE_INVALID",
  SEARCH_GEOCODING_ERROR = "SEARCH_GEOCODING_ERROR",
}

export enum SupplierCompanyOrderEnum {
  created_at = "created_at",
  id = "id",
  minimum_order_value = "minimum_order_value",
  name = "name",
  postal_code = "postal_code",
  published_date = "published_date",
  published_state = "published_state",
  submitted_at = "submitted_at",
  supplier_account_id = "supplier_account_id",
  updated_at = "updated_at",
}

export enum SupplierCompanyUpdateDenialReasonEnum {
  SUPPLIER_COMPANY_ID_OR_CREDENTIALS_WRONG = "SUPPLIER_COMPANY_ID_OR_CREDENTIALS_WRONG",
  SUPPLIER_COMPANY_NOT_FOUND = "SUPPLIER_COMPANY_NOT_FOUND",
  SUPPLIER_COMPANY_THIS_OPERATION_IS_NOT_ALLOWED = "SUPPLIER_COMPANY_THIS_OPERATION_IS_NOT_ALLOWED",
}

export enum UploadableRecordEnum {
  Image = "Image",
  ProductPortfolio = "ProductPortfolio",
  SupplierCompanyLogo = "SupplierCompanyLogo",
}

export interface ProductPortfolioAttributes {
  name: string;
  portfolioUrl?: string | null;
}

export interface SupplierAccountAttributes {
  email?: string | null;
  firstName?: string | null;
  surname?: string | null;
  faxNumber?: string | null;
  phoneNumber?: string | null;
  zksNumber?: string | null;
  backofficeNotes?: string | null;
}

export interface SupplierAccountForAdminsFilter {
  nameCont?: string | null;
  live?: boolean | null;
  offline?: boolean | null;
}

export interface SupplierAccountQueryOrder {
  attribute: SupplierAccountOrderEnum;
  order: QueryOrderEnum;
}

export interface SupplierCompanyAttributes {
  name?: string | null;
  advertisingArea?: string | null;
  edekaRegion?: string | null;
  listing?: string | null;
  description?: string | null;
  website?: string | null;
  logo?: string | null;
  postalCode?: string | null;
  city?: string | null;
  district?: string | null;
  deliveryArea?: string | null;
  commodityGroups?: string[] | null;
  products?: string | null;
  productPortfolio?: ProductPortfolioAttributes | null;
  minimumOrderValue?: string | null;
  images?: string[] | null;
  publishedState?: string | null;
  publishedDate?: any | null;
  submittedAt?: any | null;
}

export interface SupplierCompanyFilter {
  nameCont?: string | null;
  listed?: boolean | null;
  commodityGroup?: string | null;
  postalCode?: string | null;
  distance?: number | null;
}

export interface SupplierCompanyForAdminsFilter {
  nameCont?: string | null;
  listed?: boolean | null;
  commodityGroup?: string | null;
  postalCode?: string | null;
  distance?: number | null;
  live?: boolean | null;
  offline?: boolean | null;
}

export interface SupplierCompanyQueryOrder {
  attribute: SupplierCompanyOrderEnum;
  order: QueryOrderEnum;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
