import { DELETE_SUPPLIER_ACCOUNT_BY_ID } from '../../contexts/suppliers/mutations/deleteSupplierAccountById';

export const deleteSupplierAccountByIdMock = {
  request: {
    query: DELETE_SUPPLIER_ACCOUNT_BY_ID,
    variables: {
      id: '1',
    },
  },
  result: {
    data: {
      deleteSupplierAccount: {
        denialReasons: null,
      },
    },
  },
};
