import { FC } from 'react';
import Page from '../../components/Page';
import { makeStyles, Link, Container, Typography } from '@material-ui/core';
import typography from '../../theme/typography';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
  container: {
    margin: '3rem auto',
  },

  typography: {
    fontSize: typography.body1.fontSize,
    fontWeight: 'bold',
    flexDirection: 'column',
  },
  list: {
    marginLeft: '20px',
  },
}));

const TermsView: FC = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Page
      title={formatMessage({
        id: 'page-titles.terms',
        defaultMessage: 'Terms',
      })}
    >
      <Container maxWidth="sm" className={classes.container}>
        <Typography variant="h1" color="textPrimary" align="center" paragraph gutterBottom>
          Allgemeine Geschäftsbedingungen für Regionalpartner von foodstarter
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Hier findest Du unsere Allgemeinen Geschäftsbedingungen. Bei Abschluss der Registrierung
          erklärst Du, dass Du diese gelesen und akzeptiert hast.
        </Typography>
        <Typography
          variant="h2"
          color="textPrimary"
          align="left"
          paragraph
          className={classes.typography}
        >
          1. Allgemeines
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          (1) foodstarter ist eine Plattform für die Kontaktanbahnung zwischen
          Lebensmittelproduzenten („Partnerunternehmen“) und Unternehmen des EDEKA-Verbunds.
          Unternehmen des EDEKA-Verbunds im Sinne dieser Bedingungen sind die EDEKA-Genossenschaften
          und ihre Mitglieder sowie a) Gesellschaften, an denen die EDEKA-Genossenschaften einzeln
          oder gemeinsam mittelbar oder unmittelbar zu mindestens 50 % beteiligt sind und b)
          sämtliche Unternehmen, die berechtigt sind, das EDEKA „Block E“ gemäß der
          Kennzeichnungsrichtlinie des EDEKA Verbandes e.V zu führen. Die Unternehmen des
          EDEKA-Verbunds werden nachfolgend als „Kaufleute“ bezeichnet; Partnerunternehmen und
          Kaufleute werden nachfolgend gemeinsam „Nutzer“ genannt.
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          (2) foodstarter wird von der EDEKA DIGITAL GmbH, New-York-Ring 6, 22297 Hamburg, angeboten
          und betrieben (nachfolgend auch „Anbieter“).
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          (3) Die nachfolgenden allgemeinen Geschäftsbedingungen für Regionalpartner (AGB).
          Bedingungen gelten für Regionalpartner, d.h. für solche Lieferanten von Lebensmitteln und
          Verbrauchsgütern, die vorwiegend an Abnehmer in einem regionalen Umfeld liefern,
          insbesondere im Liefergebiet nur einer regionalen EDEKA-Großhandlung. Die für überregional
          tätige Partnerunternehmen geltenden „Allgemeinen Geschäftsbedingungen foodstarter“ finden
          dann keine Anwendung. Durch die Nutzung der von foodstarter für Regionalpartner
          angebotenen Leistungen erklären sich die Nutzer mit diesen einverstanden. Abweichende
          allgemeine Geschäftsbedingungen der Nutzer werden nicht anerkannt, es sei denn, der
          Anbieter stimmt ihrer Geltung ausdrücklich zu.
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          (4) Der Anbieter behält sich das Recht vor, jederzeit Änderungen an foodstarter
          vorzunehmen. Ferner behält er sich das Recht vor, diese AGB zu ändern oder zu ergänzen,
          sofern sich die Gesetzeslage oder die höchstrichterliche Rechtsprechung ändert oder eine
          Änderung aus technischen Gründen, aufgrund der Änderung des Leistungsangebotes oder zur
          Optimierung der Inhalte erforderlich ist. Änderungen dieser AGB werden den Nutzern
          mitgeteilt. Widersprechen diese dieser Änderung nicht innerhalb von zwei Wochen nach
          Zugang der Mitteilung, gelten die Änderungen als durch sie anerkannt. Auf das Recht zum
          Widerspruch und den Rechtsfolgen des Schweigens werden sie per E-Mail hingewiesen. Das
          Widerspruchsrecht gilt nicht bei Preisänderungen hinsichtlich Leistungen des Anbieters,
          diese bedürfen der ausdrücklichen Zustimmung der Partnerunternehmen.
        </Typography>

        <Typography
          variant="h2"
          color="textPrimary"
          align="left"
          paragraph
          className={classes.typography}
        >
          2. Verantwortungsabgrenzung
        </Typography>
        <Typography
          variant="h3"
          color="textSecondary"
          align="left"
          paragraph
          className={classes.typography}
        >
          (1) Allgemein
        </Typography>
        <Typography variant="body2" align="left" gutterBottom>
          <ul className={classes.list}>
            <li>
              foodstarter ist eine Plattform für die Kontaktanbahnung zwischen Regionalpartnern und
              Kaufleuten;
            </li>
            <li>
              foodstarter bietet Regionalpartnern die Möglichkeit, sich einer relevanten Gruppe von
              Kaufleuten zu präsentieren;
            </li>
            <li>
              foodstarter bietet Regionalpartnern die Möglichkeit, sich einer relevanten Gruppe von
              Kaufleuten zu präsentieren;
            </li>
            <li>
              Ggf. von den Partnerunternehmen eingetragenen Produktinformationen werden vom Anbieter
              weder auf Plausibilität noch auf Richtigkeit geprüft.
            </li>
          </ul>
        </Typography>

        <Typography
          variant="h3"
          color="textSecondary"
          align="left"
          paragraph
          className={classes.typography}
        >
          (2) Partnerunternehmen
        </Typography>
        <Typography variant="body2" align="left" gutterBottom>
          <ul className={classes.list}>
            <li>
              Der Regionalpartner trägt die rechtliche Verantwortung für alle von ihm eingestellten
              Inhalte (siehe Ziffer 6.).
            </li>
            <li>
              Der Regionalpartner hat, soweit er Angaben zu Produkten macht, vollständige und
              korrekte Produktangaben zu machen;
            </li>
          </ul>
        </Typography>
        <Typography
          variant="h3"
          color="textSecondary"
          align="left"
          paragraph
          className={classes.typography}
        >
          (3) Kaufleute
        </Typography>
        <Typography variant="body2" align="left" gutterBottom>
          <ul className={classes.list}>
            <li>
              Die Kaufleute entscheiden allein, welche Produkte in welcher Menge sie von welchem
              Regionalpartner beziehen möchten;
            </li>
            <li>Der Anbieter überwacht nicht den Verlauf von Produkt- und Musterbestellungen;</li>
            <li>
              Der Anbieter ist nicht für die Sicherstellung der Lieferfähigkeit der Regionalpartnern
              verantwortlich.
            </li>
          </ul>
        </Typography>

        <Typography
          variant="h2"
          color="textPrimary"
          align="left"
          paragraph
          className={classes.typography}
        >
          3. Nutzer-Account
        </Typography>

        <Typography variant="body2" align="left" paragraph>
          (1) Für die Nutzung von foodstarter ist eine Registrierung erforderlich. Um einen
          Nutzer-Account anlegen zu können, muss der Nutzer entweder ein gewerblicher
          Inverkehrbringer von Lebensmitteln und/oder anderen Verbrauchsgütern sein oder dem
          EDEKA-Verbund angehören. Regionalpartner haben keinen Anspruch darauf, dass sie
          registriert werden. Der Anbieter behält sich vor, ohne Angabe von Gründen Kontoeröffnungen
          abzulehnen oder Accounts zu sperren und zu schließen.
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          (2) Die Angaben beim Anlegen des Accounts müssen korrekt und vollständig sein. Nutzer
          dürfen sich weder als jemand anderen ausgeben noch anstößige oder rechtswidrige Namen und
          Beschreibungen innerhalb ihres Kontos verwenden. Die Anmeldung einer juristischen Person
          oder Personengesellschaft darf nur von einer bevollmächtigten Person vorgenommen werden,
          die namentlich zu nennen ist. Pro Unternehmen ist nur eine Registrierung zulässig. Ändern
          sich die Registrierungsdaten, ist der Account unverzüglich zu aktualisieren. Sollten die
          Nutzer diese Regeln missachten, behalten wir uns das Recht vor, das Konto ohne
          Vorankündigung zu sperren oder zu löschen.
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          (3) Die Nutzer tragen die Verantwortung für alle Aktivitäten in ihrem Account sowie für
          die Geheimhaltung ihres Passworts. Falls Nutzer bemerken, dass jemand ihr Konto ohne ihre
          Zustimmung genutzt hat, haben sie dies unverzüglich an info@foodstarter.edeka zu melden.
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          (4) Nutzer können ihren Account jederzeit löschen und den Nutzervertrag mit dem Anbieter
          mit einer Frist von zwei Wochen jederzeit kündigen. Die Löschung kann über
          info@foodstarter.edeka beantragt werden. Bereits von dem Nutzer hochgeladene Inhalte
          werden durch das Löschen des Kontos nicht entfernt und sind auf der Plattform weiterhin
          einsehbar, sofern sie nicht seitens des Anbieters gelöscht werden.
        </Typography>

        <Typography
          variant="h2"
          color="textPrimary"
          align="left"
          paragraph
          className={classes.typography}
        >
          4. Leistungen von foodstarter für Regionalpartner
        </Typography>

        <Typography variant="body2" align="left" paragraph>
          Mit Abschluss der Registrierung erhält der Regionalpartner die Möglichkeit, die Inhalte
          für seinen Unternehmensauftritt auf foodstarter einzutragen. Nach der Veröffentlichung des
          Unternehmensauftritts auf foodstarter können Kaufleute die eingetragenen Informationen
          ansehen, um Kontakt zum Regionalpartner für eine potentielle Geschäftsbeziehung
          aufzunehmen. Der Unternehmensauftritt kann durch den Regionalpartner aktualisiert und
          erweitert werden.
        </Typography>

        <Typography
          variant="h2"
          color="textPrimary"
          align="left"
          paragraph
          className={classes.typography}
        >
          5. Laufzeit
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Das Leistungspaket ist mit einer Kündigungsfrist von einer Woche monatlich kündbar.
        </Typography>

        <Typography
          variant="h2"
          color="textPrimary"
          align="left"
          paragraph
          className={classes.typography}
        >
          6. Produktrechtliche Verantwortung
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          (1) Der Regionalpartner gewährleistet, dass die verkauften und gelieferten Artikel den in
          Deutschland geltenden Rechtsvorschriften entsprechen und verkehrsfähig sind. Lebensmittel
          müssen insbesondere den Vorschriften des LFGB, der Rückstands-Höchstmengenverordnung sowie
          den übrigen lebensmittelrechtlichen und wettbewerbsrechtlichen Vorschriften zum jeweils
          aktuellen Stand entsprechen. Die ausgelieferten Produkte müssen die rechtlich
          erforderlichen Kennzeichnungen tragen und nach den einschlägigen Herstellungsvorschriften
          (z.B. EU-Hygieneverordnungen) produziert worden sein
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          (2) Der Regionalpartner stellt die Rückverfolgbarkeit der verkauften und gelieferten
          Artikel gemäß den gesetzlichen Bestimmungen sicher. Es hat für ein funktionierendes
          Krisenmanagement zu sorgen, damit die gesetzlichen Anforderungen an Warenrücknahmen und
          Warenrückrufe im Krisenfall erfüllt werden. Die belieferten Kaufleute sind unverzüglich
          über Mängel und Produktrisiken zu informieren.
        </Typography>
        <Typography
          variant="h2"
          color="textPrimary"
          align="left"
          paragraph
          className={classes.typography}
        >
          7. Inhalte und Logos
        </Typography>

        <Typography variant="body2" align="left" paragraph>
          Der Regionalpartner behält alle Rechte an den Inhalten, die er auf seiner verlinkten
          Webseite präsentiert bzw. die er auf foodstarter hochlädt. Wenn er Inhalte einschließlich
          Logos präsentiert bzw. hochlädt, erteilt er dem Anbieter und den sonstigen Unternehmen des
          EDEKA-Verbunds die Lizenz, diese Inhalte nach Bedarf zu nutzen oder zu kopieren, um über
          die Produkte des Regionalpartners zu informieren. Hierzu gehört auch das Recht, auf den
          Regionalpartner und dessen Produkte auf eigenen und fremden Seiten des EDEKA-Verbunds
          einschließlich von Auftritten in Sozialen Medien (Facebook etc.) hinzuweisen und diese
          einschließlich der Verwendung des Logos zu bewerben. Der Regionalpartner gewährt dem
          Anbieter zudem das Recht, die Inhalte bei formellen Fehlern und technisch so zu
          bearbeiten, dass diese auch auf mobilen Endgeräten oder Softwareapplikationen dargestellt
          werden können.
        </Typography>

        <Typography
          variant="h2"
          color="textPrimary"
          align="left"
          paragraph
          className={classes.typography}
        >
          8. Haftung
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          (1) Der Anbieter ist nicht für die von dem Regionalpartner bereitgestellten oder
          weitergeleiteten Inhalte einschließlich von Texten, Bildern, Videos, Grafiken etc.,
          verantwortlich.
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          (2) Eine Gewähr oder Haftung für die Identität des Regionalpartners sowie für die
          Richtigkeit, Genauigkeit, Verwendungsfähigkeit und Vollständigkeit der auf foodstarter
          befindlichen Informationen wird nicht übernommen. Demgemäß haftet der Anbieter auch nicht
          für Fehler, Unrichtigkeiten oder Unvollständigkeiten im Zusammenhang mit Inhalten von
          foodstarter und auch nicht für Verluste oder Schäden, die durch die Benutzung eines
          Inhalts von foodstarter entstehen.
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          (3) foodstarter enthält Links zu externen Webseiten Dritter, auf deren Inhalte der
          Anbieter keinen Einfluss hat. Deshalb übernimmt er für diese fremden Inhalte auch keine
          Gewähr oder Haftung. Für die Inhalte der verlinkten Seiten ist stets der jeweilige
          Anbieter oder Betreiber der Seiten verantwortlich. Eine permanente inhaltliche Kontrolle
          der verlinkten Seiten ist ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
          zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden derartige Links umgehend
          entfernt.
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          (4) foodstarter ist grundsätzlich 24 Stunden an sieben Tagen der Woche abrufbar.
          Ausfallzeiten, die wartungsbedingt und/oder aufgrund von technischen Updates
          auftreten, sind hiervon genauso ausgenommen, wie Zeiten, in welchen foodstarter aufgrund
          höherer Gewalt (z.B. Hacker-Angriffe, Feuerschäden, Ausfall öffentlicher
          Telekommunikationsnetze), nicht oder nur eingeschränkt verfügbar ist. Die vorgenannten
          Fälle stellen keinen Mangel der Leistungen dar, für sie übernimmt der Anbieter auch keine
          Haftung. Voraussetzung für eine vollumfängliche Nutzung von foodstarter ist die Verwendung
          der neuesten (Browser-) Technologien und App-Updates inklusive einer etwaigen Aktivierung
          von beispielsweise Javascript, Cookies, Pop-ups etc. Durch die Nutzung veralteter
          Technologien und Apps kann es zu Einschränkungen kommen.
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          (5) Der Anbieter haftet für Vorsatz und grobe Fahrlässigkeit uneingeschränkt, für leichte
          Fahrlässigkeit jedoch nur bei der Verletzung von Pflichten, die wesentlich für die
          Erreichung des Vertragszwecks sind (Kardinalpflichten). Die Haftung bei Verletzung einer
          Kardinalpflicht ist auf den vertragstypischen Schaden begrenzt, mit dessen Entstehen der
          Anbieter bei Vertragsabschluss aufgrund der zu diesem Zeitpunkt bekannten Umstände rechnen
          musste.
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          (6) Die vorstehenden Haftungsbeschränkungen gelten sinngemäß auch zugunsten der
          Erfüllungsgehilfen des Anbieters. Sie gelten jedoch nicht bei Verletzung von Leib, Leben
          oder Gesundheit. Die Haftung nach dem Produkthaftungsgesetz bleibt unberührt.
        </Typography>

        <Typography
          variant="h2"
          color="textPrimary"
          align="left"
          paragraph
          className={classes.typography}
        >
          9. Freistellung
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Der Regionalpartner stellt den Anbieter und die Unternehmen des EDEKA-Verbunds von
          sämtlichen Ansprüchen frei, die andere gegenüber dem Anbieter oder den Unternehmen des
          EDEKA-Verbunds wegen der von dem Regionalpartner bei foodstarter oder auf seiner Webseite
          bereitgestellten Inhalten und angebotenen Artikeln geltend machen. Das gilt auch für
          etwaige Buß- und Strafgelder sowie Kosten der notwendigen Rechtsverteidigung in
          gesetzlicher Höhe. Die Freistellungsverpflichtung gilt nicht, wenn die Rechtsverletzung
          durch den Regionalpartner nicht zu vertreten ist. Der Regionalpartner ist verpflichtet,
          foodstarter und die betroffenen Unternehmen des EDEKA-Verbunds im Falle einer
          Inanspruchnahme durch Dritte unverzüglich, wahrheitsgemäß und vollständig alle
          Informationen und Unterlagen zur Verfügung zu stellen, die für die Prüfung der Ansprüche
          und eine Verteidigung erforderlich sind.
        </Typography>
        <Typography
          variant="h2"
          color="textPrimary"
          align="left"
          paragraph
          className={classes.typography}
        >
          10. Rechte an foodstarter
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          (1) foodstarter beinhaltet Daten und Informationen aller Art, die marken- und/oder
          urheberrechtlich zugunsten von Unternehmen des EDEKA-Verbunds oder im Einzelfall auch
          zugunsten Dritter geschützt sind. Es ist daher nicht gestattet, foodstarter im Ganzen oder
          einzelne Teile davon herunterzuladen, zu vervielfältigen und zu verbreiten, soweit es
          nicht ausdrücklich gestattet wurde. Gestattet sind vor allem die technisch bedingte
          Vervielfältigung zwecks Nutzung der Plattform.
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          (2) Das foodstarter Logo sowie der Claim „foodstarter“ sind eingetragene Marken des
          Anbieters. Jede ungenehmigte oder missbräuchliche Verwendung dieser Marken ist
          ausdrücklich untersagt und stellt eine Verletzung des Markenrechts, des Urheberrechts,
          anderer gewerblicher Schutzrechte oder des Wettbewerbsrechts dar.
        </Typography>
        <Typography
          variant="h2"
          color="textPrimary"
          align="left"
          paragraph
          className={classes.typography}
        >
          11. Schlussbestimmungen
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          (1) Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts.
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          (2) Erfüllungsort und Gerichtsstand ist der Geschäftssitz von EDEKA DIGITAL GmbH,
          New-York-Ring 6, 22297 Hamburg.
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          (3) Sollte eine der Bestimmungen dieser Nutzungsbedingungen unwirksam sein oder werden,
          wird die Gültigkeit der übrigen Bestimmungen davon nicht berührt.
        </Typography>

        <Typography variant="body2" align="left" paragraph>
          Stand: März 2021
        </Typography>
      </Container>
    </Page>
  );
};
export default TermsView;
