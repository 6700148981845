import { Box, Container, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import JWTResetPassword from '../../auth/ResetPasswordView/JWTResetPassword';
import Page from '../../components/Page';
import typography from '../../theme/typography';
import FormattedMessageMarkdown from '../../utils/FormattedMessageMarkdown';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
  container: {
    marginTop: '5rem',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  body: {
    whiteSpace: 'pre-wrap',
  },
  form: {
    backgroundColor: '#fff',
    padding: '2rem 2rem',
    borderRadius: '0.6rem',
  },
  formHeader: {
    fontSize: typography.h3.fontSize,
  },
  marginTop: {
    marginTop: '2rem',
  },
}));

const ResetPasswordView: FC = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  // read location search params to get `email_sent` for showing the request success
  const locationSearch = new URLSearchParams(useLocation().search);
  const emailSent = locationSearch.get('email_sent');

  const pageTitle = formatMessage({
    id: 'reset-password-page.title',
    defaultMessage: 'Reset password',
  });

  return (
    <Page className={classes.root} title={pageTitle}>
      <Container maxWidth="md" className={classes.container}>
        <Grid container spacing={3}>
          {!!emailSent ? (
            <>
              <Grid item xs={12} sm={12} className={classes.title}>
                <Typography variant="h1" color="textPrimary">
                  {formatMessage({
                    id: 'login-page.email-sent.header',
                    defaultMessage: 'Email sent',
                  })}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} className={classes.body}>
                <Typography variant="body1" color="textPrimary">
                  {formatMessage({
                    id: 'login-page.email-sent.message',
                    defaultMessage: 'Email sent with further instructions.',
                  })}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} className={classes.body}>
                <Typography variant="body1" color="textPrimary">
                  <FormattedMessageMarkdown
                    id="login-page.email-sent.login"
                    defaultMessage="Login"
                  />
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={6} className={classes.title}>
                <Typography variant="h1" color="textPrimary">
                  {formatMessage({
                    id: 'login-page.header',
                    defaultMessage: 'For more regional products',
                  })}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box justifyContent="center" className={classes.form}>
                  <Typography variant="h2" className={classes.formHeader} paragraph>
                    {formatMessage({
                      id: 'reset-password-page.form.header',
                      defaultMessage: 'Forgot password',
                    })}
                  </Typography>
                  <Typography variant="body1" color="textSecondary" paragraph>
                    {formatMessage({
                      id: 'reset-password-page.form.message',
                      defaultMessage: 'Forgot password',
                    })}
                  </Typography>
                  <JWTResetPassword />
                  <Divider className={classes.marginTop} />
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                    paragraph
                    className={classes.marginTop}
                  >
                    <FormattedMessageMarkdown
                      id="reset-password-page.form.help"
                      defaultMessage="Help"
                    />
                  </Typography>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </Page>
  );
};

export default ResetPasswordView;
