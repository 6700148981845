import { TypedDocumentNode, useQuery } from '@apollo/client';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import { ChangeEvent, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {
  GraphQLGridBaseQueryType,
  GraphQLGridBaseResultNodeT,
  GraphQLGridColumn,
  GraphQLGridQueryBaseOrder,
  GraphQlGridQueryBaseVariables,
} from '.';
import { QueryOrderEnum } from '../../../__generated__/globalTypes';
import SortableColumnHeader from './SortableColumnHeader';
interface GraphQLGridProps<QueryResultOrderEnum, QueryResultNodeT, QueryFilterType> {
  query: TypedDocumentNode<
    GraphQLGridBaseQueryType<QueryResultNodeT>,
    GraphQlGridQueryBaseVariables<GraphQLGridQueryBaseOrder<QueryResultOrderEnum>, QueryFilterType>
  >;
  initialVariables: GraphQlGridQueryBaseVariables<
    GraphQLGridQueryBaseOrder<QueryResultOrderEnum>,
    QueryFilterType
  >;
  filter?: QueryFilterType;
  loadingMessage: string;
  errorMessage: string;
  columns: GraphQLGridColumn<QueryResultOrderEnum, QueryResultNodeT>[];
  cypressRowName?: string;
}

function GraphQLGrid<
  QueryResultOrderEnum,
  QueryResultNodeT extends GraphQLGridBaseResultNodeT,
  QueryFilterType extends Record<string, any>,
>(
  props: PropsWithChildren<
    GraphQLGridProps<QueryResultOrderEnum, QueryResultNodeT, QueryFilterType>
  >,
) {
  const { query, initialVariables, loadingMessage, errorMessage, columns, cypressRowName, filter } =
    props;
  const [page, setPage] = useState<number>(0);
  // Grid logic
  const { loading, error, data, refetch, variables } = useQuery<
    GraphQLGridBaseQueryType<QueryResultNodeT>,
    GraphQlGridQueryBaseVariables<GraphQLGridQueryBaseOrder<QueryResultOrderEnum>, QueryFilterType>
  >(query, {
    variables: initialVariables,
  });

  const getCurrentPageLimit = useCallback(
    () => variables?.first || variables?.last || 10,
    [variables?.first, variables?.last],
  );

  const mergeVariables = useCallback(
    (
      old:
        | GraphQlGridQueryBaseVariables<
            GraphQLGridQueryBaseOrder<QueryResultOrderEnum>,
            QueryFilterType
          >
        | undefined,
      newVars: GraphQlGridQueryBaseVariables<
        GraphQLGridQueryBaseOrder<QueryResultOrderEnum>,
        QueryFilterType
      >,
    ) => {
      if (old) {
        return { ...old, ...newVars };
      }

      return newVars;
    },
    [],
  );

  // React to outside filtering
  useEffect(() => {
    if (!_.isEqual(filter, variables?.filter)) {
      setPage(0);
      refetch(
        mergeVariables(variables, {
          filter: filter,
          before: null,
          last: null,
          after: null,
          first: getCurrentPageLimit(),
        }),
      );
    }
  }, [filter, refetch, mergeVariables, variables, getCurrentPageLimit]);

  // Move between pages
  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);

    if (newPage > page) {
      refetch(
        mergeVariables(variables, {
          before: undefined,
          last: undefined,
          after: data?.rows.pageInfo.endCursor,
          first: getCurrentPageLimit(),
        }),
      );
    } else if (newPage < page) {
      refetch(
        mergeVariables(variables, {
          after: undefined,
          first: undefined,
          before: data?.rows.pageInfo.startCursor,
          last: getCurrentPageLimit(),
        }),
      );
    }
  };

  // Change the number of items per page setting
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newLimit = parseInt(event.target.value);
    if (variables?.first) {
      refetch(mergeVariables(variables, { first: newLimit }));
    } else {
      refetch(mergeVariables(variables, { last: newLimit }));
    }
  };

  // Sorting
  const handleSortingToggle = (field: QueryResultOrderEnum) => {
    let order = QueryOrderEnum.asc;
    if (
      variables?.order &&
      variables.order[0].attribute === field &&
      variables.order[0].order === QueryOrderEnum.asc
    ) {
      order = QueryOrderEnum.desc;
    }

    setPage(0);
    refetch(
      mergeVariables(variables, {
        order: [{ attribute: field, order: order }],
        before: null,
        last: null,
        after: null,
        first: getCurrentPageLimit(),
      }),
    );
  };
  //====================================================================================

  let content;

  if ((loading && !data) || !data) {
    content = (
      <Alert variant="filled" severity="info">
        {loadingMessage}
      </Alert>
    );
  } else if (error) {
    content = (
      <Alert variant="filled" severity="error">
        {errorMessage}
      </Alert>
    );
  } else {
    content = (
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column, idx) => {
              if (column.sortable) {
                return (
                  <SortableColumnHeader
                    name={column.name}
                    currentSort={variables?.order?.[0]}
                    onSortToggle={handleSortingToggle}
                    sortAttr={column.sortAttr}
                    key={idx}
                  >
                    {column.headerCell}
                  </SortableColumnHeader>
                );
              } else {
                return <TableCell key={idx}>{column.headerCell ?? column.name}</TableCell>;
              }
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.rows.edges?.map(
            (row, idx) =>
              row && (
                <TableRow
                  hover
                  key={row?.node?.id}
                  data-id={row?.node?.id}
                  data-cy={cypressRowName}
                >
                  {columns.map((column, idx) => (
                    <TableCell key={idx}>{row?.node ? column?.cell(row?.node) : <></>}</TableCell>
                  ))}
                </TableRow>
              ),
          )}
        </TableBody>
      </Table>
    );
  }

  return (
    <Box>
      <PerfectScrollbar>
        <Box minWidth={700}>{content}</Box>
      </PerfectScrollbar>
      {data && (
        <TablePagination
          component="div"
          count={data.rows.totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={getCurrentPageLimit()}
          rowsPerPageOptions={[5, 10, 25]}
        />
      )}
    </Box>
  );
}

export default GraphQLGrid;
