import { Box, Container, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import JWTNewPassword from '../../auth/NewPasswordView/JWTNewPassword';
import Page from '../../components/Page';
import typography from '../../theme/typography';
import FormattedMessageMarkdown from '../../utils/FormattedMessageMarkdown';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
  container: {
    marginTop: '5rem',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  body: {
    whiteSpace: 'pre-wrap',
  },
  form: {
    backgroundColor: '#fff',
    padding: '2rem 2rem',
    borderRadius: '0.6rem',
  },
  formHeader: {
    fontSize: typography.h3.fontSize,
  },
  marginTop: {
    marginTop: '2rem',
  },
}));

const NewPasswordView: FC = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const pageTitle = formatMessage({
    id: 'new-password-page.title',
    defaultMessage: 'New password',
  });

  return (
    <Page className={classes.root} title={pageTitle}>
      <Container maxWidth="md" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} className={classes.title}>
            <Typography variant="h1" color="textPrimary">
              {formatMessage({
                id: 'login-page.header',
                defaultMessage: 'For more regional products',
              })}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box justifyContent="center" className={classes.form}>
              <Typography variant="h2" className={classes.formHeader} paragraph>
                {formatMessage({
                  id: 'new-password-page.form.header',
                  defaultMessage: 'New password',
                })}
              </Typography>
              <JWTNewPassword />
              <Divider className={classes.marginTop} />
              <Typography
                variant="body2"
                color="textSecondary"
                align="center"
                paragraph
                className={classes.marginTop}
              >
                <FormattedMessageMarkdown id="new-password-page.form.help" defaultMessage="Help" />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default NewPasswordView;
