import { REGISTER_SUPPLIER_ACCOUNT_WITH_EMAIL_AND_PASSWORD } from '../../auth/mutations/registerSupplierAccountWithEmailAndPassword';

export const registerSupplierAccountWithEmailAndBadPasswordMock = {
  request: {
    query: REGISTER_SUPPLIER_ACCOUNT_WITH_EMAIL_AND_PASSWORD,
    variables: {
      email: 'foo@bar.baz',
      password: '123',
      passwordConfirmation: '123',
      acceptedTerms: true,
    },
  },
  result: {
    data: {
      registerSupplierAccountWithEmailAndPassword: {
        denialReasons: [
          'PASSWORD_IS_TOO_SHORT_MINIMUM_IS_7_CHARACTERS',
          'PASSWORD_DOES_NOT_HAVE_ENOUGH_UNIQUE_CHARACTERS',
          'PASSWORD_IS_TOO_COMMON',
        ],
        account: null,
      },
    },
  },
};

export const registerSupplierAccountWithEmailAndPasswordMock = {
  request: {
    query: REGISTER_SUPPLIER_ACCOUNT_WITH_EMAIL_AND_PASSWORD,
    variables: {
      email: 'foo@bar.baz',
      password: 'Enim1234567velit-!.',
      passwordConfirmation: 'Enim1234567velit-!.',
      acceptedTerms: true,
    },
  },
  result: {
    data: {
      registerSupplierAccountWithEmailAndPassword: {
        denialReasons: null,
        account: {
          id: 2,
          email: 'foo@bar.baz',
        },
      },
    },
  },
};
export const registerSupplierAccountWithEmailAndWithTooCommonPasswordMock = {
  request: {
    query: REGISTER_SUPPLIER_ACCOUNT_WITH_EMAIL_AND_PASSWORD,
    variables: {
      email: 'foo@bar.baz',
      password: '1234567',
      passwordConfirmation: '1234567',
      acceptedTerms: true,
    },
  },
  result: {
    data: {
      registerSupplierAccountWithEmailAndPassword: {
        denialReasons: ['PASSWORD_IS_TOO_COMMON'],
        account: null,
      },
    },
  },
};

export const registerSupplierAccountWithEmailAndWrongPasswordConfirmationMock = {
  request: {
    query: REGISTER_SUPPLIER_ACCOUNT_WITH_EMAIL_AND_PASSWORD,
    variables: {
      email: 'foo@bar.baz',
      password: '123456789',
      passwordConfirmation: '1234567',
      acceptedTerms: true,
    },
  },
  result: {
    data: {
      registerSupplierAccountWithEmailAndPassword: {
        denialReasons: ['PASSWORD_CONFIRMATION_DOESN_T_MATCH_PASSWORD'],
        account: null,
      },
    },
  },
};
