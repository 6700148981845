import { gql } from '@apollo/client';

export const UPDATE_SUPPLIER_COMPANY_BY_ID = gql`
  mutation UpdateSupplierCompanyById(
    $id: ID!
    $supplierCompanyAttributes: SupplierCompanyAttributes!
  ) {
    updateSupplierCompanyById(
      input: { id: $id, supplierCompanyAttributes: $supplierCompanyAttributes }
    ) {
      denialReasons
    }
  }
`;
