import { Breadcrumbs, Grid, Link, makeStyles, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import clsx from 'clsx';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AdminBreadcrumb } from '.';
import { Theme } from '../../../theme';
import ExportSection from './ExportSection';

interface AdminHeaderProps {
  className?: string;
  heading: string;
  breadcrumbs: AdminBreadcrumb[];
  exportSection: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1),
    },
  },
  breadcrumbs: {
    marginTop: '10px',
  },
}));

const AdminHeader: FC<AdminHeaderProps> = ({
  heading,
  breadcrumbs,
  className,
  exportSection,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justify="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Typography variant="h3" color="textPrimary">
          {heading}
        </Typography>
        <Breadcrumbs
          className={classes.breadcrumbs}
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs.map((b) => {
            if (b.linkTo !== null) {
              return (
                <Link variant="body1" color="inherit" to={b.linkTo} component={RouterLink}>
                  {b.label}
                </Link>
              );
            } else {
              return (
                <Typography variant="body1" color="textPrimary">
                  {b.label}
                </Typography>
              );
            }
          })}
        </Breadcrumbs>
      </Grid>
      {exportSection && <ExportSection />}
    </Grid>
  );
};

export default AdminHeader;
