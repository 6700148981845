import type { FC } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import FormattedMessageMarkdown from '../../utils/FormattedMessageMarkdown';
import { useIntl } from 'react-intl';
import { splitReduceAndJoin } from '../../utils/errorMessageHelper';
import { useHistory } from 'react-router';

interface JWTRegisterProps {
  className?: string;
}

const useStyles = makeStyles(() => ({
  root: {},
  button: {
    'margin-top': '1rem',
  },
  errors: {
    'white-space': 'pre-wrap',
  },
}));

const JWTRegister: FC<JWTRegisterProps> = ({ className, ...rest }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { register } = useAuth();
  const isMountedRef = useIsMountedRef();
  const history = useHistory();

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        confirmPassword: '',
        policy: false,
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(
            formatMessage({
              id: 'registration-page.error.valid-email',
              defaultMessage: '"Must be a valid email',
            }),
          )
          .max(255)
          .required(
            formatMessage({
              id: 'registration-page.error.email',
              defaultMessage: 'Email is required',
            }),
          ),
        password: Yup.string()
          .min(
            7,
            formatMessage({
              id: 'registration-page.error.min-password',
              defaultMessage: 'Must be at least 7 characters',
            }),
          )
          .max(128)
          .required(
            formatMessage({
              id: 'registration-page.error.password',
              defaultMessage: '"Password is required',
            }),
          ),
        confirmPassword: Yup.string().oneOf(
          [Yup.ref('password')],
          formatMessage({
            id: 'registration-page.error.passwords-match',
            defaultMessage: '"Passwords must match',
          }),
        ),
        policy: Yup.boolean().oneOf(
          [true],
          formatMessage({
            id: 'registration-page.error.accept-terms',
            defaultMessage: '"Please accept terms and conditions',
          }),
        ),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await register(values.email, values.password, values.confirmPassword, values.policy);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
            history.push('/confirmation');
          }
        } catch (err) {
          console.error(err);

          const translatedErrorMessage = splitReduceAndJoin(err.message, (message: string) =>
            formatMessage({
              id: `registration-page.error.${message}`,
              defaultMessage: message,
            }),
          );

          setStatus({ success: false });
          setErrors({ submit: translatedErrorMessage });
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form
          noValidate
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label={formatMessage({
              id: 'registration-page.form.email',
              defaultMessage: 'Email',
            })}
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />

          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label={formatMessage({
              id: 'registration-page.form.password',
              defaultMessage: 'Password',
            })}
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            label={formatMessage({
              id: 'registration-page.form.confirm-password',
              defaultMessage: 'Confirm password',
            })}
            margin="normal"
            name="confirmPassword"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.confirmPassword}
            variant="outlined"
          />
          <Box alignItems="center" display="flex" mt={2} ml={-1}>
            <Checkbox checked={values.policy} name="policy" onChange={handleChange} />
            <Typography variant="body2" color="textSecondary" component="div">
              <FormattedMessageMarkdown
                id="registration-page.form.terms"
                defaultMessage="Terms"
                linkTarget="_blank"
              />
            </Typography>
          </Box>
          {Boolean(touched.policy && errors.policy) && (
            <FormHelperText error>{errors.policy}</FormHelperText>
          )}
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error className={classes.errors}>
                {errors.submit}
              </FormHelperText>
            </Box>
          )}
          <Box mt={2} ml={4}>
            <Typography variant="body2" color="textSecondary" component="div">
              <FormattedMessageMarkdown
                id="registration-page.form.login-link"
                defaultMessage="Login"
              />
            </Typography>
          </Box>

          <Button
            color="secondary"
            disabled={isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            className={classes.button}
          >
            {formatMessage({
              id: 'registration-page.form.register-button',
              defaultMessage: 'Register',
            })}
          </Button>
        </form>
      )}
    </Formik>
  );
};

JWTRegister.propTypes = {
  className: PropTypes.string,
};

export default JWTRegister;
