import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { FC } from 'react';
import { useIntl } from 'react-intl';

interface DeleteSupplierProps {
  open: boolean;
  companyName: string | null;
  handleDelete: () => void;
  handleClose: () => void;
}

const useStyles = makeStyles({
  companyName: {
    color: 'red',
  },
});

const DeleteCompanyAccountDialog: FC<DeleteSupplierProps> = ({
  handleDelete,
  handleClose,
  open,
  companyName,
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" disableTypography={true}>
        <Typography variant="h4">
          {formatMessage({
            id: 'admin.company.delete-dialog.header-1',
            defaultMessage: 'Are you sure you want to delete company ',
          })}
          <span className={classes.companyName}>{companyName}</span>
          {formatMessage({
            id: 'admin.company.delete-dialog.header-2',
            defaultMessage: '?',
          })}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {formatMessage({
            id: 'admin.company.delete-dialog.text',
            defaultMessage:
              'If the company presence is deleted, it will no longer be visible to merchants. You cannot undo the deletion process.',
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDelete} color="primary" variant="outlined">
          {formatMessage({
            id: 'admin.company.delete-dialog.delete',
            defaultMessage: 'Delete',
          })}
        </Button>
        <Button onClick={handleClose} color="primary" variant="contained">
          {formatMessage({
            id: 'admin.company.delete-dialog.cancel',
            defaultMessage: 'Cancel',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCompanyAccountDialog;
