import { gql } from '@apollo/client';
import { SUPPLIER_COMPANY_FRAGMENT } from '../fragments';

export const UPDATE_SUPPLIER_COMPANY_AS_ADMIN = gql`
  ${SUPPLIER_COMPANY_FRAGMENT}
  mutation UpdateSupplierCompanyAsAdmin(
    $id: ID!
    $name: String
    $advertisingArea: String
    $edekaRegion: String
    $listing: String
    $description: String
    $website: String
    $logo: String
    $postalCode: String
    $city: String
    $district: String
    $deliveryArea: String
    $commodityGroups: [String!]
    $products: String
    $productPortfolio: ProductPortfolioAttributes
    $minimumOrderValue: String
    $images: [String!]
    $publishedState: String
    $publishedDate: ISO8601DateTime
  ) {
    updateSupplierCompanyByIdAsAdmin(
      input: {
        id: $id
        supplierCompanyAttributes: {
          name: $name
          advertisingArea: $advertisingArea
          edekaRegion: $edekaRegion
          listing: $listing
          description: $description
          website: $website
          logo: $logo
          postalCode: $postalCode
          city: $city
          district: $district
          deliveryArea: $deliveryArea
          commodityGroups: $commodityGroups
          products: $products
          productPortfolio: $productPortfolio
          minimumOrderValue: $minimumOrderValue
          images: $images
          publishedState: $publishedState
          publishedDate: $publishedDate
        }
      }
    ) {
      supplierCompany {
        ...SupplierCompanyCoreData
      }
      denialReasons
    }
  }
`;
