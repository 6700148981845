import { useState } from 'react';

const DetailsText = ({ children }) => {
  const [isShown, setIsShown] = useState(false);

  return (
    <>
      <br />
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setIsShown(!isShown);
        }}
      >
        {!isShown && 'Mehr Informationen'}
        {isShown && 'Weniger Informationen'}
      </a>
      {isShown && children}
    </>
  );
};

export default DetailsText;
