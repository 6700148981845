import { createContext } from 'react';
import { THEMES } from '../../constants';
import { Settings, SettingsContextValue } from './settingsContext.types';

export const defaultSettings: Settings = {
  responsiveFontSizes: true,
  theme: THEMES.LIGHT,
};

const SettingsContext = createContext<SettingsContextValue>({
  settings: defaultSettings,
  saveSettings: () => {},
});

export default SettingsContext;

export const SettingsConsumer = SettingsContext.Consumer;
