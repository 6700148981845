import { createContext } from 'react';

import { MerchantContextValue } from './merchantContext.types';

const MerchantContext = createContext<MerchantContextValue>({
  validatingCredentials: false,
  isAuthenticated: false,
  denialReasons: [],
  queryAmount: 8,
  setQueryAmount: () => {},
  lastSelectedCompany: '',
  setLastSelectedCompany: () => {},
});

export default MerchantContext;
