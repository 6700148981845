import { FC, useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import { PERSONA_LOGIN_MAP, UserPersona } from '../../types/user';
import { Redirect } from 'react-router-dom';

const LogoutView: FC = () => {
  const { logout, persona } = useAuth();
  const loginRoute = PERSONA_LOGIN_MAP[persona ?? UserPersona.SUPPLIER];

  useEffect(() => {
    logout();
  }, []);

  return <Redirect to={loginRoute} />;
};

export default LogoutView;
