import { FC, useState } from 'react';
import { Box, Button, CircularProgress, makeStyles, Modal, Typography } from '@material-ui/core';
import download from 'downloadjs';
import AuthStorageAccessor from '../../../utils/authStorage';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  root: {
    padding: '36px 12px 0 0',
  },
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    boxShadow: '24px',
    p: '4px',
    padding: '2em',
  },
  modalHeader: {
    fontSize: '20px',
    marginBottom: '1.5em',
  },
  modalButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    marginTop: '2em',
    gap: '10px',
  },
  btnUppercase: {
    textTransform: 'uppercase',
  },
  circularProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-12px',
    marginLeft: '-12px',
  },
}));

const ExportSection: FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const token = AuthStorageAccessor.readToken();
  const headers = new Headers({
    Authorization: 'Bearer ' + token,
  });

  const downloadFile = () => {
    let req = new XMLHttpRequest();
    const filename = `foodstarter_regional_lieferanten_${moment().format('YYYYMMDD')}`;

    req.open('GET', process.env.REACT_APP_DEMETER + '/export_file', true);
    req.responseType = 'blob';
    req.setRequestHeader('Authorization', 'Bearer ' + token);
    req.onload = function () {
      download(this.response, filename, 'text/csv');
    };
    req.send();
  };

  const fetchFile = () => {
    fetch(process.env.REACT_APP_DEMETER + '/export_status', { headers: headers }).then(
      (response) => {
        response.text().then((text) => {
          // File was not yet created, we need to poll again
          if (text === 'in progress') {
            setTimeout(fetchFile, 500);
          } else {
            setLoading(false);
            setOpen(false);
            downloadFile();
          }
        });
      },
    );
  };

  const handleExport = () => {
    setLoading(true);

    fetch(process.env.REACT_APP_DEMETER + '/export', { method: 'POST', headers: headers })
      .then(fetchFile)
      .catch(() => {
        alert('Error while exporting');
      });
  };

  return (
    <div className={classes.root}>
      <Button
        className={classes.btnUppercase}
        onClick={handleOpen}
        variant="contained"
        color="primary"
      >
        Lieferantenexport
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box className={classes.box}>
          {loading && <CircularProgress size={40} className={classes.circularProgress} />}
          <div style={{ filter: loading ? 'blur(5px)' : '' }}>
            <Typography className={classes.modalHeader} variant="h4" component="h2">
              Möchtest Du einen Export aller Lieferanten herunterladen?
            </Typography>
            <Typography>
              Mit dem Export erhältst Du eine Datei in CSV-Format.
              <br />
              Die Datei enthält alle abgefragten Lieferantendaten sowie die dazugehörigen
              Unternehmensauftritte.
              <br />
              Du kannst die Datei nach dem Download nach Belieben anpassen, in dem Du nicht
              benötigte Spalten löschst.
            </Typography>
            <div className={classes.modalButtonWrapper}>
              <Button
                className={classes.btnUppercase}
                onClick={handleClose}
                variant="outlined"
                color="primary"
              >
                Abbrechen
              </Button>
              <Button
                className={classes.btnUppercase}
                onClick={handleExport}
                variant="contained"
                color="primary"
              >
                {' '}
                CSV herunterladen
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ExportSection;
