import { useLocation } from 'react-router-dom';

/*
 * A custom hook that builds on useLocation to parse
 * the query string for you.
 *
 * Given URL params like `?foo=bar&meaningOfLife=42`
 * it will return a `URLSearchParams` object that you can access with
 * ```
 * let query = useQuery();
 * const foo = query.get("foo") // => "bar"
 * const meaningOfLife = query.get("meaningOfLife") // => 42
 * ```
 *
 * Taken from ReactRouterDOM => https://reactrouter.com/web/example/query-parameters
 */
const useUrlParams = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

export default useUrlParams;
