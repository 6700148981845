import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import Cookies from 'js-cookie';
import styles from './CookiesBanner.module.scss';
import CookiesSettings from './CookiesSettings';
import useAuth from '../../hooks/useAuth';
import { useAuthToken } from '../../hooks/useAuthToken';

/* eslint "jsx-a11y/anchor-is-valid": 0 */
const defaultAcceptedCookies = {
  essentialCookies: true,
  marketingCookies: false,
  analyticsCookies: false,
};

export const shouldShowBanner = ({ essentialCookies, marketingCookies, analyticsCookies }) => {
  return !essentialCookies && !marketingCookies && !analyticsCookies;
};

/*
Displays a Cookies Banner that allows the user to toggle their cookies settings.
If the user already decided their settings in an earlier session and there is a saved cookie
in the Browser, we use that to inform any forther questions regarding using technologies etc.

If there is a logged-in user we query the backend for their cookies preferences. If there are non,
we get the default response of *NONE ACCEPTED*. This means we display the banner to let them make
their decision. We display the banner until a decision was made.
 */
const CookiesBanner = () => {
  const [acceptedCookies, setAcceptedCookies] = useState(defaultAcceptedCookies);
  const [shouldShowSettings, setShouldShowSettings] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [authToken] = useAuthToken();
  const { user, persona } = useAuth();

  useEffect(() => {
    const fetchSelectedCookies = async (headers) => {
      const response = await fetch(`${process.env.REACT_APP_DEMETER}/accepted-cookies`, {
        headers,
      });
      const acceptedCookiesResponse = await response.json();
      setAcceptedCookies(acceptedCookiesResponse);
      Cookies.set('acceptedCookies', acceptedCookiesResponse);
      setIsVisible(shouldShowBanner(acceptedCookiesResponse));
    };

    if (persona === 'AdminAccount') {
      // Don't display banner for Admins.
      setIsVisible(false);
      return;
    }

    if (authToken) {
      fetchSelectedCookies({ Authorization: `Bearer ${authToken}` });
    } else {
      const acceptedCookiesFromBrowser = Cookies.get('acceptedCookies');
      if (!acceptedCookiesFromBrowser) {
        setIsVisible(true);
        return;
      }
      const parsedCookies = JSON.parse(acceptedCookiesFromBrowser);
      setAcceptedCookies(parsedCookies);
      setIsVisible(shouldShowBanner(parsedCookies));
    }
  }, [authToken, user]);

  const saveCookieSelection = (selection) => {
    setShouldShowSettings(false);
    setIsVisible(false);
    const transmitSelection = async (selectedCookies) => {
      await fetch(`${process.env.REACT_APP_DEMETER}/accept-cookies`, {
        method: 'PUT',
        body: JSON.stringify({ selectedCookies }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
    };
    Cookies.set('acceptedCookies', selection);
    transmitSelection(selection);
  };

  const acceptAllCookies = () => {
    saveCookieSelection({
      ...defaultAcceptedCookies,
      marketingCookies: true,
      analyticsCookies: true,
    });
  };

  const rejectCookies = (e) => {
    e.preventDefault();
    saveCookieSelection(defaultAcceptedCookies);
  };

  const showSettings = (e) => {
    e.preventDefault();
    setShouldShowSettings(true);
    setIsVisible(false);
  };

  return (
    <>
      <div id={styles.cookiesConsent} className={clsx(!isVisible && styles.hidden)}>
        <span className={styles.consentText}>
          Auch foodstarter kommt nicht ohne Cookies aus, um die notwendige Seitenfunktionalität und
          -qualität zu gewährleisten. Mit einem Klick auf „Zustimmen“ akzeptierst Du die
          entsprechende Erfassung und Weitergabe Deiner Daten (auch an Drittanbieter). Die Daten
          werden für Analyse, Retargeting und zur Ausspielung von personalisierten Inhalten und
          Werbung auf foodstarter, sowie auf Drittanbieterseiten genutzt. Weitere Informationen
          findest Du in den <a href="/privacy">Datenschutzhinweisen</a>. Du kannst die Verwendung
          von Cookies&nbsp;
          <a href="#" className="reject" onClick={(e) => rejectCookies(e)}>
            ablehnen
          </a>{' '}
          oder jederzeit über Deine{' '}
          <a href="#" onClick={(e) => showSettings(e)}>
            Einstellungen anpassen
          </a>
          .
        </span>
        <div className={styles.consentAcceptor}>
          <Button onClick={showSettings}>
            <span className={styles.changeSettings}>Einstellungen ändern</span>
          </Button>
          <Button
            id="accept-btn"
            color="secondary"
            onClick={acceptAllCookies}
            variant="contained"
            className={styles.acceptCookies}
          >
            Zustimmen
          </Button>
        </div>
      </div>
      <CookiesSettings
        selectedCookies={acceptedCookies}
        isVisible={shouldShowSettings}
        saveCookieSelection={saveCookieSelection}
      />
    </>
  );
};

export default CookiesBanner;
