import { GET_SUPPLIER_ACCOUNTS_GRID } from '../../contexts/suppliers/queries/getSupplierAccountsGrid';

const all = [
  {
    id: '1',
    firstname: 'Delete',
    lastname: 'Me',
    email: 'delete.me@edeka.com',
    phoneNumber: '555-555-555-555',
    faxNumber: '555-555-555-555',
    registerDate: '2020-01-25',
    isOnline: true,
    company: [
      {
        id: '1',
        name: "Holger's Farm",
      },
      {
        id: '2',
        name: "Holger's Other Farm",
      },
    ],
  },
  {
    id: '3',
    firstname: 'John',
    lastname: 'Doe',
    email: 'john.doe@edeka.com',
    phoneNumber: '555-555-555-555',
    faxNumber: '555-555-555-555',
    registerDate: '2020-02-22',
    isOnline: false,
    company: [
      {
        id: '5',
        name: "Holger's Farm",
      },
      {
        id: '6',
        name: "Holger's Other Farm",
      },
    ],
  },
  {
    id: '4',
    firstname: 'James',
    lastname: 'Doe',
    email: 'james.doe@edeka.com',
    phoneNumber: '555-555-555-555',
    faxNumber: '555-555-555-555',
    registerDate: '2020-03-27',
    isOnline: true,
    company: [
      {
        id: '7',
        name: "Holger's Farm",
      },
      {
        id: '8',
        name: "Holger's Other Farm",
      },
    ],
  },
  {
    id: '2',
    firstname: 'Jane',
    lastname: 'Doe',
    email: 'jane.doe@edeka.com',
    phoneNumber: '555-555-555-555',
    faxNumber: '555-555-555-555',
    registerDate: '2020-04-12',
    isOnline: false,
    company: [
      {
        id: '3',
        name: "Holger's Farm",
      },
      {
        id: '4',
        name: "Holger's Other Farm",
      },
    ],
  },
];

export const getSupplierAccountsMock = {
  request: {
    query: GET_SUPPLIER_ACCOUNTS_GRID,
    variables: {
      orderBy: 'registerDate',
      order: 'ASC',
    },
  },
  result: {
    data: {
      rows: all,
    },
  },
};

export const getSupplierAccountsMockWithOneDeleted = {
  request: {
    query: GET_SUPPLIER_ACCOUNTS_GRID,
    variables: {
      orderBy: 'registerDate',
      order: 'ASC',
    },
  },
  result: {
    data: {
      rows: all.filter((x) => x.id !== '1'),
    },
  },
};

export const getSupplierAccountsMockOnline = {
  request: {
    query: GET_SUPPLIER_ACCOUNTS_GRID,
    variables: {
      orderBy: 'registerDate',
      order: 'ASC',
      filter: {
        isOnline: {
          eq: 'True',
        },
      },
    },
  },
  result: {
    data: {
      rows: all.filter((x) => x.isOnline),
    },
  },
};

export const getSupplierAccountsMockOffline = {
  request: {
    query: GET_SUPPLIER_ACCOUNTS_GRID,
    variables: {
      orderBy: 'registerDate',
      order: 'ASC',
      filter: {
        isOnline: {
          eq: 'False',
        },
      },
    },
  },
  result: {
    data: {
      rows: all.filter((x) => !x.isOnline),
    },
  },
};
