import {
  Box,
  Card,
  Divider,
  IconButton,
  InputAdornment,
  makeStyles,
  SvgIcon,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import type { ChangeEvent, FC } from 'react';
import { useEffect, useState } from 'react';
import { Delete as DeleteIcon, Edit as EditIcon, Search as SearchIcon } from 'react-feather';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { LiveBadge } from '../../../../components/common/badges/LiveBadge';
import { NotLiveBadge } from '../../../../components/common/badges/NotLiveBadge';
import { GraphQLGridColumn } from '../../../../components/common/tables';
import GraphQLGrid from '../../../../components/common/tables/GaraphQlGrid';
import { GET_SUPPLIER_ACCOUNTS_GRID } from '../../../../contexts/suppliers/queries/getSupplierAccountsGrid';
import { GetSupplierAccountsGrid_rows_edges_node } from '../../../../contexts/suppliers/queries/__generated__/GetSupplierAccountsGrid';
import { useDeleteSupplierAccountById } from '../../../../hooks/useDeleteSupplierAccountById';
import type { Theme } from '../../../../theme';
import { DateUtils } from '../../../../utils/date-utils';
import { SupplierUtils } from '../../../../utils/supplier-utils';
import {
  QueryOrderEnum,
  SupplierAccountForAdminsFilter,
  SupplierAccountOrderEnum,
} from '../../../../__generated__/globalTypes';
import DeleteSupplierAccountDialog from './DeleteSupplierAccountDialog';

interface ResultsProps {
  className?: string;
}

const tabs = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'live',
    label: 'Live',
  },
  {
    value: 'offline',
    label: 'Offline',
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  queryField: {
    width: 500,
  },
  nameBox: {
    maxWidth: 120,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  companyNameBox: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '400px',
  },
}));

const SuppliersGrid: FC<ResultsProps> = ({ className, ...rest }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [query, setQuery] = useState<string | undefined>();
  const [currentTab, setCurrentTab] = useState<string>('all');

  // Grid Logic
  const [filter, setFilter] = useState<SupplierAccountForAdminsFilter>();

  const {
    selectForDelete,
    cancelDeletion,
    confirmDeletion,
    supplierToDelete,
    // On success refetch - auto uses the latest variables/filters used
  } = useDeleteSupplierAccountById();

  const suppliersGridColumns: GraphQLGridColumn<
    SupplierAccountOrderEnum,
    GetSupplierAccountsGrid_rows_edges_node
  >[] = [
    {
      name: formatMessage({
        id: 'admin.supplier-account.grid.column-headers.email',
        defaultMessage: 'E-mail',
      }),
      cell: (row) => (
        <Box display="flex" alignItems="center">
          <div>
            <Typography variant="body2" color="textPrimary">
              {row.email}
            </Typography>
          </div>
        </Box>
      ),
      sortAttr: SupplierAccountOrderEnum.email,
      sortable: true,
    },

    {
      name: formatMessage({
        id: 'admin.supplier-account.grid.column-headers.first-name',
        defaultMessage: 'First Name',
      }),
      cell: (row) => (
        <Tooltip title={row.firstName ?? ''}>
          <Box className={classes.nameBox}>{row.firstName}</Box>
        </Tooltip>
      ),
      sortAttr: SupplierAccountOrderEnum.first_name,
      sortable: true,
    },

    {
      name: formatMessage({
        id: 'admin.supplier-account.grid.column-headers.last-name',
        defaultMessage: 'Last Name',
      }),
      cell: (row) => (
        <Tooltip title={row.surname ?? ''}>
          <Box className={classes.nameBox}>{row.surname}</Box>
        </Tooltip>
      ),
      sortAttr: SupplierAccountOrderEnum.surname,
      sortable: true,
    },

    {
      name: formatMessage({
        id: 'admin.supplier-account.grid.column-headers.company',
        defaultMessage: 'Company',
      }),
      cell: (row) => {
        const text = row.supplierCompanies?.nodes?.map((c) => c?.name ?? '').join(', ');
        return (
          <Tooltip title={text ?? ''}>
            <Box className={classes.companyNameBox}>{text}</Box>
          </Tooltip>
        );
      },

      sortable: false,
    },

    {
      name: formatMessage({
        id: 'admin.supplier-account.grid.column-headers.live-status',
        defaultMessage: 'Live Status',
      }),
      cell: (row) =>
        row.supplierCompanies?.nodes?.some((c) => c && SupplierUtils.isCompanyActive(c)) ? (
          <LiveBadge />
        ) : (
          <NotLiveBadge />
        ),
      sortable: false,
    },

    {
      name: formatMessage({
        id: 'admin.supplier-account.grid.column-headers.register-date',
        defaultMessage: 'Register Date',
      }),
      cell: (row) => (
        <Tooltip title={`Timezone: ${DateUtils.DISPLAYED_TZ}`}>
          <Box>{DateUtils.toBerlinTimezoneString(row.createdAt)}</Box>
        </Tooltip>
      ),
      sortable: true,
      sortAttr: SupplierAccountOrderEnum.created_at,
    },

    {
      name: formatMessage({
        id: 'admin.supplier-account.grid.column-headers.edit',
        defaultMessage: 'Edit',
      }),
      cell: (row) => (
        <Box alignContent="right" flexWrap="nowrap" flexDirection="row" display="flex">
          <IconButton
            data-cy="detailbutton"
            component={RouterLink}
            to={`/admin/supplier_accounts/${row.id}`}
          >
            <SvgIcon fontSize="small">
              <EditIcon />
            </SvgIcon>
          </IconButton>
          <IconButton
            onClick={() => selectForDelete(row)}
            component={RouterLink}
            data-cy="deletebutton"
            to="#"
          >
            <SvgIcon fontSize="small">
              <DeleteIcon />
            </SvgIcon>
          </IconButton>
        </Box>
      ),
      sortable: false,
    },
  ];

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    if (currentTab !== value) {
      if (value === 'all') {
        setFilter({ ...filter, live: null, offline: null });
      } else if (value === 'live') {
        setFilter({ ...filter, live: true, offline: null });
      } else if (value === 'offline') {
        setFilter({ ...filter, live: null, offline: true });
      }

      setCurrentTab(value);
    }
  };

  useEffect(() => {
    if (query !== filter?.nameCont) {
      setFilter({ ...filter, nameCont: query });
    }
  }, [filter, query, setFilter]);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <DeleteSupplierAccountDialog
        open={supplierToDelete !== null}
        supplierName={`${supplierToDelete?.firstName} ${supplierToDelete?.surname}`}
        handleDelete={confirmDeletion}
        handleClose={cancelDeletion}
      />
      <Tabs
        onChange={handleTabsChange}
        scrollButtons="auto"
        textColor="secondary"
        value={currentTab}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            value={tab.value}
            label={formatMessage({
              id: `admin.supplier-account.grid.tabs.${tab.value}`,
              defaultMessage: tab.label,
            })}
          />
        ))}
      </Tabs>
      <Divider />
      <Box p={2} minHeight={56} display="flex" alignItems="center">
        <TextField
          className={classes.queryField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          onChange={(e) => setQuery(e.target.value || undefined)}
          placeholder={formatMessage({
            id: 'admin.supplier-account.grid.search',
            defaultMessage: 'Search',
          })}
          value={query}
          variant="outlined"
        />
        <Box flexGrow={1} />
      </Box>
      <GraphQLGrid<
        SupplierAccountOrderEnum,
        GetSupplierAccountsGrid_rows_edges_node,
        SupplierAccountForAdminsFilter
      >
        query={GET_SUPPLIER_ACCOUNTS_GRID}
        initialVariables={{
          first: 10,
          order: [{ attribute: SupplierAccountOrderEnum.created_at, order: QueryOrderEnum.desc }],
        }}
        filter={filter}
        loadingMessage={formatMessage({
          id: 'admin.supplier-account.grid.loading',
          defaultMessage: 'Loading supplier accounts...',
        })}
        errorMessage={formatMessage({
          id: 'admin.supplier-account.grid.error',
          defaultMessage: 'Error loading supplier accounts...',
        })}
        columns={suppliersGridColumns}
        cypressRowName="supplierrow"
      />
    </Card>
  );
};

SuppliersGrid.propTypes = {
  className: PropTypes.string,
};

export default SuppliersGrid;
