import { gql } from '@apollo/client';
import { SUPPLIER_COMPANY_FRAGMENT } from '../fragments';

export const GET_SUPPLIER_ACCOUNTS_GRID = gql`
  ${SUPPLIER_COMPANY_FRAGMENT}
  query GetSupplierAccountsGrid(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [SupplierAccountQueryOrder!]
    $filter: SupplierAccountForAdminsFilter
  ) {
    rows: supplierAccounts(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      filter: $filter
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          surname
          email
          firstName
          supplierCompanies {
            nodes {
              ...SupplierCompanyCoreData
            }
          }
          createdAt
        }
      }
    }
  }
`;
