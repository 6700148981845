const ToggableCheckbox = ({ cookieType, styles, setMySelection, mySelection }) => (
  <div className={styles.checkboxContainer}>
    <input
      type="checkbox"
      name={cookieType}
      id={cookieType}
      onChange={(e) => {
        setMySelection({
          ...mySelection,
          [cookieType]: e.target.checked,
        });
      }}
    />
  </div>
);

export default ToggableCheckbox;
