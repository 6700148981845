import { Link as RouterLink, useHistory, useRouteMatch } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  Hidden,
  Link,
  makeStyles,
  Toolbar,
  IconButton,
  SvgIcon,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { Award } from 'react-feather';
import PropTypes from 'prop-types';
import { useState, FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useAuthToken } from '../../hooks/useAuthToken';
import logo from '../../assets/foodstarter_logo.png';
import { UserPersona } from '../../types/user';
interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
  persona?: UserPersona;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  toolbar: {
    height: 64,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  image: {
    height: 64,
  },
  menu: {
    width: '15rem',
    position: 'absolute',
    top: '102%',
    padding: '1rem 2rem',
    backgroundColor: theme.palette.background.paper,
    listStyleType: 'none',
    right: 0,
    '& li': {
      margin: '0.25rem 0',
      padding: '0.5rem 0',
      '&:not(:first-child)': {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      },
    },
  },
  adminHamburger: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  merchantLink: {
    color: '#777',
    display: 'none',
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '0.1em',
    marginLeft: theme.spacing(2),
    marginTop: 10,
    textTransform: 'uppercase',

    '&:hover': {
      color: '#333',
      textDecoration: 'none',
    },

    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  merchantHamburger: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

const NavLink: FC<{ to: string }> = ({ to, children }) => {
  const classes = useStyles();
  return (
    <Link
      className={classes.link}
      color="textSecondary"
      component={RouterLink}
      to={to}
      underline="none"
      variant="body2"
    >
      {children}
    </Link>
  );
};

const routerLinkTarget = (persona?: UserPersona) => {
  if (persona) {
    return {
      [UserPersona.ADMIN]: '/admin',
      [UserPersona.MERCHANT]: '/merchants',
      [UserPersona.SUPPLIER]: '/app',
    }[persona];
  }

  return '/';
};

const TopBar: FC<TopBarProps> = ({ className, onMobileNavOpen, persona }) => {
  const currentUrl = useRouteMatch().url;
  const history = useHistory();
  const classes = useStyles();
  const [authToken] = useAuthToken();
  const { formatMessage } = useIntl();
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);

  useEffect(() => {
    setIsMenuExpanded(false);
  }, [history.location.pathname]);

  const handleClick = () => {
    if (persona === UserPersona.ADMIN && onMobileNavOpen) {
      onMobileNavOpen();
    }
    setIsMenuExpanded(!isMenuExpanded);
  };

  const logoutText = formatMessage({
    id: 'topbar.logout-link',
    defaultMessage: 'Logout',
  });
  const loginText = formatMessage({
    id: 'topbar.login-link',
    defaultMessage: 'Login',
  });
  const accountSettingsText = formatMessage({
    id: 'topbar.account-settings',
    defaultMessage: 'Accounts Settings',
  });
  const registerText = formatMessage({
    id: 'topbar.register-link',
    defaultMessage: 'Register',
  });
  const dashboardText = formatMessage({
    id: 'topbar.dashboard',
    defaultMessage: 'Dashboard',
  });

  return (
    <AppBar className={clsx(classes.root, className)} color="default">
      <Toolbar className={classes.toolbar}>
        <RouterLink to={routerLinkTarget(persona)}>
          <img src={logo} className={classes.image} alt="Edeka foodstarter logo" />
        </RouterLink>

        {persona == UserPersona.MERCHANT && (
          <>
            <Link
              className={classes.merchantLink}
              component="a"
              color="inherit"
              href={`${process.env.REACT_APP_LION}/merchants/products/overview`}
            >
              Alle Produkte
            </Link>
            <RouterLink className={classes.merchantLink} color="inherit" to="/merchants">
              Regional
            </RouterLink>
          </>
        )}

        <>
          <Box flexGrow={1} />
          <Button
            onClick={handleClick}
            className={
              persona === UserPersona.ADMIN
                ? classes.adminHamburger
                : persona === UserPersona.MERCHANT
                ? classes.merchantHamburger
                : ''
            }
          >
            <MenuIcon />
          </Button>
          {isMenuExpanded && persona !== UserPersona.ADMIN && (
            <ul className={classes.menu}>
              {!!authToken ? (
                persona === UserPersona.MERCHANT ? (
                  <>
                    <li>
                      <Link
                        className={classes.link}
                        component="a"
                        color="textSecondary"
                        variant="body2"
                        href={`${process.env.REACT_APP_LION}/merchants/products/overview`}
                      >
                        Alle Produkte
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={classes.link}
                        component={RouterLink}
                        color="textSecondary"
                        variant="body2"
                        to="/merchants"
                        underline="always"
                      >
                        Regional
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <NavLink to="/app/dashboard">{dashboardText}</NavLink>
                    </li>
                    <li>
                      <NavLink to="/app/account">{accountSettingsText}</NavLink>
                    </li>
                    <li>
                      <NavLink to="/logout">{logoutText}</NavLink>
                    </li>
                  </>
                )
              ) : currentUrl === '/login' ? (
                <li>
                  <NavLink to="/">{registerText}</NavLink>
                </li>
              ) : currentUrl === '/admin/sign_in' ? (
                <li>
                  <NavLink to="/admin/sign_in">{loginText}</NavLink>
                </li>
              ) : (
                <li>
                  <NavLink to="/login">{loginText}</NavLink>
                </li>
              )}
            </ul>
          )}
        </>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};

export default TopBar;
