/* Classes, Functions and hooks */
import { FC, useState, useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import { useMutation } from '@apollo/client';
import { apolloClient } from '../graphql/GraphQlProvider';
import SupplierContext, { initialSupplierState } from './SupplierContext';

/* Types */
import {
  GetSupplierAccountById_supplierAccount as SupplierAccountType,
  GetSupplierAccountById_supplierAccount_supplierCompanies_nodes as SupplierCompanyType,
  GetSupplierAccountById_supplierAccount_supplierCompanies_nodes_images as ImageType,
} from './queries/__generated__/GetSupplierAccountById';
import { GetSupplierCompanyStatusById_supplierCompany } from './queries/__generated__/GetSupplierCompanyStatusById';
import { SupplierState } from './supplierContext.types';
import { UserPersona } from '../../types/user';
import { CompanyPublishedState } from '../../types/company';

/* Mutations and Queries */
import { UPDATE_SUPPLIER_ACCOUNT_BY_ID_BASIC_ATTRIBUTES } from './mutations/updateSupplierAccountByIdBasicAttributes';
import { GET_SUPPLIER_ACCOUNT_BY_ID } from './queries/getSupplierAccountById';
import { UPDATE_SUPPLIER_COMPANY_STATUS_BY_ID } from './mutations/updateSupplierCompanyStatusById';
import { GET_SUPPLIER_COMPANY_STATUS_BY_ID } from './queries/getSupplierCompanyStatusById';
import { UPDATE_SUPPLIER_COMPANY_BY_ID } from './mutations/updateSupplierCompanyById';

const emptyCompany: Partial<SupplierCompanyType> = {
  city: '',
  commodityGroups: [],
  deliveryArea: '',
  description: '',
  district: '',
  minimumOrderValue: '',
  name: '',
  postalCode: '',
  products: '',
  publishedDate: null,
  publishedState: null,
  website: '',
  logo: null,
  images: null,
  productPortfolio: null,
};

export const SupplierProvider: FC = ({ children }) => {
  const [state, setState] = useState(initialSupplierState);
  const [accountData, setAccountData] = useState<null | SupplierAccountType>(null);
  const { user } = useAuth();

  useEffect(() => {
    if (state.companyId) {
      apolloClient
        .query<GetSupplierCompanyStatusById_supplierCompany>({
          query: GET_SUPPLIER_COMPANY_STATUS_BY_ID,
          variables: {
            id: state.companyId,
          },
        })
        .then((data) => {
          const company: GetSupplierCompanyStatusById_supplierCompany = (data as any)
            .supplierCompany;
          if (company) {
            setState((prevState) => ({
              ...prevState,
              publishedState: company.publishedState ?? '',
            }));
          }
        });
    }
  }, [state.companyId]);

  const isUserSupplierAccount = !!(user?.__typename === UserPersona.SUPPLIER);

  useEffect(() => {
    if (user && isUserSupplierAccount) {
      if (!accountData) {
        apolloClient
          .query({
            query: GET_SUPPLIER_ACCOUNT_BY_ID,
            variables: {
              id: user.id,
            },
          })
          .then((data) => {
            const supplierAccount: SupplierAccountType = data.data.supplierAccount;
            setAccountData(supplierAccount);
            const { firstName, surname, phoneNumber, faxNumber } = supplierAccount;
            const company: Partial<SupplierCompanyType> =
              supplierAccount.supplierCompanies.nodes?.[0] ?? emptyCompany;

            setState((prevState) => ({
              ...prevState,
              firstName: firstName ?? '',
              surname: surname ?? '',
              phone: phoneNumber ?? '',
              fax: faxNumber,
              ...company,
              companyId: company.id ?? null,
            }));
          });
      }
    } else {
      setState(initialSupplierState);
    }
  }, [user, isUserSupplierAccount, accountData]);

  const [submitSupplierAccountDataToBackend] = useMutation(
    UPDATE_SUPPLIER_ACCOUNT_BY_ID_BASIC_ATTRIBUTES,
  );

  const [submitSupplierCompanyDataToBackend] = useMutation(UPDATE_SUPPLIER_COMPANY_BY_ID);

  const [submitSupplierCompanyStatusToBackend] = useMutation(UPDATE_SUPPLIER_COMPANY_STATUS_BY_ID);

  const updateAndSubmitSupplierAccountState = async (data: Partial<SupplierState>) => {
    setState((prevState) => ({ ...prevState, ...data }));
    return await submitSupplierAccountDataToBackend({
      variables: {
        id: user?.id,
        supplierAccountAttributes: {
          firstName: data.firstName,
          surname: data.surname,
          phoneNumber: data.phone,
          faxNumber: data.fax,
        },
      },
    });
  };

  const updateAndSubmitSupplierCompanyState = async (data: Partial<SupplierState>) => {
    const newState = { ...state, ...data };
    setState(newState);
    if (!state.companyId) {
      return new Promise<Record<string, any>>((_resolve, reject) =>
        reject({ message: 'COMPANY_NOT_SETUP_OR_KNOWN' }),
      );
    }
    return await submitSupplierCompanyDataToBackend({
      variables: {
        id: state.companyId,
        supplierCompanyAttributes: {
          name: newState.name,
          description: newState.description,
          website: newState.website,
          postalCode: newState.postalCode,
          city: newState.city,
          district: newState.district,
          deliveryArea: newState.deliveryArea,
          commodityGroups: newState.commodityGroups,
          products: newState.products,
          minimumOrderValue: newState.minimumOrderValue,
        },
      },
    });
  };

  const updateSupplierContextState = (data: Partial<SupplierState>) => {
    setState((prevState) => ({ ...prevState, ...data }));
  };

  const updateAndSubmitSupplierStatus = async () => {
    return await submitSupplierCompanyStatusToBackend({
      variables: {
        id: state.companyId,
        supplierCompanyAttributes: {
          publishedState: 'approval_requested',
          submittedAt: new Date(),
        },
      },
    });
  };

  const approvalRequested = state.publishedState === CompanyPublishedState.approval_requested;
  const approved = state.publishedState === CompanyPublishedState.approved;
  const blocked =
    state.publishedState === CompanyPublishedState.blocked_approval_requested ||
    state.publishedState === CompanyPublishedState.blocked_approved ||
    state.publishedState === CompanyPublishedState.blocked_draft;

  const basicsDone =
    !!state.name && !!state.postalCode && !!state.city && !!state.district && !!state.deliveryArea;

  const detailsDone = !!state.description;

  const productsDone =
    !!state.products &&
    !!state.minimumOrderValue &&
    !!state.commodityGroups &&
    state.commodityGroups?.length > 0;

  const accountDone = !!state.firstName && !!state.surname && !!state.phone;

  const logoDone = !!state.logo?.imageUrl?.length;

  const productPortfolioDone = !!state.productPortfolio?.portfolioUrl?.length;

  // TODO: This isn't right yet, but I couldn't get it to work with different signature issues.
  const imagesDone = !!state?.images && state?.images.length > 0;
  // state.images?.reduce((result: boolean, image: ImageType) => !!image?.imageUrl?.length ?? result, false) ||
  // false;

  const readyToSubmit = basicsDone && detailsDone && productsDone && accountDone && imagesDone;

  return (
    <SupplierContext.Provider
      value={{
        ...state,
        basicsDone,
        detailsDone,
        productsDone,
        accountDone,
        logoDone,
        productPortfolioDone,
        imagesDone,
        readyToSubmit,
        updateSupplierContextState,
        updateAndSubmitSupplierAccountState,
        updateAndSubmitSupplierCompanyState,
        updateAndSubmitSupplierStatus,
        approvalRequested,
        approved,
        blocked,
      }}
    >
      {children}
    </SupplierContext.Provider>
  );
};
