import DetailsText from './DetailsText';

const MarketingCookiesDetails = ({ styles }) => (
  <>
    <h4>
      <label htmlFor="marketingCookies">Marketing Cookies</label>
    </h4>
    <br />
    <p className={styles.shortText}>
      Diese Cookies und ähnliche Technologien werden eingesetzt, um Dir personalisierte und dadurch
      relevante werbliche Inhalte anzeigen zu können.
    </p>
    <DetailsText>
      <p>
        Marketingcookies werden eingesetzt um interessante Werbeinhalte anzuzeigen und die
        Wirksamkeit unserer Kampagnen zu messen. Dies geschieht nicht nur auf Webseiten von
        foodstarter, sondern auch auf anderen Werbepartner-Seiten (von Drittanbietern), auch bekannt
        als Retargeting. Das Retargeting dient zur Erstellung eines pseudonymen Interessenprofils
        und der Anzeige von relevanter Werbung auf anderen Webseiten. Ein unmittelbarer Rückschluss
        auf eine Person ist dabei nicht möglich. Durch das Unterdrücken von Marketingcookies siehst
        Du weiterhin die gleiche Anzahl an Werbung, die jedoch nicht auf Dein persönliches Interesse
        zugeschnitten ist.
        <br />
        <a href="/privacy" target="_blank" rel="noreferrer noopener">
          Datenschutzhinweise
        </a>
      </p>
    </DetailsText>
  </>
);

export default MarketingCookiesDetails;
