import { AuthAction } from './jwtAuthContext.actions.types';
import { AuthState } from './jwtAuthContext.types';

export const authReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user, persona, confirmationEmail } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        isAppReady: true,
        user,
        confirmationEmail,
        persona,
      };
    }
    case 'LOGIN': {
      const { user, persona } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
        persona,
        denialReasons: null,
        confirmationEmail: null,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        persona: null,
        denialReasons: null,
        confirmationEmail: null,
      };
    }
    case 'REGISTER': {
      const { user, persona, confirmationEmail } = action.payload;

      return {
        ...state,
        isAuthenticated: false,
        user,
        persona,
        denialReasons: null,
        confirmationEmail,
      };
    }
    case 'CONFIRM': {
      const { user, persona } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        isInitialised: false,
        user,
        persona,
        denialReasons: null,
        confirmationEmail: null,
      };
    }
    case 'DENIAL': {
      const { denialReasons } = action.payload;

      return {
        ...state,
        isAuthenticated: false,
        isInitialised: false,
        user: null,
        persona: null,
        denialReasons,
      };
    }
    default: {
      return { ...state };
    }
  }
};
