import { FC } from 'react';
import Page from '../../components/Page';
import { makeStyles, Link, Container, Typography } from '@material-ui/core';
import typography from '../../theme/typography';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
  container: {
    margin: '3rem auto',
  },

  typography: {
    fontSize: typography.body1.fontSize,
    fontWeight: 'bold',
    flexDirection: 'column',
  },
}));

const TermsView: FC = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Page
      title={formatMessage({
        id: 'page-titles.privacy-policy',
        defaultMessage: 'Privacy policy',
      })}
    >
      <Container maxWidth="sm" className={classes.container}>
        <Typography variant="h1" color="textPrimary" align="center" paragraph gutterBottom>
          Datenschutzerklärung
        </Typography>
        <Typography
          variant="h2"
          color="textPrimary"
          align="left"
          paragraph
          className={classes.typography}
        >
          1. Geltungsbereich
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Der Schutz personenbezogener Daten ist uns sehr wichtig. Mit den nachfolgenden Hinweisen
          zum Datenschutz wollen wir Ihnen näherbringen, welche personenbezogenen Daten wir für
          welche Zwecke verarbeiten, während Sie unser Internetangebot nutzen.
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Die nachfolgenden Hinweise gelten für alle Inhalte unter{' '}
          <Link href="https://foodstarter.edeka" target="_blank">
            https://foodstarter.edeka
          </Link>{' '}
          (nachfolgend „Angebot“ / „Webseite“). Darunter fallen zum Beispiel auch Newsletter oder
          Gewinnspiele, für die Sie sich registrieren. Der verantwortliche Anbieter für diese
          Inhalte ist die EDEKA DIGITAL GmbH, New-York-Ring 6, 22297 Hamburg,{' '}
          <Link href="mailto:info@foodstarter.edeka" target="_blank">
            info@foodstarter.edeka
          </Link>
          (nachfolgend „Verantwortliche“ / „Anbieter“).
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Die rechtlichen Grundlagen des Datenschutzes finden sich in der
          EU-Datenschutzgrundverordnung (nachfolgend DSGVO) und im Bundesdatenschutzgesetz (BDSG).
        </Typography>

        <Typography
          variant="h2"
          color="textPrimary"
          align="left"
          paragraph
          className={classes.typography}
        >
          2. Begriffsbestimmung
        </Typography>
        <Typography
          variant="h3"
          color="textSecondary"
          align="left"
          gutterBottom
          className={classes.typography}
        >
          Personenbezogene Daten
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          "Personenbezogene Daten" sind alle Informationen, die sich auf eine identifizierte oder
          identifizierbare natürliche Person beziehen; als identifizierbar wird eine natürliche
          Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer
          Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung
          oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck
          der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen
          oder sozialen Identität dieser natürlichen Person sind.
        </Typography>
        <Typography
          variant="h3"
          color="textSecondary"
          align="left"
          gutterBottom
          className={classes.typography}
        >
          Verarbeitung
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          "Verarbeitung" meint jeden mit oder ohne Hilfe automatisierter Verfahren ausgeführten
          Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das
          Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder
          Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch
          Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die
          Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.
        </Typography>

        <Typography
          variant="h3"
          color="textSecondary"
          align="left"
          gutterBottom
          className={classes.typography}
        >
          Unternehmen des EDEKA-Verbunds
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Unternehmen des EDEKA-Verbunds sind die EDEKA-Genossenschaften und ihre Mitglieder sowie
          diejenigen Gesellschaften, an denen die EDEKA-Genossenschaften einzeln oder gemeinsam
          mittelbar oder unmittelbar zu mindestens 50 % beteiligt sind.
        </Typography>

        <Typography
          variant="h2"
          color="textPrimary"
          align="left"
          paragraph
          className={classes.typography}
        >
          3. Arten der personenbezogenen Daten
        </Typography>
        <Typography
          variant="h3"
          color="textSecondary"
          align="left"
          gutterBottom
          className={classes.typography}
        >
          Zugriffsdaten
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          „Zugriffsdaten“ sind Daten über jeden Zugriff auf den Server, auf dem sich unsere Webseite
          befindet. Zu den Zugriffsdaten gehören Datum und Uhrzeit des Zugriffs, URL (Adresse) der
          verweisenden Internetseite, abgerufene Datei, Menge der gesendeten Daten, den HTTP
          Antwort-Code, Browsertyp und -version, evtl. vorhandene Browsererweiterungen, Breite und
          Höhe des Browserfensters, Farbtiefe, Betriebssystem sowie IP-Adresse und hinsichtlich des
          Einsatzes von Google Analytics diese UserID (siehe hierzu Ziffer 7).
        </Typography>
        <Typography
          variant="h3"
          color="textSecondary"
          align="left"
          gutterBottom
          className={classes.typography}
        >
          Cookies
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Wir oder Drittanbieter setzen auf dem Zugriffsgerät Cookies, das sind kleine Dateien, in
          denen der von Ihnen genutzte Web Browser Informationen zu besuchten Internetseiten ablegt,
          die vom Web Server gesendet werden. Dies können Informationen zum Seitenbesuch wie Dauer,
          Login-Daten, Benutzereingaben oder ähnliches sein.
        </Typography>
        <Typography
          variant="h3"
          color="textSecondary"
          align="left"
          gutterBottom
          className={classes.typography}
        >
          Eingabe- und Mitteilungsdaten
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Sofern Sie sich bei foodstarter registrieren, Ihr Konto verwalten, oder mit uns in Kontakt
          treten, verarbeiten wir die personenbezogenen Daten, die Sie in das jeweilige Formular
          eingeben oder uns mitteilen (ggf. Nachname, Vorname, E-Mail-Adresse, Kennwort,
          Telefonnummer, Faxnummer, Anschrift, Rolle im Unternehmen, Teamfotos, ggf. mit
          Personenbezug: Firmenname, Umsatzsteueridentifikationsnummer, Handelsregisternummer,
          Artikelinformationen, Bank- und Zahldaten, Unternehmensprofil). Zudem verarbeiten wir die
          personenbezogenen Daten, die Sie über unsere Plattform dem Partnerunternehmen bzw.
          andersrum den Kaufleuten mitteilen.
        </Typography>
        <Typography
          variant="h3"
          color="textSecondary"
          align="left"
          gutterBottom
          className={classes.typography}
        >
          Dokumente und Zertifikate, Rückrufinformationen
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Sofern Sie sich bei foodstarter registrieren, Ihr Konto verwalten, oder mit uns in Kontakt
          treten, verarbeiten wir die personenbezogenen Daten, die Sie in das jeweilige Formular
          eingeben oder uns mitteilen (ggf. Nachname, Vorname, E-Mail-Adresse, Kennwort,
          Telefonnummer, Faxnummer, Anschrift, Rolle im Unternehmen, Teamfotos, ggf. mit
          Personenbezug: Firmenname, Umsatzsteueridentifikationsnummer, Handelsregisternummer,
          Artikelinformationen, Bank- und Zahldaten, Unternehmensprofil). Zudem verarbeiten wir die
          personenbezogenen Daten, die Sie über unsere Plattform dem Partnerunternehmen bzw.
          andersrum den Kaufleuten mitteilen.
        </Typography>

        <Typography
          variant="h2"
          color="textPrimary"
          align="left"
          paragraph
          className={classes.typography}
        >
          4. Zwecke der Verarbeitung
        </Typography>
        <Typography
          variant="h3"
          color="textSecondary"
          align="left"
          gutterBottom
          className={classes.typography}
        >
          Zugriffsdaten
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Wir erheben die Zugriffsdaten aus Sicherheitsgründen zur Betrugs- und Missbrauchskontrolle
          sowie zur statistischen Erfassung der Webseitennutzung und Optimierung unserer Webseite.
          Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1Satz 1 f DSGVO. Zur Verarbeitung der
          IP-Adresse durch Drittanbieter siehe Ziffer 6.C
        </Typography>
        <Typography
          variant="h3"
          color="textSecondary"
          align="left"
          gutterBottom
          className={classes.typography}
        >
          Cookies
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Cookies dienen zum einen der Benutzerfreundlichkeit von Webseiten und damit den Nutzern
          (z.B. Speicherung von Login-Daten). Zum anderen können sie dazu dienen, die statistischen
          Daten der Webseitennutzung zu erfassen und sie zwecks Verbesserung unserer Angebote
          analysieren zu können. Die Nutzer können auf den Einsatz der Cookies Einfluss nehmen. Die
          meisten Browser verfügen über eine Optionmit der das Speichern von Cookies eingeschränkt
          oder komplett verhindert wird. Allerdings wird darauf hingewiesen, dass die Nutzung und
          insbesondere der Nutzungskomfort unserer Webseite ohne Cookies eingeschränkt werden.
          Rechtsgrundlage für die Nutzung von Cookies ist Art. 6 Abs. 1 a, f DSGVO. Zum Einsatz von
          Cookies durch Drittanbieter siehe Ziffern 6.
        </Typography>
        <Typography
          variant="h3"
          color="textSecondary"
          align="left"
          gutterBottom
          className={classes.typography}
        >
          Analyse- und Bewegungsdaten
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Wir binden verschiedene Dienste und Inhalte von Drittanbietern auf unserer Webseite ein,
          aus denen anhand von IP-Adressen und Cookies Analyse- und Bewegungsdaten erstellt und
          verarbeitet werden. Details hierzu finden Sie unter Ziffer 6.
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Rechtsgrundlage für die Verarbeitung der IP-Adresse und die Nutzung von Cookies istArt. 6
          Abs. 1 a, f DSGVO. Die vorgenannten Daten werden dazu verwendet, um die Nutzung unserer
          Website zu analysieren und unsere Webseite regelmäßig zu verbessern, ferner um die
          Attraktivität der angebotenen Inhalte für Sie zu erhöhen und unsere Angebote zu optimieren
          und Werbung auf Drittseiten zu schalten sowie eine Kosten-Nutzen-Analyse unserer
          Internetwerbung durchführen zu können.
        </Typography>
        <Typography
          variant="h3"
          color="textSecondary"
          align="left"
          gutterBottom
          className={classes.typography}
        >
          Eingabe- und Mitteilungsdaten
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Die Eingabe- und Mitteilungsdaten verwenden wir für die Registrierung und Verwaltung Ihres
          Kontos, Erfüllung unserer Leistungen, Zahlungsabwicklung, dem Betrieb unserer Plattform
          sowie zur Kommunikation mit Ihnen (Rechtsgrundlage ist Art. 6 Abs. 1 Satz 1 a, b, f
          DSGVO).
        </Typography>
        <Typography
          variant="h3"
          color="textSecondary"
          align="left"
          gutterBottom
          className={classes.typography}
        >
          Dokumente und Zertifikate, Rückrufinformationen
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Informationen zur Verkehrsfähigkeit der Artikel benötigen wir, um die Aufnahme von
          Partnerunternehmen bei Foodstarter zu prüfen. Informationen zu Warenrücknahmen und
          Warenrückrufen benötigen wir ggf., damit Rücknahmen und Rückrufe innerhalb des
          EDEKA-Verbundes effektiv bearbeitet und durchgeführt werden können (Rechtsgrundlage ist
          Art. 6 Abs. 1 Satz 1 a, f DSGVO).
        </Typography>

        <Typography
          variant="h2"
          color="textPrimary"
          align="left"
          paragraph
          className={classes.typography}
        >
          5. Leistungspakete
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Bestandteile unserer Foodstarter-Leistungspakete sind relevante Informationen für
          Partnerunternehmen rund um den EDEKA-Verbund sowie relevante Informationen für Unternehmen
          des EDEKA-Verbunds über die Partnerunternehmen. Diesbezüglich versenden wir regelmäßig
          Informationsschreiben per E-Mail an die im Rahmen der Registrierung mitgeteilte
          E-Mail-Adresse (Rechtsgrundlage ist Art. 6 Abs. 1 Satz 1 a, b, f DSGVO).
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Wir setzen hierfür den Dienst mailjet des Dienstanbieters Mailjet SAS, 13-13bis, rue de
          l’Aubrac, 75012 Paris, Frankreich ein. Hierdurch werden Ihre personenbezogenen Daten
          (E-Mail-Adresse, IP-Adresse) an einen Mailjet-Server mit Sitz in der Europäischen Union
          übertragen. Nähere Informationen zu der Datenverwendung im Rahmen des Dienstes finden Sie
          unter{' '}
          <Link href="https://www.mailjet.de/sicherheit-datenschutz/" target="_blank">
            https://www.mailjet.de/sicherheit-datenschutz/
          </Link>
          . Rechtsgrundlage für die Nutzung des Dienstes ist Art. 6 Abs. 1 f DSGVO. Unser
          berechtigtes Interesse folgt daraus, dass wir den Einsatz zum Zwecke der Optimierung
          unserer Leistungen benötigen.
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Zudem geben wir Informationen zu den Partnerunternehmen ggf. an Unternehmen des
          EDEKA-Verbunds zur Anbahnung und Weiterentwicklung von Geschäftsbeziehungen weiter
          (Rechtsgrundlage ist Art. 6 Abs. 1 Satz 1 a, b, f DSGVO).
        </Typography>
        <Typography
          variant="h2"
          color="textPrimary"
          align="left"
          paragraph
          className={classes.typography}
        >
          6. Kategorien von Empfängern personenbezogener Daten
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Mit Ausnahme der von Drittanbietern verarbeiteten Daten verarbeitet unser Hosting-Anbieter
          die in Ziffer 3 genannten Daten in unserem Auftrag. Die Daten werden auf den Servern
          unseres Hosting-Anbieters gespeichert. Hinsichtlich der Weitergabe von Informationen
          innerhalb des EDEKA-Verbunds siehe Ziffern 4. und 5. Hinsichtlich der Weitergabe von Daten
          an den Mailjet-Anbieter siehe Ziffer 5. Hinsichtlich des Einsatzes von Cookies und der
          Verarbeitung von IP-Adressen durch Drittanbieter siehe zudem nachfolgend Ziffer 7.7
        </Typography>
        <Typography
          variant="h2"
          color="textPrimary"
          align="left"
          paragraph
          className={classes.typography}
        >
          7. Analyse- und Bewegungsdaten
        </Typography>
        <Typography
          variant="h3"
          color="textSecondary"
          align="left"
          gutterBottom
          className={classes.typography}
        >
          Google Analytics
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“).
          Google Analytics verwendet Cookies, die auf Ihrem Computer gespeichertwerden und die eine
          Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten
          Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von
          Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der
          IP-Anonymisierung auf dieser Website, wird Ihre IP-Adresse von Google jedoch innerhalb von
          Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über
          den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle
          IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. In unserem
          Auftragwird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten,
          um Reports über die Website-Aktivitäten zusammenzustellen und um weitere mit der
          Website-Nutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem
          Website-Betreiber zu erbringen.
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wirdnicht mit
          anderen Daten von Google zusammengeführt.
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
          Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
          gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werdennutzen
          können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf
          Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse)an Google sowie die
          Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link
          verfügbare Browser-Plug-in herunterladen und installieren:{' '}
          <Link href="http://tools.google.com/dlpage/gaoptout?hl=de" target="_blank">
            http://tools.google.com/dlpage/gaoptout?hl=de
          </Link>
          .
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Diese Website verwendet Google Analytics mit der Erweiterung „_anonymizeIp()“. Dadurch
          werden IP-Adressen gekürzt weiterverarbeitet, eine Personenbeziehbarkeit kann damit
          ausgeschlossen werden. Soweit den über Sie erhobenen Daten ein Personenbezug zukommt, wird
          dieser also sofort ausgeschlossen und die personenbezogenen Daten damit umgehend gelöscht.
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Für die Ausnahmefälle, in denen personenbezogene Daten in die USA übertragen werden, hat
          sich Google dem EU-US Privacy Shield unterworfen,{' '}
          <Link href="https://www.privacyshield.gov/EU-US-Framework" target="_blank">
            https://www.privacyshield.gov/EU-US-Framework
          </Link>
          .
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Informationen des Drittanbieters: Google Dublin, Google Ireland Ltd., Gordon House, Barrow
          Street, Dublin 4, Ireland, Fax: +353 (1) 436 1001. Nutzerbedingungen:{' '}
          <Link href="http://www.google.com/analytics/terms/de.html" target="_blank">
            http://www.google.com/analytics/terms/de.html
          </Link>
          , Übersicht zum Datenschutz:{' '}
          <Link href="http://www.google.com/intl/de/analytics/learn/privacy.html" target="_blank">
            http://www.google.com/intl/de/analytics/learn/privacy.html
          </Link>
          , sowie die Datenschutzerklärung:{' '}
          <Link href="http://www.google.de/intl/de/policies/privacy" target="_blank">
            http://www.google.de/intl/de/policies/privacy
          </Link>
          .
        </Typography>

        <Typography
          variant="h3"
          color="textSecondary"
          align="left"
          gutterBottom
          className={classes.typography}
        >
          Universal Analytics und UserID
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Unsere Nutzeranalyse verwendet zudem Universal Analytics. Damit können wir Informationen
          über die Nutzung unserer Angebote auf verschiedenen Geräten erhalten („Cross Device“). Wir
          setzen mittels der Cookie-Technologie eine pseudonymisierte User-ID ein, die keine
          personenbezogenen Daten enthält und solche auch nicht an Google übermittelt. Der
          Datenerhebung und -speicherung kann jederzeit mit Wirkung für die Zukunft durch ein
          Browser-Plugin von Google (
          <Link href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank">
            https://tools.google.com/dlpage/gaoptout?hl=de
          </Link>
          ) widersprochen werden. Dieses OptOut müssen Sie auf allen Systemen durchführen, die Sie
          nutzen, beispielsweise in einem anderen Browser oder auf Ihrem mobilen Endgerät. Weitere
          Hinweise zu Universal Analytics finden Sie hier:{' '}
          <Link
            href="https://support.google.com/analytics/answer/2838718?hl=de&ref_topic=6010376"
            target="_blank"
          >
            https://support.google.com/analytics/answer/2838718?hl=de&ref_topic=6010376
          </Link>
        </Typography>

        <Typography
          variant="h2"
          color="textPrimary"
          align="left"
          paragraph
          className={classes.typography}
        >
          8. Dauer der Verarbeitung
        </Typography>
        <Typography
          variant="h3"
          color="textSecondary"
          align="left"
          gutterBottom
          className={classes.typography}
        >
          Zugriffsdaten
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Sofern von Drittanbietern anhand von Cookies und IP-Adressen verarbeitet werden, haben wir
          auf die Dauer der Verarbeitung keinen Einfluss. Unter Ziffern 5. und 7. finden Sie die
          Links zu den Datenschutzerklärungen der Drittanbieter. Dort können Sie sich über die Dauer
          der Verarbeitung informieren.
        </Typography>
        <Typography
          variant="h3"
          color="textSecondary"
          align="left"
          gutterBottom
          className={classes.typography}
        >
          Cookies und IP-Adressen, die von Dritten verarbeitet werden
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Die Zugriffsdaten werden aus Sicherheitsgründen (z.B. zur Aufklärung von Missbrauchs- oder
          Betrugshandlungen) fortlaufend gespeichert gelöscht, sofern Sie hierfür nicht mehr
          benötigt werden.
        </Typography>
        <Typography
          variant="h3"
          color="textSecondary"
          align="left"
          gutterBottom
          className={classes.typography}
        >
          Eingabe- und Mitteilungsdaten, Dokumente und Zertifikate, Rückrufinformationen
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Die vorgenannten Daten verarbeiten wir und unser Hosting-Anbieter für die Dauer der
          Zusammenarbeit mit Ihnen, sofern diese Daten nicht den steuerrechtlichen und
          handelsrechtlichen Aufbewahrungsfristen unterliegen oder eine Einwilligung in die
          Weiterverarbeitung vorliegt oder ein anderer gesetzlicher Grund vorliegt, der eine weitere
          Verarbeitung rechtfertigt. Darauf, wie lange andere Unternehmen des EDEKA-Verbunds Daten
          verarbeiten, haben wir keinen Einfluss. Wenden Sie sich ggf. an diese. Hinsichtlich der
          Datenverarbeitung durch Drittanbieter verweisen wir auf deren Datenschutzerklärungen,
          siehe Ziffern 5. und 7.
        </Typography>
        <Typography
          variant="h2"
          color="textPrimary"
          align="left"
          paragraph
          className={classes.typography}
        >
          9. Freiwilligkeit der Datenbereitstellung
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Die Bereitstellung der Wege der Registrierung und zur Verwaltung des Kontos mitgeteilten
          personenbezogenen Daten ist für die Nutzung von Foodstarter erforderlich. Im Übrigen sind
          Sie nicht verpflichtet, die personenbezogenen Daten beim Besuch unserer Webseite
          bereitzustellen. Hinsichtlich der Verarbeitung der IP-Adresse und der Nutzung von Cookies
          verweisen wir auf Ziffern 4. und 7.
        </Typography>
        <Typography
          variant="h2"
          color="textPrimary"
          align="left"
          paragraph
          className={classes.typography}
        >
          10. Widerspruchsrecht
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Sie haben das Recht, jederzeit Widerspruch gegen die auf Grundlage von Art. 6 Abs. 1 Satz
          1 f DSGVO verarbeiteten personenbezogenen Daten zu erheben, sofern Gründe für den
          Widerspruch vorliegen, die sich aus Ihrer besonderen Situation ergeben. Ihre
          personenbezogenen Daten werden jedoch weiterverarbeitet, wenn zwingende schutzwürdige
          Gründe vorliegen, die Daten weiter zu verarbeiten, die die Interessen, Rechte und
          Freiheiten Ihrer Person überwiegen, oder wenn die Verarbeitung der Geltendmachung,
          Ausübung oder Verteidigung von Rechtsansprüchen dient. Sofern wir von Ihnen
          personenbezogene Daten verarbeiten, um Direktwerbung zu betreiben, haben Sie das Recht,
          jederzeit ohne Vorliegen von Gründen Widerspruch gegen die Verarbeitung der
          personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen (Art. 21 DSGVO).
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Zu den Widerspruchs- und Deaktivierungsmöglichkeiten hinsichtlich des Einsatzes von
          Drittanbieterlösungen siehe Ziffer 7. und die dortigen Verlinkungen zu den
          Datenschutzerklärungen der Drittanbieter.
        </Typography>
        <Typography
          variant="h2"
          color="textPrimary"
          align="left"
          paragraph
          className={classes.typography}
        >
          11. Weitere Betroffenenrechte
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Sie haben im Falle einer erteilten Einwilligung das Recht auf Widerruf dieser. Wir weisen
          Sie darauf hin, dass ein Widerruf nichts an der Rechtmäßigkeit der bis zum Widerruf
          erteilten Verarbeitung ändert (keine Rückwirkung des Widerrufs).
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Sie haben das Recht, im Rahmen der DSGVO auf Antrag unentgeltlich Auskunft zu erhalten
          über die bei uns vorhandenen, Sie betreffenden personenbezogenen Daten (Art. 15 DSGVO).
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Ferner haben Sie nach Maßgabe der DSGVO Anspruch auf Berichtigung (Art. 16 DSGVO),
          Löschung (Art. 17 DSGVO), Einschränkung (Art. 18 DSGVO) und Übertragung (Art. 20 DSGVO)
          Ihrer personenbezogenen Daten.
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Sie haben zudem das Recht, sich bei der für uns zuständigen Datenschutzaufsichtsbehörde in
          begründeten Fällen zu beschweren (Art. 77 DSGVO).
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Ihre Rechte nach der DSGVO können Sie per E-Mail oder schriftlich geltend machen. Die
          Kontaktdaten des Anbieters und des in der EU Verantwortlichen finden Sie nachstehend.
        </Typography>
        <Typography
          variant="h2"
          color="textPrimary"
          align="left"
          paragraph
          className={classes.typography}
        >
          Kontaktdaten
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Anbieter als verantwortliche Stelle: EDEKA DIGITAL GmbH, Geschäftsführung: Christoph
          Diekmeyer, New-York-Ring 6, 22297 Hamburg, Telefon: +49 40 6377 – 0, Telefax: +49 40 6377
          – 4010, E-Mail:
          <Link href="mailto:info.eddi@edeka.de" target="_blank">
            info.eddi@edeka.de
          </Link>
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Unseren Datenschutzbeauftragten erreichen Sie unter{' '}
          <Link href="mailto:datenschutz.eddi@edeka.de" target="_blank">
            datenschutz.eddi@edeka.de
          </Link>{' '}
          oder unserer Postadresse mit dem Zusatz „der Datenschutzbeauftragte“.
        </Typography>
        <Typography variant="body2" align="left" paragraph gutterBottom>
          Datenschutzaufsichtsbehörde: Freie und Hansestadt Hamburg, Der Hamburgische Beauftragte
          für Datenschutz und Informationsfreiheit, Klosterwall 6 (Block C), 20095 Hamburg, Tel.:
          040 / 428 54 – 4040, Fax: 040 / 428 54 – 4000, E-Mail:{' '}
          <Link href="mailto:mailbox@datenschutz.hamburg.de" target="_blank">
            mailbox@datenschutz.hamburg.de
          </Link>
        </Typography>
        <Typography variant="body2" align="left" paragraph>
          Stand: Februar 2021
        </Typography>
      </Container>
    </Page>
  );
};
export default TermsView;
