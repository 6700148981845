import MomentUtils from '@date-io/moment';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { flatten } from 'flat';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import moment from 'moment';
import 'moment/locale/de';
import { SnackbarProvider } from 'notistack';
import { IntlProvider } from 'react-intl';
import { Router } from 'react-router-dom';
import Rollbar from 'rollbar';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import CookiesBanner from './components/Cookies/CookiesBanner';
import GlobalStyles from './components/GlobalStyles';
import ScrollReset from './components/ScrollReset';
import GraphQlProvider from './contexts/graphql/GraphQlProvider';
import { AuthProvider } from './contexts/jwt-auth/JWTAuthProvider';
import SystemSettingsProvider from './contexts/system-settings/SystemSettingsProvider';
import useSettings from './hooks/useSettings';
import de from './lang/de.json';
import routes, { renderRoutes } from './routes';
import { createTheme } from './theme';
import { DateUtils } from './utils/date-utils';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const de_flatten = flatten(de);
moment.locale(DateUtils.DISPLAYED_LOCALE);

const nodeEnv = process.env.NODE_ENV;
const rollbar = new Rollbar({
  accessToken: '1c7a28ac864f44aeb0d07da673e63c1a',
  captureUncaught: true,
  captureUnhandledRejections: true,
  // enable reporting only in production by setting enabled conditionally:
  enabled: nodeEnv === 'production',
});

function App() {
  const { settings } = useSettings();

  const theme = createTheme({
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <IntlProvider messages={de_flatten as any} locale="de">
        <SystemSettingsProvider>
          <StylesProvider jss={jss}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale={DateUtils.DISPLAYED_LOCALE}>
              <SnackbarProvider dense maxSnack={3}>
                <GraphQlProvider>
                  <Router history={history}>
                    <QueryParamProvider adapter={ReactRouter5Adapter}>
                      <AuthProvider>
                        <GlobalStyles />
                        <ScrollReset />
                        <CookiesBanner />
                        {renderRoutes(routes)}
                      </AuthProvider>
                    </QueryParamProvider>
                  </Router>
                </GraphQlProvider>
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </StylesProvider>
        </SystemSettingsProvider>
      </IntlProvider>
    </ThemeProvider>
  );
}

export default App;
