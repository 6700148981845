import { NEW_PASSWORD } from '../../auth/mutations/newPassword';

export const newPasswordMock = {
  request: {
    query: NEW_PASSWORD,
    variables: {
      password: 'Enim1234567velit-!.',
      passwordConfirmation: 'Enim1234567velit-!.',
      resetPasswordToken: '3L4MzskrB-vU3Zw2kPAE',
    },
  },
  result: {
    data: {
      newPassword: {
        denialReasons: null,
      },
    },
  },
};

export const newPasswordConfirmationDoesNotMatchMock = {
  request: {
    query: NEW_PASSWORD,
    variables: {
      password: 'Enim1234567velit-!.',
      passwordConfirmation: 'Fino1234567velit-!.',
      resetPasswordToken: '3L4MzskrB-vU3Zw2kPAE',
    },
  },
  result: {
    data: {
      newPassword: {
        denialReasons: ['PASSWORD_CONFIRMATION_DOESN_T_MATCH_PASSWORD'],
      },
    },
  },
};
