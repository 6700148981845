import { FC } from 'react';
import Wrapper from './shared/Wrapper';
import AccountDetails from './AccountDetails';

const AccountView: FC = () => {
  return (
    <Wrapper
      formattedMessageMarkdownTitleId="page-titles.account"
      formattedMessageMarkdownHeaderId="account.header"
    >
      <AccountDetails />
    </Wrapper>
  );
};

export default AccountView;
