import DetailsText from './DetailsText';

const AnalyticalCookiesDetails = ({ styles }) => (
  <>
    <h4>
      <label htmlFor="analyticsCookies">Analytische Cookies</label>
    </h4>
    <br />
    <p className={styles.shortText}>
      Diese Cookies helfen uns, das Nutzungsverhalten besser zu verstehen.
    </p>
    <DetailsText>
      <p>
        Analyse-Cookies ermöglichen die Erhebung von Nutzungs- und Erkennungsmöglichkeiten in so
        genannten pseudonymen Nutzungsprofilen. Wir benutzen beispielsweise Analysecookies, um die
        Zahl der Besucher einer Webseite oder eines Dienstes zu ermitteln oder um andere Statistiken
        im Hinblick auf den Betrieb unserer Produkte zu erheben, als auch das Nutzerverhalten auf
        Basis anonymer und pseudonymer Informationen zu analysieren, wie Besucher mit der Webseite
        interagieren. Ein unmittelbarer Rückschluss auf eine Person ist dabei nicht möglich. Die
        Analyse-Cookies werden von uns selbst oder Dritten erhoben und verarbeitet.
        <br />
        <a href="/privacy" target="_blank" rel="noreferrer noopener">
          Datenschutzhinweise
        </a>
      </p>
    </DetailsText>
  </>
);

export default AnalyticalCookiesDetails;
