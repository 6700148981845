import { FC, useContext, useState } from 'react';
import { makeStyles, Container, Typography, Box, Grid, Button } from '@material-ui/core';
import Page from '../../components/Page';
import AccountSetupForm from './AccountSetupForm';
import typography from '../../theme/typography';
import { useIntl } from 'react-intl';
import SupplierContext from '../../contexts/suppliers/SupplierContext';
import { NavLink as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
  container: {
    marginTop: '5rem',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  accountSetupForm: {
    backgroundColor: '#fff',
    padding: '1rem',
    borderRadius: '0.6rem',
  },
  formHeader: {
    fontSize: typography.h3.fontSize,
  },
  header: {
    marginBottom: '1rem',
  },
  description: {
    marginBottom: '2rem',
  },
  linkButton: {
    margin: 'auto',
    marginTop: '0.5rem',
  },
  thanksText: {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.h3.fontWeight,
    textAlign: 'center',
  },
  messageText: {
    fontSize: theme.typography.h3.fontSize,
    textAlign: 'center',
  },
}));

const AccountSetupSuccess: FC = () => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { firstName } = useContext(SupplierContext);
  return (
    <>
      <Typography variant="body1" gutterBottom className={classes.thanksText} color="textPrimary">
        {formatMessage(
          {
            id: 'account-setup.thank-you',
            defaultMessage: 'Thank you',
          },
          {
            name: firstName,
          },
        )}
      </Typography>
      <Typography variant="body1" gutterBottom className={classes.messageText} color="textPrimary">
        {formatMessage({
          id: 'account-setup.introduce',
          defaultMessage: 'Foodstarter is asking you to introduce yourself',
        })}
      </Typography>
      <Typography variant="body1" gutterBottom className={classes.messageText} color="textPrimary">
        {formatMessage({
          id: 'account-setup.next-steps',
          defaultMessage: 'Give your information in the next steps',
        })}
      </Typography>
      <Button
        to="/onboarding/basics"
        component={RouterLink}
        variant="contained"
        color="secondary"
        fullWidth
        size="large"
        className={classes.linkButton}
      >
        {formatMessage({
          id: 'account-setup.lets-go',
          defaultMessage: 'Give your information in the next steps',
        })}
      </Button>
    </>
  );
};

const AccountSetUpView: FC = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [formSubmitted, setFormSubmitted] = useState(false);

  return (
    <Page
      className={classes.root}
      title={formatMessage({
        id: 'page-titles.setup-account',
        defaultMessage: 'Account setup view',
      })}
    >
      <Container maxWidth="sm" className={classes.container}>
        <Typography variant="h1" color="textPrimary" align="center" className={classes.header}>
          {formatMessage({
            id: 'account-setup.profile',
            defaultMessage: 'Your Profile',
          })}
        </Typography>
        {!formSubmitted && (
          <Typography
            variant="h2"
            align="center"
            color="textSecondary"
            className={classes.description}
          >
            {formatMessage({
              id: 'account-setup.data',
              defaultMessage: 'Login',
            })}
          </Typography>
        )}
        <Box justifyContent="center" className={classes.accountSetupForm}>
          {formSubmitted ? (
            <AccountSetupSuccess />
          ) : (
            <AccountSetupForm setFormSubmitted={setFormSubmitted} />
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default AccountSetUpView;
