import { TableCell, TableSortLabel, Tooltip } from '@material-ui/core';
import { PropsWithChildren } from 'react';
import { QueryOrderEnum } from '../../../__generated__/globalTypes';

export interface SortableColumnHeaderProps<OrderEnumT> {
  sortAttr: OrderEnumT;
  name: string;
  onSortToggle: Function;
  currentSort?: {
    attribute: OrderEnumT;
    order: QueryOrderEnum;
  };
  key: any;
}

export function SortableColumnHeader<OrderEnumT>(
  props: PropsWithChildren<SortableColumnHeaderProps<OrderEnumT>>,
) {
  const { name, sortAttr, onSortToggle, currentSort, children, key } = props;
  return (
    <TableCell onClick={() => onSortToggle(sortAttr)} key={key}>
      <Tooltip enterDelay={300} title={`Sort by ${name}`}>
        <TableSortLabel
          active={currentSort && currentSort.attribute === sortAttr}
          direction={
            currentSort && currentSort.attribute === sortAttr ? currentSort.order : undefined
          }
        >
          {children ? children : name}
        </TableSortLabel>
      </Tooltip>
    </TableCell>
  );
}

export default SortableColumnHeader;
