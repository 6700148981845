import type { FC } from 'react';
import { Redirect } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { PERSONA_DEFAULT_ROUTE_MAP, PERSONA_LOGIN_MAP, UserPersona } from '../types/user';

interface AuthGuardProps {
  allowedPersonas: UserPersona[];
  sendGuestsToLoginFor?: UserPersona;
}

/*
  The AuthGuard is responsible for only letting authorized users "pass through".
  Children of the AuthGuard component will require an authorized user.
  If the user is not authorized, they will be redirected to `/login` or another specified login page.
*/
const AuthGuard: FC<AuthGuardProps> = ({
  children,
  allowedPersonas,
  sendGuestsToLoginFor = UserPersona.SUPPLIER,
}) => {
  const { isAuthenticated, persona } = useAuth();

  // If authenticated but trying to go where not allowed
  // throw them out and GuestGuard will take care of it
  if (persona && !allowedPersonas.includes(persona)) {
    return <Redirect to={PERSONA_DEFAULT_ROUTE_MAP[persona]} />;
  }

  if (!isAuthenticated) {
    return <Redirect to={PERSONA_LOGIN_MAP[sendGuestsToLoginFor]} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
