import { gql } from '@apollo/client';

export const NEW_PASSWORD = gql`
  mutation NewPassword(
    $password: String!
    $passwordConfirmation: String!
    $resetPasswordToken: String!
  ) {
    newPassword(
      input: {
        password: $password
        passwordConfirmation: $passwordConfirmation
        resetPasswordToken: $resetPasswordToken
      }
    ) {
      denialReasons
    }
  }
`;
