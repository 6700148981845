import sign from 'jwt-encode';
import { CONFIRM_SUPPLIER_ACCOUNT } from '../../auth/mutations/confirmSupplierAccount';

const secret = '';
const data = {
  account_id: '1234567890',
  account_type: 'SupplierAccount',
  exp: (Date.now() + 12096e5) / 1000,
};
const jwtToken = sign(data, secret);

export const confirmSupplierAccountMock = {
  request: {
    query: CONFIRM_SUPPLIER_ACCOUNT,
    variables: {
      email: 'foo@bar.baz',
      confirmationToken: 'jajd-asdasd-3434',
    },
  },
  result: {
    data: {
      confirmSupplierAccount: {
        denialReasons: null,
        session: {
          jwtToken,
          account: {
            id: 2,
            email: 'foo@bar.baz',
          },
        },
      },
    },
  },
};

export const confirmSupplierAccountWithNotMatchingTokenMock = {
  request: {
    query: CONFIRM_SUPPLIER_ACCOUNT,
    variables: {
      email: 'foo@bar.baz',
      confirmationToken: 'not-matching-token',
    },
  },
  result: {
    data: {
      confirmSupplierAccount: {
        denialReasons: ['CONFIRMATION_TOKEN_DOES_NOT_MATCH'],
        session: null,
      },
    },
  },
};

export const confirmSupplierAccountWithOutdatedConfirmationMock = {
  request: {
    query: CONFIRM_SUPPLIER_ACCOUNT,
    variables: {
      email: 'foo@bar.baz',
      confirmationToken: 'outdated-token',
    },
  },
  result: {
    data: {
      confirmSupplierAccount: {
        denialReasons: [
          'SUPPLIER_ACCOUNT_EMAIL_NEEDS_TO_BE_CONFIRMED_WITHIN_3_DAYS_PLEASE_REQUEST_A_NEW_ONE',
        ],
        session: null,
      },
    },
  },
};
