import JwtDecode from 'jwt-decode';
import AuthStorageAccessor from '../../utils/authStorage';
import { DenialAction, LoginAction } from './jwtAuthContext.actions.types';
import { AuthResponse } from './jwtAuthContext.types';

export class JWTAuthContextUtils {
  public static setSession = (accessToken: string | null): void => {
    if (accessToken) {
      window.localStorage.setItem('accessToken', accessToken);
      AuthStorageAccessor.writeToken(accessToken);
    } else {
      window.localStorage.removeItem('accessToken');
      AuthStorageAccessor.removeToken();
    }
  };

  public static isValidToken = (accessToken: string): boolean => {
    if (!accessToken) {
      return false;
    }

    const decoded: any = JwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  public static processLoginResponse = (
    result: any,
    key: string,
    dispatch: (action: LoginAction | DenialAction) => void,
  ): void => {
    const { session, denialReasons } = result.data[key] as AuthResponse;

    if (denialReasons && denialReasons.length) {
      dispatch({
        type: 'DENIAL',
        payload: {
          denialReasons,
        },
      });

      return;
    }

    JWTAuthContextUtils.removeConfirmationEmail();

    const accessToken = session.jwtToken;
    const user = session.account;

    JWTAuthContextUtils.setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        persona: user.__typename,
      },
    });
  };

  public static setConfirmationEmail = (email: string) => {
    window.localStorage.setItem('awaiting_confirmation_email', email);
  };

  public static removeConfirmationEmail = () => {
    window.localStorage.removeItem('awaiting_confirmation_email');
  };

  public static getConfirmationEmail = (): string | null => {
    return window.localStorage.getItem('awaiting_confirmation_email');
  };
}
