import { FC } from 'react';
import { makeStyles, Container, Typography, Box, Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import Page from '../../components/Page';
import JWTLogin from '../../auth/LoginView/JWTLogin';
import typography from '../../theme/typography';
import { UserPersona } from '../../types/user';

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    marginTop: '5rem',
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  titleText: {
    [theme.breakpoints.up('sm')]: {
      backgroundColor: '#fff',
      padding: '0.4rem 0 0.4rem 0.8rem',
      borderRadius: '0.6rem',
    },
  },
  loginForm: {
    backgroundColor: '#fff',
    padding: '2rem 2rem',
    borderRadius: '0.6rem',
  },
  formHeader: {
    fontSize: typography.h3.fontSize,
  },
}));

interface LoginViewProps {
  persona: UserPersona;
}

const LoginView: FC<LoginViewProps> = ({ persona }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const pageTitleId = persona === UserPersona.ADMIN ? 'admin-login' : 'login';
  return (
    <Page
      title={formatMessage({
        id: `page-titles.${pageTitleId}`,
        defaultMessage: 'Login',
      })}
    >
      <Container maxWidth="md" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} className={classes.title}>
            <Typography
              variant="h1"
              color="textPrimary"
              className={persona !== UserPersona.ADMIN ? classes.titleText : undefined}
            >
              {formatMessage({
                id: 'login-page.header',
                defaultMessage: 'More local products within the retail industry',
              })}
            </Typography>
            {persona === UserPersona.ADMIN && (
              <Typography variant="h4" color="textSecondary">
                {formatMessage({
                  id: 'login-page.subheader',
                  defaultMessage:
                    'This is your access to your admin account for foodstarter REGIONAL',
                })}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box justifyContent="center" className={classes.loginForm}>
              <Typography variant="h2" className={classes.formHeader}>
                {formatMessage({
                  id: 'login-page.form.header',
                  defaultMessage: 'Login',
                })}
              </Typography>
              <JWTLogin persona={persona} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default LoginView;
