import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Theme,
  Typography,
  Box,
  Input,
  FormHelperText,
  Chip,
  ListSubheader,
  ListItemIcon,
  ListItem,
  ListItemText,
  List,
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { Person as PersonIcon } from '@material-ui/icons';
import { Formik } from 'formik';
import { FC, useState, ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { useQuery } from '@apollo/client';
import DeleteCompanyAccountDialog from '../CompanyListView/DeleteCompanyAccountDialog';
import * as Yup from 'yup';
import { GetSupplierCompanyById_supplierCompany } from '../../../../contexts/suppliers/queries/__generated__/GetSupplierCompanyById';
import { useMutation } from '@apollo/client';
import { UPDATE_SUPPLIER_COMPANY_AS_ADMIN } from '../../../../contexts/suppliers/mutations/updateSupplierCompanyAsAdmin';
import { useSnackbar } from 'notistack';
import { useDeleteSupplierCompanyById } from '../../../../hooks/useDeleteSupplierCompanyById';
import { SupplierUtils } from '../../../../utils/supplier-utils';
import { LiveBadge } from '../../../../components/common/badges/LiveBadge';
import { NotLiveBadge } from '../../../../components/common/badges/NotLiveBadge';
import { AdvertisingAreas, CompanyPublishedState, EdekaRegions } from '../../../../types/company';
import moment from 'moment';
import { DateUtils } from '../../../../utils/date-utils';
import ImageCard from '../../../../components/common/cards/ImageCard';
import pdfIcon from '../../../../assets/pdfIcon.svg';
import FileUploadArea from '../../../../components/common/upload/FileUploadArea';
import { MB } from '../../../../types/files';
import { saveCompanyFileData } from '../../../../utils/file-upload';
import { DELETE_UPLOAD_BY_ID_AND_TYPE } from '../../../../contexts/suppliers/mutations/deleteUploadByIdAndType';
import {
  DeleteUploadByIdAndType,
  DeleteUploadByIdAndTypeVariables,
} from '../../../../contexts/suppliers/mutations/__generated__/DeleteUploadByIdAndType';
import { UploadableRecordEnum } from '../../../../__generated__/globalTypes';
import { GET_COMMODITY_GROUPS } from '../../../../components/merchants/queries/commodity_groups';
import { CommodityGroup } from '../../../../types/commodityGroup';

interface CompanyDetailProps {
  className?: string;
  company: GetSupplierCompanyById_supplierCompany;
  refreshData: () => void;
}

const listElementTypes = {
  HEADER: 'header',
  SUB: 'sub',
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  button: {
    marginTop: '1rem',
    width: '25%',
  },
  select: {
    width: '100%',
    marginTop: '0.5rem',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  menuItem: {
    paddingLeft: theme.spacing(5),
  },
  linkList: {
    display: 'flex',
    flexDirection: 'column',
  },
  linkListItem: {
    paddingTop: theme.spacing(1),
  },
  cardWrapper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  tooltipImage: {
    width: '250px',
  },
}));

const tabs = [
  {
    value: 'view',
    label: 'View',
  },
  {
    value: 'edit',
    label: 'Edit',
  },
  {
    value: 'delete',
    label: 'Delete',
  },
];

const CompanyDetail: FC<CompanyDetailProps> = ({ className, company, refreshData }) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState<string>('view');
  const [disabled, setDisabled] = useState<boolean>(true);
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [updateSupplierCompany] = useMutation(UPDATE_SUPPLIER_COMPANY_AS_ADMIN);
  const [deleteUploadByIdAndType] = useMutation<
    DeleteUploadByIdAndType,
    DeleteUploadByIdAndTypeVariables
  >(DELETE_UPLOAD_BY_ID_AND_TYPE);
  const { enqueueSnackbar } = useSnackbar();

  const { loading, data } = useQuery(GET_COMMODITY_GROUPS);

  const { selectForDelete, cancelDeletion, confirmDeletion, companyToDelete } =
    useDeleteSupplierCompanyById(() => history.push('/admin/supplier_companies'));

  const handleTabsChange = (event: ChangeEvent<{}> | null, value: string): void => {
    if (value === 'edit') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    setCurrentTab(value);
  };

  return (
    <Card>
      <DeleteCompanyAccountDialog
        open={companyToDelete !== null}
        companyName={company.name}
        handleDelete={confirmDeletion}
        handleClose={() => {
          cancelDeletion();
          setCurrentTab('view');
        }}
      />
      <Tabs
        onChange={handleTabsChange}
        scrollButtons="auto"
        textColor="secondary"
        value={currentTab}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            value={tab.value}
            label={formatMessage({
              id: `admin.company-detail.tabs.${tab.value}`,
              defaultMessage: tab.label,
            })}
            onClick={() => (tab.value === 'delete' ? selectForDelete(company) : cancelDeletion())}
          />
        ))}
      </Tabs>
      <Divider />
      <CardContent>
        {currentTab !== 'delete' && (
          <Formik
            initialValues={{
              name: company.name,
              description: company.description,
              website: company.website,
              logo: company.logo,
              logoNew: null,
              logoForDelete: null,
              postalCode: company.postalCode,
              city: company.city,
              district: company.district,
              deliveryArea: company.deliveryArea,
              commodityGroups: company.commodityGroups,
              products: company.products,
              productPortfolio: company.productPortfolio,
              productPortfolioNew: null,
              productPortfolioForDelete: null,
              minimumOrderValue: company.minimumOrderValue,
              images: company.images,
              imagesNew: [],
              imagesForDelete: [],
              publishedState: company.publishedState,
              publishedDate: company.publishedDate,
              listing:
                company.listing === '' || company.listing === null ? 'false' : company.listing,
              advertisingArea: company.advertisingArea ?? '',
              edekaRegion: company.edekaRegion ?? EdekaRegions.sw,
            }}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              name: Yup.string().required(
                formatMessage({
                  id: 'admin.company-detail.error.name',
                  defaultMessage: 'Company Name is required',
                }),
              ),
              logoNew: Yup.object().nullable(),
              description: Yup.string().nullable(),
              website: Yup.string().nullable(),
              postalCode: Yup.string().nullable(),
              city: Yup.string().nullable(),
              district: Yup.string().nullable(),
              products: Yup.string().nullable(),
              deliveryArea: Yup.string().nullable(),
              commodityGroups: Yup.array()
                .required()
                .min(
                  1,
                  formatMessage({
                    id: 'admin.company-detail.error.commodity-groups',
                    defaultMessage: 'Please select at least one commodity group',
                  }),
                ),
              productPortfolio: Yup.object().nullable(),
              productPortfolioNew: Yup.object().nullable(),
              imagesNew: Yup.array(),
              minimumOrderValue: Yup.string().nullable(),
              publishedState: Yup.string().nullable(),
              publishedDate: Yup.string().nullable(),
              listing: Yup.string(),
              advertisingArea: Yup.string().nullable(),
              edekaRegion: Yup.string().nullable(),
            })}
            onSubmit={async (values, { setStatus, setSubmitting }) => {
              try {
                // DELETE ACTIONS
                if (values !== null && values.logoForDelete !== null) {
                  await deleteUploadByIdAndType({
                    variables: {
                      //@ts-ignore
                      id: values.logoForDelete.id,
                      type: UploadableRecordEnum.SupplierCompanyLogo,
                    },
                  });
                }

                if (values !== null && values.productPortfolioForDelete !== null) {
                  await deleteUploadByIdAndType({
                    variables: {
                      //@ts-ignore
                      id: values.productPortfolioForDelete.id,
                      type: UploadableRecordEnum.ProductPortfolio,
                    },
                  });
                }

                if (values.imagesForDelete.length > 0) {
                  for (let image of values.imagesForDelete) {
                    //@ts-ignore
                    await deleteUploadByIdAndType({
                      variables: {
                        //@ts-ignore
                        id: image.id,
                        type: UploadableRecordEnum.Image,
                      },
                    });
                  }
                }
                // END DELETE ACTIONS

                // SUBMIT ACTIONS
                if (values.logoNew !== null) {
                  //@ts-ignore
                  await saveCompanyFileData(values.logoNew, company.id, 'SupplierCompanyLogo');
                }

                if (values.productPortfolioNew !== null) {
                  await saveCompanyFileData(
                    //@ts-ignore
                    values.productPortfolioNew,
                    company.id,
                    'ProductPortfolio',
                  );
                }

                if (values.imagesNew.length > 0) {
                  for (let image of values.imagesNew) {
                    //@ts-ignore
                    await saveCompanyFileData(image, company.id, 'Image');
                  }
                }

                await updateSupplierCompany({
                  variables: {
                    id: company.id,
                    name: values.name,
                    description: values.description,
                    website: values.website,
                    postalCode: values.postalCode,
                    city: values.city,
                    district: values.district,
                    deliveryArea: values.deliveryArea,
                    commodityGroups: values.commodityGroups,
                    products: values.products,
                    minimumOrderValue: values.minimumOrderValue,
                    listing: values.listing,
                    advertisingArea: values.advertisingArea,
                    edekaRegion: values.edekaRegion,
                    publishedState: values.publishedState,
                    publishedDate: values.publishedDate,
                  },
                });
                // END SUBMIT ACTIONS

                refreshData();
                setStatus({ success: true });
                setSubmitting(false);
                enqueueSnackbar(
                  formatMessage({
                    id: 'admin.company-detail.edit-success',
                    defaultMessage: 'Successfully edited company details!',
                  }),
                  { variant: 'success' },
                );
                handleTabsChange(null, 'view');
              } catch (error) {
                setStatus({ success: false });
                setSubmitting(false);
                enqueueSnackbar('Error! ' + error?.message ?? error, { variant: 'error' });
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3} xs={12} direction="column" alignItems="center">
                  <Grid item xs={12} md={6} container alignItems="flex-start">
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label={formatMessage({
                        id: 'admin.company-detail.fields.name',
                        defaultMessage: 'Company Name',
                      })}
                      margin="normal"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} container alignItems="flex-start">
                    <TextField
                      error={Boolean(touched.description && errors.description)}
                      fullWidth
                      multiline
                      rows={10}
                      helperText={touched.description && errors.description}
                      label={formatMessage({
                        id: 'admin.company-detail.fields.description',
                        defaultMessage: 'Description',
                      })}
                      margin="normal"
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      variant="outlined"
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} container alignItems="flex-start">
                    <TextField
                      error={Boolean(touched.website && errors.website)}
                      fullWidth
                      helperText={touched.website && errors.website}
                      label={formatMessage({
                        id: 'admin.company-detail.fields.website',
                        defaultMessage: 'Website',
                      })}
                      margin="normal"
                      name="website"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.website}
                      variant="outlined"
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} container alignItems="flex-start" direction="column">
                    <Typography gutterBottom variant="h5" component="h2">
                      {formatMessage({
                        id: 'admin.company-detail.fields.logo',
                        defaultMessage: 'Logo',
                      })}
                    </Typography>

                    {values?.logo?.imageUrl && !values.logoForDelete ? (
                      <ImageCard
                        image={values.logo.imageUrl}
                        downloadLink={values.logo.imageUrl}
                        buttons={
                          currentTab === 'edit' ? (
                            <Button
                              variant="outlined"
                              onClick={() => {
                                setFieldValue('logoForDelete', values.logo);
                                setFieldValue('logo', null);
                              }}
                            >
                              {formatMessage({
                                id: `admin.company-detail.delete-file`,
                                defaultMessage: 'Delete',
                              })}
                            </Button>
                          ) : null
                        }
                        tooltipContent={
                          <img
                            className={classes.tooltipImage}
                            src={values.logo.imageUrl}
                            alt="logo"
                          />
                        }
                      />
                    ) : (
                      formatMessage({
                        id: 'admin.company-detail.fields.no-data',
                        defaultMessage: 'No data',
                      })
                    )}

                    {!disabled && !values.logo && (
                      <Box className={classes.cardWrapper}>
                        <FileUploadArea
                          acceptedFiles={['image/jpeg', 'image/png']}
                          maxFileSize={10 * MB}
                          filesLimit={1}
                          onS3UploadComplete={(result) => {
                            if (result.successful.length > 0) {
                              setFieldValue('logoNew', result.successful[0]);
                            }
                          }}
                          onCancelAll={() => setFieldValue('logoNew', null)}
                          height="300px"
                          width="40vw"
                        />
                      </Box>
                    )}

                    {errors.logo && (
                      <FormHelperText error margin="dense">
                        {errors.logo}
                      </FormHelperText>
                    )}
                    {errors.logoNew && (
                      <FormHelperText error margin="dense">
                        {errors.logoNew}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} container alignItems="flex-start">
                    <TextField
                      error={Boolean(touched.postalCode && errors.postalCode)}
                      fullWidth
                      helperText={touched.postalCode && errors.postalCode}
                      label={formatMessage({
                        id: 'admin.company-detail.fields.postal-code',
                        defaultMessage: 'Postal Code',
                      })}
                      margin="normal"
                      name="postalCode"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.postalCode}
                      variant="outlined"
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} container alignItems="flex-start">
                    <TextField
                      error={Boolean(touched.city && errors.city)}
                      fullWidth
                      helperText={touched.city && errors.city}
                      label={formatMessage({
                        id: 'admin.company-detail.fields.city',
                        defaultMessage: 'City',
                      })}
                      margin="normal"
                      name="city"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.city}
                      variant="outlined"
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} container alignItems="flex-start">
                    <TextField
                      error={Boolean(touched.district && errors.district)}
                      fullWidth
                      helperText={touched.district && errors.district}
                      label={formatMessage({
                        id: 'admin.company-detail.fields.district',
                        defaultMessage: 'District',
                      })}
                      margin="normal"
                      name="district"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.district}
                      variant="outlined"
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} container alignItems="flex-start">
                    <TextField
                      error={Boolean(touched.deliveryArea && errors.deliveryArea)}
                      fullWidth
                      helperText={touched.deliveryArea && errors.deliveryArea}
                      label={formatMessage({
                        id: 'admin.company-detail.fields.delivery-area',
                        defaultMessage: 'Delivery Area',
                      })}
                      margin="normal"
                      name="deliveryArea"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.deliveryArea}
                      variant="outlined"
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} container alignItems="flex-start">
                    <Grid item container direction="column" alignItems="flex-start">
                      <Typography>
                        {formatMessage({
                          id: 'admin.company-detail.fields.commodity-groups',
                          defaultMessage: 'Commodity Groups',
                        })}
                      </Typography>

                      <Select
                        error={Boolean(touched.commodityGroups && errors.commodityGroups)}
                        labelId="demo-mutiple-chip-label"
                        fullWidth
                        id="demo-mutiple-chip"
                        name="commodityGroups"
                        multiple
                        label={'Commodity groups'}
                        value={values.commodityGroups ?? []}
                        onChange={handleChange}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={(selected) => (
                          <div className={classes.chips}>
                            {(selected as string[]).map((value) => (
                              <Chip key={value} label={value} className={classes.chip} />
                            ))}
                          </div>
                        )}
                        disabled={disabled}
                      >
                        {!loading &&
                          data.commodityGroups.map((option: CommodityGroup, index: number) => {
                            if (option.level === listElementTypes.HEADER) {
                              return (
                                <ListSubheader
                                  key={index}
                                  style={{ pointerEvents: 'none' }}
                                  disableSticky
                                >
                                  {option.name}
                                </ListSubheader>
                              );
                            }
                            return (
                              <MenuItem
                                key={index}
                                value={option.name}
                                className={classes.menuItem}
                              >
                                {option.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      {errors.commodityGroups && (
                        <FormHelperText error margin="dense">
                          {errors.commodityGroups}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} container alignItems="flex-start">
                    <TextField
                      error={Boolean(touched.products && errors.products)}
                      fullWidth
                      multiline
                      rows={10}
                      helperText={touched.products && errors.products}
                      label={formatMessage({
                        id: 'admin.company-detail.fields.products',
                        defaultMessage: 'Products',
                      })}
                      margin="normal"
                      name="products"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.products}
                      variant="outlined"
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} container alignItems="flex-start">
                    <Grid item container direction="column" alignItems="flex-start">
                      <Typography>
                        {formatMessage({
                          id: 'admin.company-detail.fields.product-portfolio',
                          defaultMessage: 'Product Portfolio',
                        })}
                      </Typography>
                      {values.productPortfolio?.portfolioUrl ? (
                        <ImageCard
                          image={pdfIcon}
                          buttons={
                            currentTab === 'edit' ? (
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  setFieldValue(
                                    'productPortfolioForDelete',
                                    values.productPortfolio,
                                  );
                                  setFieldValue('productPortfolio', null);
                                }}
                              >
                                {formatMessage({
                                  id: `admin.company-detail.delete-file`,
                                  defaultMessage: 'Delete',
                                })}
                              </Button>
                            ) : null
                          }
                          downloadLink={values.productPortfolio.portfolioUrl}
                          tooltipContent={formatMessage({
                            id: 'admin.company-detail.fields.download',
                            defaultMessage: 'Download',
                          })}
                          content={
                            <Typography gutterBottom variant="h5" component="h2" noWrap={true}>
                              {values.productPortfolio.name}
                            </Typography>
                          }
                        />
                      ) : (
                        formatMessage({
                          id: 'admin.company-detail.fields.no-data',
                          defaultMessage: 'No data',
                        })
                      )}
                      {!disabled && !values.productPortfolio?.portfolioUrl && (
                        <Box className={classes.cardWrapper}>
                          <FileUploadArea
                            acceptedFiles={['application/pdf']}
                            maxFileSize={20 * MB}
                            filesLimit={1}
                            onS3UploadComplete={(result) => {
                              if (result.successful.length > 0) {
                                setFieldValue('productPortfolioNew', result.successful[0]);
                              }
                            }}
                            onCancelAll={() => setFieldValue('productPortfolioNew', null)}
                            height="300px"
                            width="40vw"
                          />
                        </Box>
                      )}
                      {errors.productPortfolio && (
                        <FormHelperText error margin="dense">
                          {errors.productPortfolio}
                        </FormHelperText>
                      )}
                      {errors.productPortfolioNew && (
                        <FormHelperText error margin="dense">
                          {errors.productPortfolioNew}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} container alignItems="flex-start">
                    <TextField
                      error={Boolean(touched.minimumOrderValue && errors.minimumOrderValue)}
                      fullWidth
                      helperText={touched.minimumOrderValue && errors.minimumOrderValue}
                      label={formatMessage({
                        id: 'admin.company-detail.fields.min-order-value',
                        defaultMessage: 'Minimum Order Value',
                      })}
                      margin="normal"
                      name="minimumOrderValue"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.minimumOrderValue}
                      variant="outlined"
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} container alignItems="flex-start">
                    <Grid item container direction="column">
                      <Typography>
                        {formatMessage({
                          id: 'admin.company-detail.fields.images',
                          defaultMessage: 'Images',
                        })}
                      </Typography>
                      {values.images && values.images.length > 0 ? (
                        <Grid item container xs={12} direction="row" justify="space-evenly">
                          {values.images.map((image, idx) => {
                            if (!image.imageUrl) {
                              return null;
                            }
                            return (
                              <Grid
                                item
                                direction="column"
                                xs={6}
                                alignItems="center"
                                className={classes.cardWrapper}
                              >
                                <ImageCard
                                  image={image.imageUrl}
                                  downloadLink={image.imageUrl}
                                  buttons={
                                    currentTab === 'edit' ? (
                                      <Button
                                        variant="outlined"
                                        onClick={() => {
                                          let imagesForDelete = values.imagesForDelete;
                                          //@ts-ignore
                                          imagesForDelete.push(image);
                                          setFieldValue('imagesForDelete', imagesForDelete);
                                          // values.imagesForDelete.push(image);
                                          let remainingImages = values.images?.filter(
                                            (currentImage) => currentImage.id !== image.id,
                                          );

                                          setFieldValue('images', remainingImages);
                                        }}
                                      >
                                        {formatMessage({
                                          id: `admin.company-detail.delete-file`,
                                          defaultMessage: 'Delete',
                                        })}
                                      </Button>
                                    ) : null
                                  }
                                  tooltipContent={
                                    <img
                                      className={classes.tooltipImage}
                                      src={image.imageUrl}
                                      alt="logo"
                                    />
                                  }
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      ) : (
                        formatMessage({
                          id: 'admin.company-detail.fields.no-data',
                          defaultMessage: 'No data',
                        })
                      )}
                      {!disabled && !(values.images && values.images.length >= 4) && (
                        <Box className={classes.cardWrapper}>
                          <FileUploadArea
                            acceptedFiles={['image/jpeg', 'image/png']}
                            maxFileSize={10 * MB}
                            filesLimit={values.images ? 4 - values.images.length : 4}
                            onS3UploadComplete={(result) => {
                              if (result.successful.length > 0) {
                                setFieldValue('imagesNew', [
                                  ...values.imagesNew,
                                  ...result.successful,
                                ]);
                              }
                            }}
                            onCancelAll={() => setFieldValue('imagesNew', [])}
                            height="300px"
                            width="40vw"
                          />
                        </Box>
                      )}
                      {errors.images && (
                        <FormHelperText error margin="dense">
                          {errors.images}
                        </FormHelperText>
                      )}
                      {errors.imagesNew && (
                        <FormHelperText error margin="dense">
                          {errors.imagesNew}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} container alignItems="flex-start">
                    <Box>
                      <DateTimePicker
                        margin="normal"
                        name="publishedDate"
                        label={formatMessage({
                          id: 'admin.company-detail.fields.published-date',
                          defaultMessage: 'Published Date',
                        })}
                        format="DD.MM.yyyy HH:mm"
                        disabled={disabled}
                        ampm={false}
                        value={values.publishedDate}
                        onBlur={handleBlur}
                        onChange={(date) => {
                          if (date) {
                            setFieldValue('publishedDate', date?.toISOString());
                          }
                        }}
                      />
                      {moment.tz.guess() !== DateUtils.DISPLAYED_TZ && (
                        <Typography variant="subtitle2">
                          You are selecting/viewing this time in the timezone: {moment.tz.guess()}
                        </Typography>
                      )}
                      {errors.publishedDate && (
                        <FormHelperText error margin="dense">
                          {errors.publishedDate}
                        </FormHelperText>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} container alignItems="flex-start">
                    <Grid item container direction="column">
                      <InputLabel id="published-state-label">
                        {formatMessage({
                          id: 'admin.company-detail.fields.published-state',
                          defaultMessage: 'Published State',
                        })}
                      </InputLabel>
                      <Select
                        name="publishedState"
                        value={values.publishedState}
                        onChange={handleChange}
                        className={classes.select}
                        variant="outlined"
                        labelId="published-state-label"
                        disabled={disabled}
                        error={Boolean(touched.publishedState && errors.publishedDate)}
                        fullWidth
                      >
                        {Object.values(CompanyPublishedState).map((state) => (
                          <MenuItem value={state} key={state}>
                            {formatMessage({
                              id: `admin.company-detail.published-state.${state}`,
                              defaultMessage: state,
                            })}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.publishedState && (
                        <FormHelperText error margin="dense">
                          {errors.publishedState}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} container alignItems="flex-start">
                    <Grid
                      item
                      container
                      xs={6}
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Typography>
                        {`${formatMessage({
                          id: 'admin.supplier-detail.data.live-status',
                          defaultMessage: 'Live Status',
                        })}: `}
                      </Typography>
                      {SupplierUtils.isCompanyActive(company) ? <LiveBadge /> : <NotLiveBadge />}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} container alignItems="flex-start">
                    <Grid item container direction="column">
                      <InputLabel id="listing-label">
                        {formatMessage({
                          id: 'admin.company-detail.fields.listing',
                          defaultMessage: 'Listing',
                        })}
                      </InputLabel>
                      <Select
                        name="listing"
                        value={values.listing}
                        onChange={handleChange}
                        className={classes.select}
                        variant="outlined"
                        labelId="listing-label"
                        disabled={disabled}
                        defaultValue="false"
                        error={Boolean(touched.listing && errors.listing)}
                        fullWidth
                      >
                        <MenuItem value="false">
                          {formatMessage({
                            id: 'admin.company-detail.listing-status.not-listed',
                            defaultMessage: 'not listed',
                          })}
                        </MenuItem>
                        <MenuItem value="true">
                          {formatMessage({
                            id: 'admin.company-detail.listing-status.listed',
                            defaultMessage: 'listed',
                          })}
                        </MenuItem>
                        <MenuItem value="warehouse">
                          {formatMessage({
                            id: 'admin.company-detail.listing-status.listed-warehouse',
                            defaultMessage: 'listed warehouse',
                          })}
                        </MenuItem>
                        <MenuItem value="cross_docking">
                          {formatMessage({
                            id: 'admin.company-detail.listing-status.listed-cross-docking',
                            defaultMessage: 'cross docking',
                          })}
                        </MenuItem>
                      </Select>
                      {errors.listing && (
                        <FormHelperText error margin="dense">
                          {errors.listing}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} container alignItems="flex-start">
                    <Grid item container direction="column">
                      <InputLabel id="region-label">
                        {formatMessage({
                          id: 'admin.company-detail.fields.edeka-region',
                          defaultMessage: 'EDEKA Region',
                        })}
                      </InputLabel>
                      <Select
                        name="edekaRegion"
                        value={values.edekaRegion}
                        onChange={handleChange}
                        className={classes.select}
                        variant="outlined"
                        labelId="region-label"
                        disabled={disabled}
                        error={Boolean(touched.edekaRegion && errors.edekaRegion)}
                        fullWidth
                      >
                        {Object.values(EdekaRegions).map((region) => (
                          <MenuItem value={region} key={region}>
                            {region}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.edekaRegion && (
                        <FormHelperText error margin="dense">
                          {errors.edekaRegion}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} container alignItems="flex-start">
                    <List
                      subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                          {formatMessage({
                            id: 'admin.company-detail.fields.supplier',
                            defaultMessage: 'Supplier',
                          })}
                        </ListSubheader>
                      }
                    >
                      <ListItem
                        button
                        component="a"
                        href={`/admin/supplier_accounts/${company.supplierAccount.id}`}
                      >
                        <ListItemIcon>
                          <PersonIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={`${company.supplierAccount.firstName} ${company.supplierAccount.surname} (${company.supplierAccount.email})`}
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={12} md={6} container alignItems="center" direction="column">
                    <Button
                      color="secondary"
                      size="large"
                      type="submit"
                      variant="contained"
                      className={classes.button}
                      disabled={isSubmitting || disabled}
                    >
                      {formatMessage({
                        id: 'account-setup.save-button',
                        defaultMessage: 'Save',
                      })}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        )}
      </CardContent>
    </Card>
  );
};

export default CompanyDetail;
