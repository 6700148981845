import { GET_SUPPLIER_ACCOUNT } from '../../contexts/suppliers/queries/account';

// comment out the `supplierCompanies` key to render the dashboard without information about a
// companies but a button to start onboarding after reloading the dashboard
export const getSupplierAccountMock = {
  request: {
    query: GET_SUPPLIER_ACCOUNT,
  },
  result: {
    data: {
      supplierAccount: {
        __typename: 'SupplierAccount',
        id: 2,
        email: 'foo@bar.baz',
        firstName: 'Foo',
        surname: 'Bar',
        supplierCompanies: {
          totalCount: 2,
          pageInfo: {
            startCursor: 'MQ',
            endCursor: 'Mg',
            hasNextPage: false,
            hasPreviousPage: false,
          },
          edges: [
            {
              cursor: 'MQ',
              node: {
                id: '1',
                name: 'Haverney OHG',
                images: ['https://via.placeholder.com/1600x700.png/8a85ff/fff'],
                publishedState: 'approval_requested',
              },
            },
            {
              cursor: 'Mg',
              node: {
                id: '2',
                name: 'Emmelmann-Golomski',
                images: ['https://via.placeholder.com/1600x700.png/8a850f/fff'],
                publishedState: 'approved',
              },
            },
          ],
        },
      },
    },
  },
};
