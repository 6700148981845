import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ChangeEvent, FC, useState } from 'react';
import { useIntl } from 'react-intl';
import DeleteSupplierAccountDialog from '../SupplierListView/DeleteSupplierAccountDialog';
import { useHistory } from 'react-router';
import { Theme } from '../../../../theme';
import { useDeleteSupplierAccountById } from '../../../../hooks/useDeleteSupplierAccountById';
import { GetSupplierAccountById_supplierAccount } from '../../../../contexts/suppliers/queries/__generated__/GetSupplierAccountById';
import { useMutation } from '@apollo/client';
import { UPDATE_SUPPLIER_ACCOUNT_AS_ADMIN } from '../../../../contexts/suppliers/mutations/updateSupplierAccountAsAdmin';
import { useSnackbar } from 'notistack';
import { UpdateSupplierAccountAsAdmin } from '../../../../contexts/suppliers/mutations/__generated__/UpdateSupplierAccountAsAdmin';
import { DateUtils } from '../../../../utils/date-utils';
import { SupplierUtils } from '../../../../utils/supplier-utils';
import { LiveBadge } from '../../../../components/common/badges/LiveBadge';
import { NotLiveBadge } from '../../../../components/common/badges/NotLiveBadge';

interface SupplierDetailProps {
  supplier: GetSupplierAccountById_supplierAccount;
}

interface SupplierDetailFormData extends Partial<GetSupplierAccountById_supplierAccount> {}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  button: {
    marginTop: '1rem',
    width: '25%',
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  textField: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formText: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    textAlign: 'left',
  },
}));

const tabs = [
  {
    value: 'view',
    label: 'View',
  },
  {
    value: 'edit',
    label: 'Edit',
  },
  {
    value: 'delete',
    label: 'Delete',
  },
];

const SupplierDetail: FC<SupplierDetailProps> = ({ supplier }) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState<string>('view');
  const [disabled, setDisabled] = useState<boolean>(true);
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { cancelDeletion, confirmDeletion, selectForDelete, supplierToDelete } =
    useDeleteSupplierAccountById(() => history.push('/admin/supplier_accounts'));
  const [updateSupplierAccountAsAdmin] = useMutation<UpdateSupplierAccountAsAdmin>(
    UPDATE_SUPPLIER_ACCOUNT_AS_ADMIN,
  );

  const handleTabsChange = (event: ChangeEvent<{}> | null, value: string): void => {
    if (value === 'edit') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    setCurrentTab(value);
  };

  return (
    <Card>
      <DeleteSupplierAccountDialog
        open={supplierToDelete !== null}
        supplierName={`${supplierToDelete?.firstName} ${supplierToDelete?.surname}`}
        handleDelete={confirmDeletion}
        handleClose={() => {
          cancelDeletion();
          handleTabsChange(null, 'view');
        }}
      />
      <Tabs
        onChange={handleTabsChange}
        scrollButtons="auto"
        textColor="secondary"
        value={currentTab}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            value={tab.value}
            label={formatMessage({
              id: `admin.supplier-detail.tabs.${tab.value}`,
              defaultMessage: tab.label,
            })}
            onClick={() => {
              if (tab.value === 'delete') selectForDelete(supplier);
            }}
          />
        ))}
      </Tabs>
      <Divider />
      <CardContent>
        {currentTab !== 'delete' && (
          <Formik<SupplierDetailFormData>
            initialValues={{
              firstName: supplier.firstName,
              surname: supplier.surname,
              email: supplier.email,
              faxNumber: supplier.faxNumber,
              phoneNumber: supplier.phoneNumber,
              zksNumber: supplier.zksNumber,
              backofficeNotes: supplier.backofficeNotes,
            }}
            validationSchema={Yup.object().shape({
              firstName: Yup.string().required(
                formatMessage({
                  id: 'admin.supplier-detail.error.first-name',
                  defaultMessage: 'First Name is required',
                }),
              ),
              surname: Yup.string().required(
                formatMessage({
                  id: 'admin.supplier-detail.error.last-name',
                  defaultMessage: 'Last Name is required',
                }),
              ),
              email: Yup.string().required(
                formatMessage({
                  id: 'admin.supplier-detail.error.email',
                  defaultMessage: 'E-mail Address is required',
                }),
              ),
              faxNumber: Yup.string().nullable(),
              phoneNumber: Yup.string().required(
                formatMessage({
                  id: 'admin.supplier-detail.error.phone-number',
                  defaultMessage: 'Phone number is required',
                }),
              ),
              zksNumber: Yup.string().nullable(),
              backofficeNotes: Yup.string().nullable(),
            })}
            onSubmit={async (values, { setStatus, setSubmitting }) => {
              return updateSupplierAccountAsAdmin({
                variables: {
                  id: supplier.id,
                  ...values,
                },
              })
                .then(() => {
                  setStatus({ success: true });
                  setSubmitting(false);
                  enqueueSnackbar(
                    formatMessage({
                      id: 'admin.supplier-detail.data.update-success',
                      defaultMessage: 'Successfully updated supplier!',
                    }),
                    { variant: 'success' },
                  );
                  handleTabsChange(null, 'view');
                })
                .catch(() => {
                  setStatus({ success: false });
                  setSubmitting(false);
                  enqueueSnackbar(
                    formatMessage({
                      id: 'admin.supplier-detail.data.update-error',
                      defaultMessage: 'Error updating supplier!',
                    }),
                    { variant: 'error' },
                  );
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} className={classes.gridItem}>
                    <TextField
                      error={Boolean(touched.firstName && errors.firstName)}
                      fullWidth
                      helperText={touched.firstName && errors.firstName}
                      label={formatMessage({
                        id: 'admin.supplier-detail.fields.first-name',
                        defaultMessage: 'First Name',
                      })}
                      margin="normal"
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstName}
                      variant="outlined"
                      className={classes.textField}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.gridItem}>
                    <TextField
                      error={Boolean(touched.surname && errors.surname)}
                      fullWidth
                      helperText={touched.surname && errors.surname}
                      label={formatMessage({
                        id: 'admin.supplier-detail.fields.last-name',
                        defaultMessage: 'Last Name',
                      })}
                      margin="normal"
                      name="surname"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.surname}
                      variant="outlined"
                      className={classes.textField}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.gridItem}>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label={formatMessage({
                        id: 'admin.supplier-detail.fields.email',
                        defaultMessage: 'E-mail',
                      })}
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      variant="outlined"
                      className={classes.textField}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.gridItem}>
                    <TextField
                      error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                      fullWidth
                      helperText={touched.phoneNumber && errors.phoneNumber}
                      label={formatMessage({
                        id: 'admin.supplier-detail.fields.phone-number',
                        defaultMessage: 'Phone number',
                      })}
                      margin="normal"
                      name="phoneNumber"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.phoneNumber}
                      variant="outlined"
                      className={classes.textField}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.gridItem}>
                    <TextField
                      error={Boolean(touched.faxNumber && errors.faxNumber)}
                      fullWidth
                      helperText={touched.faxNumber && errors.faxNumber}
                      label={formatMessage({
                        id: 'admin.supplier-detail.fields.fax-number',
                        defaultMessage: 'Fax number',
                      })}
                      margin="normal"
                      name="faxNumber"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.faxNumber}
                      variant="outlined"
                      className={classes.textField}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.gridItem}>
                    <TextField
                      error={Boolean(touched.zksNumber && errors.zksNumber)}
                      fullWidth
                      helperText={touched.zksNumber && errors.zksNumber}
                      label={formatMessage({
                        id: 'admin.supplier-detail.fields.zks-number',
                        defaultMessage: 'ZKS number',
                      })}
                      margin="normal"
                      name="zksNumber"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.zksNumber}
                      variant="outlined"
                      className={classes.textField}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.gridItem}>
                    <TextField
                      error={Boolean(touched.backofficeNotes && errors.backofficeNotes)}
                      fullWidth
                      helperText={touched.backofficeNotes && errors.backofficeNotes}
                      label={formatMessage({
                        id: 'admin.supplier-detail.fields.backoffice-notes',
                        defaultMessage: 'Backoffice-notes',
                      })}
                      margin="normal"
                      name="backofficeNotes"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.backofficeNotes}
                      variant="outlined"
                      className={classes.textField}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.gridItem}>
                    <Typography className={classes.formText}>
                      {`${formatMessage({
                        id: 'admin.supplier-detail.data.registered-at',
                        defaultMessage: 'Registrierung',
                      })}: ${DateUtils.toBerlinTimezoneString(supplier.createdAt)}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.gridItem}>
                    <Typography className={classes.formText}>
                      {`${formatMessage({
                        id: 'admin.supplier-detail.data.live-status',
                        defaultMessage: 'Live Status',
                      })}: `}
                      {supplier.supplierCompanies.nodes?.some(
                        (c) => c && SupplierUtils.isCompanyActive(c),
                      ) ? (
                        <LiveBadge />
                      ) : (
                        <NotLiveBadge />
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.gridItem}>
                    <FormControlLabel
                      className={classes.textField}
                      control={
                        <Checkbox
                          checked={supplier.acceptedTerms}
                          name="acceptedTerms"
                          disabled={true}
                        />
                      }
                      label={formatMessage({
                        id: 'admin.supplier-detail.data.accepted-terms',
                        defaultMessage: 'Accepted terms',
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.gridItem}>
                    <List
                      subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                          {formatMessage({
                            id: 'admin.supplier-detail.data.companies',
                            defaultMessage: 'Companies',
                          })}
                        </ListSubheader>
                      }
                      className={classes.textField}
                    >
                      {supplier?.supplierCompanies.nodes?.length === 0 && (
                        <Typography
                          variant="body2"
                          color="textPrimary"
                          className={classes.formText}
                        >
                          {formatMessage({
                            id: 'admin.supplier-detail.data.no-companies-msg',
                            defaultMessage: 'No companies to display',
                          })}
                        </Typography>
                      )}
                      {supplier?.supplierCompanies?.nodes?.map(
                        (company) =>
                          company && (
                            <ListItem
                              button
                              component="a"
                              href={`/admin/supplier_companies/${company.id}`}
                            >
                              <ListItemIcon>
                                <BusinessIcon />
                              </ListItemIcon>
                              <ListItemText primary={company.name} />
                              {SupplierUtils.isCompanyActive(company) ? (
                                <LiveBadge />
                              ) : (
                                <NotLiveBadge />
                              )}
                            </ListItem>
                          ),
                      )}
                    </List>
                  </Grid>
                  <Grid item xs={12} className={classes.gridItem}>
                    <Button
                      color="secondary"
                      size="large"
                      type="submit"
                      variant="contained"
                      className={classes.button}
                      disabled={isSubmitting || disabled}
                    >
                      {formatMessage({
                        id: 'account-setup.save-button',
                        defaultMessage: 'save',
                      })}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        )}
      </CardContent>
    </Card>
  );
};

export default SupplierDetail;
