import moment from 'moment';
import { SupplierCompanyCoreData } from '../contexts/suppliers/__generated__/SupplierCompanyCoreData';
import { CompanyPublishedState } from '../types/company';

export class SupplierUtils {
  public static isCompanyActive(company: SupplierCompanyCoreData) {
    if (!company.publishedDate) {
      return false;
    }

    return (
      company.publishedState === CompanyPublishedState.approved &&
      moment(company.publishedDate).isBefore(moment.now())
    );
  }
}
