import React from 'react';

const ExternalDownloadLink: React.FC<React.AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  children,
  ...rest
}) => {
  return (
    <a {...rest} target="_blank" rel="noreferrer noopener">
      {children}
    </a>
  );
};

export default ExternalDownloadLink;
