import { gql } from '@apollo/client';

export const GET_SUPPLIER_ACCOUNT = gql`
  query GetSupplierAccount {
    supplierAccount: account {
      __typename
      ... on SupplierAccount {
        id
        email
        firstName
        surname
        supplierCompanies {
          totalCount
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              name
              images {
                id
                imageUrl
              }
              publishedState
            }
          }
        }
      }
    }
  }
`;
